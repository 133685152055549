import React, { useRef, useEffect } from "react";
// import Sliderimg from "./Sliderimg";
import useState from "react-usestateref";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./Header";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Modal, Table, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import apiService from "../core/service/v1/index";
import { postMethod } from "../core/service/common.api";
import { PDFAnnotate } from "../utils/pdfAnnotate";
import { fabric } from "fabric";
import SignatureCanvas from "react-signature-canvas";

function unVisibleObject(jsonData, email) {
  try {
    jsonData.pages.forEach((pages, pagesIndex) => {
      pages.objects.forEach((pageObject, pageObjectIndex) => {
        if (pageObject.type === "group") {
          let unVisibleIndex = undefined;

          pageObject.objects.forEach((item) => {
            if (item.type === "text" && item.text !== email) {
              unVisibleIndex = pageObjectIndex;
            } else {
              // console.log(pageObjectIndex, "unVisibleIndex");
              // pageObject.visible = false;
            }
          });

          // console.log(unVisibleIndex, "check");

          if (unVisibleIndex !== undefined) {
            // console.log("hello", unVisibleIndex);
            jsonData.pages[pagesIndex].objects[unVisibleIndex].visible = false;
            unVisibleIndex = undefined;
          }
        }
      });
    });
    return jsonData;
  } catch (error) {
    // console.log(error, "error-unVisibleObject");
  }
}

function TemplateMoreFiter() {
  fabric.Object.NUM_FRACTION_DIGITS = 8;

  useEffect(() => {
    const HeadLink = window.location.href;
    const Link = HeadLink?.split("?")[1];
    // console.log(Link, "Link");
    // console.log(Link.split("/-"));
    const file = Link?.split("/-")[0];
    const email = Link?.split("/-")[1];
    const ID = Link?.split("/-")[2];

    setID(ID);
    setfile(file);
    setemail(email);
    LinkVerify();
  }, []);

  const value = {
    otp: "",
  };

  const [mainCanvases, setMainCanvases] = useState();

  const [activeCanvas, setActiveCanvas] = useState("");

  const handleFileChange = (e) => {
    try {
      // console.log(e, "file change");
      const pdfFile = e;
      var pdf = new PDFAnnotate("pdf-container", e, {
        onPageUpdated(page, oldData, newData) {
          // console.log(page, oldData, newData);
        },
        ready(canvases) {
          // console.log("Plugin initialized successfully");
          // console.log({
          //   canvases,
          // });
          let result = unVisibleObject(
            JSON.parse(Jsondataref.current),
            Emailref.current
          );

          // console.log(JSON.parse(Jsondataref.current), "Jsondataref");
          // console.log(result, "result");
          // console.log(Emailref.current, "email");
          pdf.loadFromJSON(result);
          setAllCanvases(result);
          setMainCanvases(canvases);
          canvases?.forEach((canvas) => {
            canvas.on("mouse:up", (e) => {
              // console.log("mouse clicked", e);
            });

            canvas?.on("mouse:down", (e) => {
              console.log("clicked obj")
              if (e.target) {
                // console.log(e.target, "-----e", e.target.left);
                if (e.target.type === "group") {
                  var text = new fabric.Text("Updated Sign", {
                    fill: "green",
                    left: e.target.left,
                    top: e.target.top,
                    selectable: false,
                  });
                  // setIsModalOpen(!isModalOpen);
                  setIsSignTypeModalOpen(!isSignTypeModalOpen);
                  setActiveCanvas({
                    canvas,
                    left: e.target.left,
                    top: e.target.top,
                    target: e.target,
                  }); 

                  canvas.requestRenderAll();
                }
              }
            });
          });
        },
        scale: 1.5,
        pageImageCompression: "MEDIUM",
      });
      setPdfInstance(pdf);
    } catch (error) {
      // console.log(error, "error-unVisibleObject");
    }
  };

  const [formData, setFormData] = useState(value);
  const [otpErr, setotpErr, otpErrref] = useState(false);
  const [validateErr, setvalidateErr] = useState("");
  const [Loader, setLoader] = useState(true);
  const [OTPverify, setOTPverify] = useState(false);
  const [file, setfile, fileref] = useState([]);
  const [ID, setID, IDref] = useState("");

  const [email, setemail, emailref] = useState([]);
  const [Email, setEmail, Emailref] = useState("");
  const [jsondata, Setjsondata, Jsondataref] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const signatureRef = useRef(null);
  const [output, Setoutput] = useState();
  const [datas, setdatas, datasref] = useState();

  const [pdfInstance, setPdfInstance] = useState(null);
  const navigate = useNavigate();
  const [isSignTypeModalOpen, setIsSignTypeModalOpen] = useState(false);

  const [allCanvases, setAllCanvases] = useState([]);

  const [pdfLoader, setPdfLoader] = useState(false);

  const [isHandWritingFontModalOpen, setIsHandWritingFontModalOpen] = useState(false)

  const [signName, setSignName] = useState("")

  const openModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    if (signatureRef.current) {
      signatureRef.current.clear();
    }
  };

  const buildSign = async  (localCanvas, dataURL, fontFamily= "", ) => {



    localCanvas.canvas.getObjects().forEach((item) => {
      if (item.visible === true) {
        localCanvas.canvas.remove(item);
      }
    });

        let random = Math.floor(Math.random() * 1000000000);


    console.log("build in sign", fontFamily)


    if(fontFamily !== ""){

      console.log("inside build font", fontFamily,{ top: localCanvas.target.left, left: localCanvas.target.left, 
           fontFamily: fontFamily,
          signName
      })

      var t = new fabric.Text(signName, {
           fontFamily: fontFamily,
           fontSize: 24, 
           top :localCanvas.target.top,
           left : localCanvas.target.left
      });


              //line1 top
      let line1 = new fabric.Line(
        [
          t.left - 10,
          t.top - 10,
          t.left + 20,
          t.top - 10,
        ],
        {
          stroke: "#000",
          strokeWidth: 1.4,
        }
      );




//line2 bottom
    let line2 = new fabric.Line(
        [
          t.left - 10,
          t.top + 65,
          t.left + 20,
          t.top + 65,
        ],
        {
          stroke: "#000",
          strokeWidth: 1.4,
        }
      );



    //line3 left

     let line3 = new fabric.Line(
        [
          t.left - 10,
          t.top - 10,
          t.left - 10,
          t.top + 65,
        ],
        {
          stroke: "#000",
          strokeWidth: 1.4,
        }
      );
      






      var text = new fabric.Text("JustiChain(AI) Signed By:", {
        top: line1.top - 5,
        left: line1.left + 35,
        fontSize: 12,
      });



      var text2 = new fabric.Text(random.toString(), {
        top: line2.top - 5,
        left: line2.left + 35,
        fontSize: 12,
      });


     let group = new fabric.Group([ t,text2, text, line1, line2, line3, ], {
        id: "impImg",
        selectable: false,
      });
     console.log("adding group", group)


     return group;

      // localCanvas.canvas.add(group);


      // localCanvas.canvas.requestRenderAll();
  



    }else {

    console.log(localCanvas, "localCanvas");

    // localCanvas.canvas.remove(localCanvas.target);

    console.log(dataURL, localCanvas  , "data url---")

    fabric.Image.fromURL(dataURL, (myImg) => {
    myImg.top = localCanvas.target.top;
    myImg.left = localCanvas.target.left;
    myImg.scaleToWidth(40);
    myImg.scaleToHeight(40);




      // localCanvas.canvas.add(myImg);


//line1 top
            let line1 = new fabric.Line(
        [
          myImg.left - 10,
          myImg.top - 10,
          myImg.left + 20,
          myImg.top - 10,
        ],
        {
          stroke: "#000",
          strokeWidth: 1.4,
        }
      );

                          // localCanvas.canvas.add(line1);



//line2 bottom
              let line2 = new fabric.Line(
        [
          myImg.left - 10,
          myImg.top + 65,
          myImg.left + 20,
          myImg.top + 65,
        ],
        {
          stroke: "#000",
          strokeWidth: 1.4,
        }
      );


    // localCanvas.canvas.add(line2);

    //line3 left

     let line3 = new fabric.Line(
        [
          myImg.left - 10,
          myImg.top - 10,
          myImg.left - 10,
          myImg.top + 65,
        ],
        {
          stroke: "#000",
          strokeWidth: 1.4,
        }
      );
      
      // localCanvas.canvas.add(line3);






      var text = new fabric.Text("JustiChain(AI) Signed By:", {
        top: line1.top - 5,
        left: line1.left + 35,
        fontSize: 12,
      });
            // localCanvas.canvas.add(text);



      var text2 = new fabric.Text(random.toString(), {
        top: line2.top - 5,
        left: line2.left + 35,
        fontSize: 12,
      });

                  // localCanvas.canvas.add(text2);

     let group = new fabric.Group([ myImg,text2, text, line1, line2, line3, ], {
        id: "impImg",
        selectable: false,
      });

      localCanvas.canvas.add(group);



    }, );



         localCanvas.canvas.requestRenderAll()




 }


//     let image = fabric.Image.fromURL(dataURL, function (img) {
//       // console.log(img, "imgimgimg");
//       img.left = localCanvas.left - 0;
//       img.top = localCanvas.top - 0;
//       let scale = 100 / img.width;

//       img.set({
//         scaleX: scale,
//         scaleY: scale,
//         // id: "signImg",
//       });
//       img.background = "#000";
//       img.selectable = false;
// console.log("coming", img)
      // localCanvas.canvas.add(img)

      // let line1 = new fabric.Line(
      //   [
      //     localCanvas.target.left - 10,
      //     localCanvas.target.top - 10,
      //     localCanvas.target.left + 20,
      //     localCanvas.target.top - 10,
      //   ],
      //   {
      //     stroke: "#000",
      //     strokeWidth: 1.4,
      //   }
      // );

      // console.log({ target: localCanvas.target }, "show things");

      // let line2 = new fabric.Line(
      //   [
      //     localCanvas.target.left - 10,
      //     localCanvas.target.top + 65,
      //     localCanvas.target.left + 20,
      //     localCanvas.target.top + 65,
      //   ],
      //   {
      //     stroke: "#000",
      //     strokeWidth: 1.4,
      //   }
      // );

      // let line3 = new fabric.Line(
      //   [
      //     localCanvas.target.left - 10,
      //     localCanvas.target.top - 10,
      //     localCanvas.target.left - 10,
      //     localCanvas.target.top + 65,
      //   ],
      //   {
      //     stroke: "#000",
      //     strokeWidth: 1.4,
      //   }
      // );

      // let line4 = new fabric.Line(
      //   [
      //     localCanvas.target.left + localCanvas.target.width / 2,
      //     localCanvas.target.top,
      //     localCanvas.target.left + localCanvas.target.width / 2,
      //     localCanvas.target.top + 50,
      //   ],
      //   {
      //     stroke: "#000",
      //     strokeWidth: 1.4,
      //   }
      // );

      // var text2 = new fabric.Text(random.toString(), {
      //   top: line2.top - 5,
      //   left: line2.left + 35,
      //   fontSize: 12,
      // });

      // var text = new fabric.Text("JustiChain(AI) Signed By:", {
      //   top: line1.top - 5,
      //   left: line1.left + 35,
      //   fontSize: 12,
      // });

      // localCanvas.canvas.add(line1);
      // localCanvas.canvas.add(line2);
      // localCanvas.canvas.add(line3);
      // // localCanvas.canvas.add(line4);

      // localCanvas.canvas.add(text)
      // localCanvas.canvas.add(text2)

        // localCanvas.canvas.add(img);

      // localCanvas.canvas.add(text2)
      // localCanvas.canvas.add(text)
      // localCanvas.canvas.add(line1)
      // localCanvas.canvas.add(line2)
      // localCanvas.canvas.add(line3)

      // let group = new fabric.Group([ img,text2, text, line1, line2, line3, ], {
      //   id: "impImg",
      //   selectable: false,
      // });

      // localCanvas.canvas.add(group);

    //   localCanvas.canvas.requestRenderAll();
    // });
  };

  const saveFont = async (font) => {
    console.log(font);

    // let elements = []

       allCanvases.pages.map((pages, pagesIndex) => {
        pages.objects.map(async (pageObject, pageObjectIndex) => {
          if (pageObject.type === "group") {
            let unVisibleIndex = undefined;

            if (
              allCanvases.pages[pagesIndex].objects[pageObjectIndex].visible ==
              true
            ) {

              // setTimeout(() => { 


            // if(pageObjectIndex === 0){



            let data  =  await  buildSign(
                {
                  canvas: mainCanvases[pagesIndex],
                  target:
                    allCanvases.pages[pagesIndex].objects[pageObjectIndex],
                },
                "",
                
                font,
              );

             mainCanvases[pagesIndex].add(data);


mainCanvases[pagesIndex].requestRenderAll();
// }

// elements.push(data)
               // }
               // }, 2000)
             
            }
          }
        });
      });





              setIsHandWritingFontModalOpen(false)


  }

  const saveSignature = () => {
    let random = Math.floor(Math.random() * 1000000000);
    console.log(random, "random");
    // try {

    if (signatureRef.current) {
      // console.log(
      //   signatureRef.current.getTrimmedCanvas(),
      //   "signatureRef.current"
      // );
      const dataURL = signatureRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      // console.log(dataURL, "dataURL");

      var imageHeight = 0;
      var imageWidth = 0;

      console.log(allCanvases, "allCanvases");

      allCanvases.pages.forEach((pages, pagesIndex) => {
        pages.objects.forEach((pageObject, pageObjectIndex) => {
          if (pageObject.type === "group") {
            let unVisibleIndex = undefined;

            if (
              allCanvases.pages[pagesIndex].objects[pageObjectIndex].visible ==
              true
            ) {
              console.log(
                allCanvases.pages[pagesIndex].objects[pageObjectIndex],
                "allCanvases.pages[pagesIndex].objects[pageObjectIndex]"
              );

              buildSign(
                {
                  canvas: mainCanvases[pagesIndex],
                  target:
                    allCanvases.pages[pagesIndex].objects[pageObjectIndex],
                },
                dataURL
              );
            }
          }
        });
      });

              // activeCanvas.canvas.remove(activeCanvas.target);
      //       let image =   fabric.Image.fromURL(dataURL, function (img) {
      //           // console.log(img, "imgimgimg");
      //           img.left = activeCanvas.left - 0;
      //           img.top = activeCanvas.top - 0;
      //                         let scale = 100 / img.width;

      //           img.set({
      //             scaleX: scale,
      //             scaleY: scale,
      //             id : "signImg"
      //           });
      //           img.background = "#000"
      //           img.selectable = false;

      // let line1 = new fabric.Line([activeCanvas.target.left - 10, activeCanvas.target.top-10, activeCanvas.target.left + 20,  activeCanvas.target.top - 10],  {
      //            stroke: '#000',
      //                       strokeWidth: 1.4

      //         })

      //        console.log({ target: activeCanvas.target }, "show things")

      //         let line2 = new fabric.Line([activeCanvas.target.left-10, activeCanvas.target.top +  65 , activeCanvas.target.left + 20,  activeCanvas.target.top + 65], {
      //            stroke: '#000',
      //                       strokeWidth: 1.4

      //         })

      //       let line3 = new fabric.Line([activeCanvas.target.left-10, activeCanvas.target.top-10, activeCanvas.target.left -10 ,  activeCanvas.target.top +65], {
      //            stroke: '#000',
      //                       strokeWidth: 1.4

      //         })

      //         let line4 = new fabric.Line([activeCanvas.target.left + activeCanvas.target.width / 2, activeCanvas.target.top, activeCanvas.target.left + activeCanvas.target.width / 2,  activeCanvas.target.top +50], {
      //            stroke: '#000',
      //            strokeWidth: 1.4
      //         })

      //        var text2 = new fabric.Text(random.toString(), { top: line2.top - 5, left: line2.left + 35   , fontSize: 12} )

      //        var text = new fabric.Text("JustiChain(AI) Signed By:", { top: line1.top - 5, left: line1.left + 35   , fontSize: 12} )

      //         // activeCanvas.canvas.add(line1);
      //         // activeCanvas.canvas.add(line2);
      //         // activeCanvas.canvas.add(line3);
      //         // // activeCanvas.canvas.add(line4);

      //         // activeCanvas.canvas.add(text)
      //         // activeCanvas.canvas.add(text2)

      //         //   activeCanvas.canvas.add(img);

      //        let group = new fabric.Group([img, text2, text, line1, line2, line3], {
      //         id: "impImg",
      //         selectable: false,
      //        })

      //        activeCanvas.canvas.add(group)

      //           activeCanvas.canvas.requestRenderAll();

      //         });

      //  let imgObj =    activeCanvas?.canvas?.getObjects().forEach(function(o) {
      //     if(o.id === "signImg") {
      //         activeCanvas?.canvas.setActiveObject(o);
      //     }
      // })

      // activeCanvas.canvas.requestRenderAll();
    }
    closeModal();
    // } catch (error) {
    //   // console.log(error, "error-unVisibleObject");
    // }
  };
  const validation = () => {
    const error = {};
    if (formData.otp == "") {
      error.otp = "otp is Required";
      setotpErr(true);
    } else {
      setotpErr(false);
    }
    setvalidateErr(error);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // console.log(formData, "formData");
  };
  const LinkVerify = async () => {
    try {
      var obj = {
        Email: emailref.current,
        File: fileref.current,
        ID: IDref.current,
      };
      // console.log(obj, "obj");
      var data = {
        apiUrl: apiService.LinkVerify,
        payload: obj,
      };
      var resp = await postMethod(data);
      setLoader(false);
      if (resp.Status == true) {
        // toast.success(resp.Message);
        Setjsondata(resp.Data);
        setEmail(resp.Email);
        signerVerify();
        // console.log(resp.Data, "resp.Data");
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      // console.log(error, "error");
    }
  };
  const signerVerify = async () => {
    try {
      validation();
      if (otpErrref.current == true) {
        var obj = {
          Email: emailref.current,
          File: fileref.current,
          ID: IDref.current,
        };
        var data = {
          apiUrl: apiService.signerverify,
          payload: obj,
        };
        // console.log(data, "data");
        var resp = await postMethod(data);
        setLoader(false);
        if (resp.Status == true) {
          // toast.success(resp.Message);
          setOTPverify(true);
          handleFileChange(
            `https://gateway.pinata.cloud/ipfs/${fileref.current}`
          );
        } else {
          // toast.error(resp.Message);
        }
      } else {
        // console.log("OTP required");
      }
    } catch (error) {
      // console.log(error, "error");
    }
  };
  const Updatesign = async () => {
    try {
      validation();
      if (otpErrref.current == true) {
        setPdfLoader(true);

        const JsonFile = pdfInstance.serializePdf(async (string) => {
          pdfInstance.getFileAsArrayBuffer();

          let pdfFileString = pdfInstance.pdfFileAsString;
          var obj = {
            Email: emailref.current,
            File: fileref.current,
            UpdateFile: fileref.current,
            ID: ID,
            JsonFile: string,
            pdfFileString,
          };

          console.log(obj, "obj--------");
          var data = {
            apiUrl: apiService.Updatesign,
            payload: obj,
          };
          console.log(data, "data");

          var resp = await postMethod(data);
          if (resp.Status == true) {
            console.log(resp.Data, "rep data");
            if (resp.Data != undefined) {
              console.log("going ");
              handleViewDocument(resp.Data);
            } else {
              navigate("/uploadoc");
              setPdfLoader(false);
            }

            toast.success(resp.Message);
          } else {
            toast.error(resp.Message);
          }
        });
      } else {
        // console.log("OTP required");
      }
    } catch (error) {
      // console.log(error, "error");
    }
  };
  useEffect(() => {
    // console.log(isModalOpen, "isModalOpen changed");
  }, [isModalOpen]);

  const handleViewDocument = async (data) => {
    setdatas(data);
    console.log(data, "data");
    // try {
    let canvasDataJson = [];
    let signerDataJson = [];

    data?.Signer?.forEach((item) => {
      console.log(item, "item");

      signerDataJson.push(item);

      if (item?.Jsonfile?.length !== 0) {
        console.log(item?.Jsonfile?.length, "item?.Jsonfile?.length");

        if (canvasDataJson.length === 0) {
          console.log(canvasDataJson.length, "item?.Jsonfile?.length");

          canvasDataJson = replaceJsonDataForPDF(
            JSON.parse(data.Jsondata),
            JSON.parse(item.Jsonfile)
          );
        } else {
          console.log(canvasDataJson.length, "item?.Jsonfile?.length");

          canvasDataJson = replaceJsonDataForPDF(
            canvasDataJson,
            JSON.parse(item.Jsonfile)
          );
        }
      } else {
        console.log(item?.Jsonfile?.length, "item?.Jsonfile?.length");
      }
    });

    if (canvasDataJson.length === 0) {
      canvasDataJson = data.Jsondata;
    }
    let pdfDomElement = document.getElementById("pdfontainer");

    if (pdfDomElement) {
      pdfDomElement.replaceChildren();
    }

    console.log(canvasDataJson, "canvas data json");

    // pdfInstance.loadFromJSON(canvasDataJson)

    const pdfFile = "https://gateway.pinata.cloud/ipfs/" + data.File;

    var pdf = new PDFAnnotate("pdfontainer", pdfFile, {
      onPageUpdated(page, oldData, newData) { },
      ready(canvases) {
        let inputElements =
          typeof canvasDataJson === "string"
            ? JSON.parse(canvasDataJson)
            : canvasDataJson;

        inputElements.pages.forEach((item, index) => {
          item.objects.forEach((obj) => {



              if (obj.type === "image") {
                fabric.Image.fromObject(obj, function (oImg) {
                  oImg.selectable = false;
                  canvases[index].add(oImg);
                });
              }

              if (obj.type === "line") {
                fabric.Line.fromObject(obj, function (oImg) {
                  oImg.selectable = false;

                  canvases[index].add(oImg);
                });
              }

                            if (obj.type === "text") {
                fabric.Text.fromObject(obj, function (oImg) {
                  oImg.selectable = false;

                  canvases[index].add(oImg);
                });
              }

              if (obj.type === "group") {
                console.log("group", obj)
                fabric.Group.fromObject(obj, (oGroup) => {
                  oGroup.selectable = false;
                  canvases[index].add(oGroup);
                });
              }
          });
          canvases[index].requestRenderAll();
        });
      },
      scale: 1.5,
      pageImageCompression: "MEDIUM",
    });

    setTimeout(async function () {
      pdf.getFileAsArrayBuffer();
      Setoutput(pdf.pdfFileAsString);

      var obj = {
        Id: datasref.current._id,
        Data: pdf.pdfFileAsString,
      };
      console.log(obj, "obj--------");
      var data = {
        apiUrl: apiService.Ethsigncheck,
        payload: obj,
      };
      console.log(data, "data");

      var resp = await postMethod(data);

      console.log(resp, "response ---");

      if (resp.Status == true) {
        navigate("/uploadoc");
        setPdfLoader(false);
      } else {
        setPdfLoader(false);
        navigate("/uploadoc");
      }
    }, 2000);
    // } catch (error) {
    //   console.log(error, "error");
    // }
  };

  function replaceJsonDataForPDF(canvasData, userData) {
    try {
      canvasData.pages.forEach((canvasPage, canvasPageIndex) => {
        canvasPage.objects.forEach(
          (canvasPageObjects, canvasPageObjectsIndex) => {

            userData.pages.forEach((userPages, userPagesIndex) => {
              userPages.objects.forEach(
                (userPageObjects, userPagesObjectsIndex) => {
 
                  if (userPageObjects.type == "group") {


                    console.log({ "canvasData.pages[canvasPageIndex].top": canvasData.pages[canvasPageIndex].objects[canvasPageObjectsIndex].top, "userData.pages[userPagesIndex].top": userData.pages[userPagesIndex].objects[userPagesObjectsIndex].top })
                    if (

                                          Math.round(canvasData.pages[canvasPageIndex].objects[canvasPageObjectsIndex].top) - 15 == Math.round(userData.pages[userPagesIndex].objects[userPagesObjectsIndex].top)

                    ) {
              

                      // let filter= canvasData.pages[canvasPageIndex].objects.filter((item, index) =>  index != canvasPageObjectsIndex)
                      


                      canvasData.pages[canvasPageIndex].objects[
                        canvasPageObjectsIndex
                      ] =
                        userData.pages[userPagesIndex].objects[
                        userPagesObjectsIndex
                        ];





                      // canvasData.pages[canvasPageIndex].objects = userData.pages[userPagesIndex].objects;


                      //   canvasData.pages[canvasPageIndex].objects[
                      //   canvasPageObjectsIndex
                      // ].push()
                    }
                  }

                }
              );
            });
          }
        );
      });



      // console.log({ canvasData}, "canvas data ")
      return canvasData;

    } catch (error) {
      // console.log(error, "error-unVisibleObject")
    }
  }

  return (
    <>
      <Header />
      <main class="mt-24">
        <section class="relative pt-10 pb-24">
          <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <img
              src={require("../img/gradient_light.jpg")}
              alt="gradient"
              class="h-full w-full"
            />
          </picture>
          <>
            <div class="px-6 xl:px-24">
              {Loader == true ? (
                <div class="grid grid-cols-1 mt-20 mb-20 gap-3 md:grid-cols-1 md:gap-[1.875rem] lg:grid-cols-1">
                  <img
                    width="100"
                    height="100"
                    class="my-5 d-block fill-jacarta-500 dark:fill-white mx-auto"
                    src={require("../img/loadinggif.gif")}
                  ></img>
                </div>
              )

                : (
                  <>
                    <div class="flex flex-wrap justify-between">
                      <div class="flexn space-x-2 mb-2">
                        <h1 class="pt-3 mb-2 font-display text-xl font-medium text-jacarta-700 dark:text-white">
                          Add Signature
                        </h1>
                        <p>
                          View, sign, manage, and download your contracts here.
                        </p>
                      </div>
                    </div>
                    <div class="lg:flex mt-6 justify-center">
                      <div class="lg:w-4/5 js-collections-content">
                        <div class="tab-content">
                          <div
                            class="tab-pane fade show active"
                            id="view-grid"
                            role="tabpanel"
                            aria-labelledby="view-grid-tab"
                          >
                            <div class="">
                              <article>
                                <div class="rounded-2.5xl justify-center mx-5 border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
                                  <div id="pdf-container"> </div>
                                  <div id="pdfontainer"> </div>
                                </div>
                                <div class="flex flex-wrap justify-end">
                                  <div class="flex space-x-2 my-4 mr-4">
                                    <label class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                                      <span class="dark:text-white group-hover:text-white">
                                        Update Signature
                                      </span>
                                      <input
                                        type="button"
                                        class="custom-file-input d-none"
                                        onClick={Updatesign}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </article>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </div>
            <Modal
              isOpen={isModalOpen}
              className="Signclass mx-auto d-block"
              toggle={openModal}
            >
              <div class="text-center modal-header">
                <h5 class="modal-title">
                  <h2 class="mb-0 font-display text-xl text-jacarta-700 dark:text-white lg:text-3xl xl:text-3xl">
                    <span class="animate-gradient">Add Signer</span>
                  </h2>
                </h5>
              </div>
              <ModalBody>
                <SignatureCanvas
                  ref={signatureRef}
                  canvasProps={{ width: 400, height: 200 }}
                />
              </ModalBody>
              <ModalFooter className="mx-auto d-blck">
                <Button color="primary" onClick={saveSignature}>
                  Add
                </Button>{" "}
                <Button color="secondary" onClick={openModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={isSignTypeModalOpen}
              className="Signclass mx-auto d-block"
              toggle={openModal}
            >
              <div class="text-center modal-header">
                <h5 class="modal-title">
                  <h2 class="mb-0 font-display text-xl text-jacarta-700 dark:text-white lg:text-3xl xl:text-3xl">
                    <span class="animate-gradient">Select Your Sign Type</span>
                  </h2>
                </h5>
              </div>

              <ModalFooter className="mx-auto d-blck">
                <Button color="primary" onClick={() => { setIsModalOpen(!isModalOpen)
                    setIsSignTypeModalOpen(!isSignTypeModalOpen)

                   }}>
                  Custom Drawing
                </Button>{" "}
                <Button color="secondary" onClick={() => {
                  setIsSignTypeModalOpen(false)
                    setIsHandWritingFontModalOpen(!isHandWritingFontModalOpen)
                }}>
                   Hand Writing Font
                </Button>
              </ModalFooter>
            </Modal>


          <Modal
              isOpen={isHandWritingFontModalOpen}
              className="Signclass mx-auto d-block"
            >
              <div class="text-center modal-header">
                <h5 class="modal-title">
                  <h2 class="mb-0 font-display text-xl text-jacarta-700 dark:text-white lg:text-3xl xl:text-3xl">
                    <span class="animate-gradient">Select Your Font For Sign</span>
                  </h2>
                </h5>
              </div>

              <ModalBody>
               <div className="mx-auto  md:flex wrap_s">
                  <div className="mb-1  md:pr-8 w-100_n">
                    <div class="mb-6">
                      <label
                        for="item-name"
                        class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                      >
                        Name
                      </label>


                  <input
                        type="text"
                        name="fullName"
                        value={signName}
                        onChange={e => setSignName(e.target.value)}
                        class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                        placeholder="Enter the Name to show for Sign"
                        required
                   />
 </div>
                   </div>
                   </div>

                    <div className="mx-auto  md:flex wrap_s">
                  <div className="mb-1  md:pr-8 w-100_n">
                    <div class="mb-6">

                   <div className="mx-auto  md:flex wrap_s">
                     <label
                        for="item-name"
                        class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                      >
                        Select One Of The Below Font For Your Sign 
                      </label>

                   <div style={{background: "#fff"}} onClick={() => saveFont("Lobster")} className="mb-1  md:pr-8 w-100_n">
                      <h2 style={{fontSize: "1.4rem"  , marginTop: "5px"}}>Lobster Font </h2>
                       <h2 style={{  color: "#000" , fontSize: "1.6rem",  fontFamily: "Lobster", marginTop: "5px", marginBottom: "5px"
                        }}>{signName}</h2>

                   </div>

                       <div style={{background: "#fff"}} onClick={() => saveFont("Moon Dance")} className="mb-1  md:pr-8 w-100_n">
                      <h2 style={{fontSize: "1.4rem"  , marginTop: "5px"}}>Moon Dance Font </h2>
                       <h2 style={{  color: "#000" , fontSize: "1.6rem",  fontFamily: "Moon Dance", marginTop: "5px", marginBottom: "5px"
                        }}>{signName}</h2>

                   </div>

                       <div style={{background: "#fff"}} onClick={() => saveFont("Great Vibes")} className="mb-1  md:pr-8 w-100_n">
                      <h2 style={{fontSize: "1.4rem"  , marginTop: "5px"}}>Great Vibes Font </h2>
                       <h2 style={{  color: "#000" , fontSize: "1.6rem",  fontFamily: "Great Vibes", marginTop: "5px", marginBottom: "5px"
                        }}>{signName}</h2>

                   </div>

                    <div style={{background: "#fff"}} onClick={() => saveFont("Pacifico")} className="mb-1  md:pr-8 w-100_n">
                      <h2 style={{fontSize: "1.4rem"  , marginTop: "5px"}}>Pacifico Font </h2>
                       <h2 style={{  color: "#000" , fontSize: "1.6rem",  fontFamily: "Pacifico", marginTop: "5px", marginBottom: "5px"
                        }}>{signName}</h2>

                   </div>


                          <div style={{background: "#fff"}} onClick={() => saveFont("Alex Brush")} className="mb-1  md:pr-8 w-100_n">
                      <h2 style={{fontSize: "1.4rem"  , marginTop: "5px"}}>Alex Brush Font </h2>
                       <h2 style={{  color: "#000" , fontSize: "1.6rem",  fontFamily: "Alex Brush", marginTop: "5px", marginBottom: "5px"
                        }}>{signName}</h2>

                   </div> 
                   

                  </div> 

                  </div>

                  </div>
                  </div>

              </ModalBody>

            </Modal>



          </>
        </section>

        {pdfLoader && (
          <div class="overlay">
            <div class="overlay__inner">
              <div class="overlay__content">
                <span class="spinner"></span>
              </div>
            </div>
          </div>
        )}
      </main>

      <Footer />
    </>
  );
}
export default TemplateMoreFiter;
