import { React, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Colleaction from "./pages/Colleaction";
import ItemsDetails from "./pages/ItemsDetails";
import Profile from "./pages/Profile";
import Create from "./pages/Create";
import Login from "./pages/Login";
import Client from "./pages/Client";
import Advacate from "./pages/Advacate";
import Editprofile from "./pages/Editprofile";
import Lawermore from "./pages/Lawermore";
import Checkout from "./pages/Checkout";
import Commingsoon from "./pages/Commingsoon";
import Editpdf from "./pages/Editpdf";
import Arbitrators from "./pages/Arbitrators";
import Mediators from "./pages/Mediators";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeAuthToken } from "./core/lib/localStorage";
import ClientProfile from "./pages/cleintProfile";
import ClientEdit from "./pages/clientEditProfile";
import TemplateMore from "./pages/templateMore";
import TemplateMoreFiter from "./pages/TemplateMoreFiter";
import AIchatPage from "./pages/AIchatPage";
import ForgotPassword from "./pages/ForgotPassword";
import Aicreatetemp from "./pages/Aicreatetemp";
import FormSubmit from "./pages/FormSubmit";
import Uploadoc from "./pages/Uploadoc";
import Viewdraft from "./pages/viewdraft";
import Viewfile from "./pages/viewuploaddoc";
import ContractUpload from "./pages/ContractUpload";
import WebNexusUser from "./pages/WebNexusUser";



function App() {
  function RequireAuth({ children }) {
    var data = localStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
  return (
    <>
      <>
        <BrowserRouter>
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/colleaction" element={<Colleaction />} />
            <Route
              path="/Item"
              element={
                <RequireAuth>
                  {" "}
                  <ItemsDetails />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/profile"
              element={
                <RequireAuth>
                  {" "}
                  <Profile />{" "}
                </RequireAuth>
              }
            />
            <Route path="/profile/:id" element={<Profile />} />
            <Route
              path="/create"
              element={
                <RequireAuth>
                  {" "}
                  <Create />{" "}
                </RequireAuth>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/clientregister" element={<Client />} />
            <Route path="/advacateclient" element={<Advacate />} />
            <Route path="/lawmarketai" element={<AIchatPage />} />
            <Route path="/arbitrators" element={<Arbitrators />} />
            <Route path="/mediators" element={<Mediators />} />

            <Route
              path="/editprofile"
              element={
                <RequireAuth>
                  {" "}
                  <Editprofile />{" "}
                </RequireAuth>
              }
            />
            <Route path="/lawermore" element={<Lawermore />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route
              path="/checkout"
              element={
                <RequireAuth>
                  {" "}
                  <Checkout />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/clientProfilers"
              element={
                <RequireAuth>
                  {" "}
                  <ClientProfile />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/contracts"
              element={
                <RequireAuth>
                  {" "}
                  <ContractUpload />{" "}
                </RequireAuth>
              }
            />
             <Route
              path="/viewdraft/:id"
              element={
                <RequireAuth>
                  {" "}
                  <Viewdraft />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/editpdf"
              element={
                <RequireAuth>
                  {" "}
                  <Editpdf />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/ClientEditProfile"
              element={
                <RequireAuth>
                  {" "}
                  <ClientEdit />{" "}
                </RequireAuth>
              }
            />

            <Route path="/commingsoon" element={<Commingsoon />} />
            <Route
              path="/checkout"
              element={
                <RequireAuth>
                  {" "}
                  <Checkout />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/clientProfilers"
              element={
                <RequireAuth>
                  {" "}
                  <ClientProfile />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/editpdf"
              element={
                <RequireAuth>
                  {" "}
                  <Editpdf />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/checkout"
              element={
                <RequireAuth>
                  {" "}
                  <Checkout />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/clientProfilers"
              element={
                <RequireAuth>
                  {" "}
                  <ClientProfile />{" "}
                </RequireAuth>
              }
            />
            <Route path="/alltemplates" element={<TemplateMore />} />
            <Route path="/templates" element={<TemplateMoreFiter />} />
            <Route path="/web5-nexus-user" element={<WebNexusUser />} />
            <Route
              path="/uploadoc"
              element={
                <RequireAuth>
                  <Uploadoc />
                </RequireAuth>
              }
            />
            <Route
              path="/viewfile"
              element={
                <RequireAuth>
                  <Viewfile />
                </RequireAuth>
              }
            />
            <Route
              path="/templateai"
              element={
                <RequireAuth>
                  {" "}
                  <FormSubmit />{" "}
                </RequireAuth>
              }
            />
          </Routes>
        </BrowserRouter>
      </>
    </>
  );
}

export default App;
