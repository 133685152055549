import React, { useEffect, useState } from "react";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/v1";
import useStateRef from "react-usestateref";

function SelectUserType({ userInfo, userEmail, showAdvocateOrClientSignup }) {
  const [Nouser, SetNouser, Nouserref] = useStateRef(false);

  useEffect(() => {
    checkuser();
  }, []);

  const checkuser = async () => {
    console.log({ userEmail, userInfo });

    var data = {
      apiUrl: apiService.checkUserExist,
      payload: {
        email: userEmail,
        name: userInfo?.name,
        profileImage: "",
        typeOfLogin: userInfo?.typeOfLogin,
        verifier: userInfo?.verifier,
        verifierId: userInfo?.verifierId,
        wallet: userInfo?.address,
        balance: userInfo?.balance,
      },
    };
    var resp = await postMethod(data);

    console.log(resp, "resp");

    if (
      resp.isUserAdvocateRegistered == false &&
      resp.isUserClientRegistered == false
    ) {
      SetNouser(true);
    } else {
      showAdvocateOrClientSignup(resp);
    }
  };

  const handleUserClick = async (usertype) => {
    if (usertype == "advocate") {
      console.log(usertype);
      showAdvocateOrClientSignup({ isUserAdvocateRegistered: false });
    } else {
      console.log(usertype, "ininknknknknk");
      showAdvocateOrClientSignup({ isUserClientRegistered: false });
    }
  };

  return (
    <>
      {Nouserref.current == true ? (
        <>
          <div className="container" id="kt_content_container">
            <div class="mb-10 ">
              <div class="rounded-2.5xl h-[50px] border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadoHw-lg dark:border-jacarta-700 dark:bg-jacarta-700 flex flex-column justify-center">
                <div
                  class="tab-pane fade show active"
                  id="wallet-connect"
                  role="tabpanel"
                  aria-labelledby="wallet-connect-tab"
                >
                  <h1>Select User Type </h1>
                  <div className="flex flex-row gap-4">
                    <div className="form_betwine">
                      <button
                        onClick={() => handleUserClick("advocate")}
                        class=" inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                      >
                        Advocate
                      </button>
                    </div>
                    <div className="form_betwine">
                      <button
                        onClick={() => handleUserClick("client")}
                        class=" inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                      >
                        Client
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default SelectUserType;
