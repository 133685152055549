import React from 'react'

function Country({ uploadDetails, changeContent }) {
    return (
        <>
            <select
                name="country"
                onChange={changeContent}
                value={uploadDetails.country}
                data-value={uploadDetails.country}
                aria-label="Select a Country"
                data-placeholder="Select a country..."
                className="form-select form-select-solid form-select-lg fw-bold"
            >
                <option value="">Origin of Document...</option>
                <option
                    data-kt-flag="flags/afghanistan.svg"
                    value="Afghanistan"
                >
                    Afghanistan
                </option>
                <option
                    data-kt-flag="flags/aland-islands.svg"
                    value="Aland Islands"
                >
                    Aland Islands
                </option>
                <option
                    data-kt-flag="flags/albania.svg"
                    value="Albania"
                >
                    Albania
                </option>
                <option
                    data-kt-flag="flags/algeria.svg"
                    value="Algeria"
                >
                    Algeria
                </option>
                <option
                    data-kt-flag="flags/american-samoa.svg"
                    value="American Samoa"
                >
                    American Samoa
                </option>
                <option
                    data-kt-flag="flags/andorra.svg"
                    value="Andorra"
                >
                    Andorra
                </option>
                <option
                    data-kt-flag="flags/angola.svg"
                    value="Angola"
                >
                    Angola
                </option>
                <option
                    data-kt-flag="flags/anguilla.svg"
                    value="Anguilla"
                >
                    Anguilla
                </option>
                <option
                    data-kt-flag="flags/antigua-and-barbuda.svg"
                    value="Antigua and Barbuda"
                >
                    Antigua and Barbuda
                </option>
                <option
                    data-kt-flag="flags/argentina.svg"
                    value="Argentina"
                >
                    Argentina
                </option>
                <option
                    data-kt-flag="flags/armenia.svg"
                    value="Armenia"
                >
                    Armenia
                </option>
                <option
                    data-kt-flag="flags/aruba.svg"
                    value="Aruba"
                >
                    Aruba
                </option>
                <option
                    data-kt-flag="flags/australia.svg"
                    value="Australia"
                >
                    Australia
                </option>
                <option
                    data-kt-flag="flags/austria.svg"
                    value="Austria"
                >
                    Austria
                </option>
                <option
                    data-kt-flag="flags/azerbaijan.svg"
                    value="Azerbaijan"
                >
                    Azerbaijan
                </option>
                <option
                    data-kt-flag="flags/bahamas.svg"
                    value="Bahamas"
                >
                    Bahamas
                </option>
                <option
                    data-kt-flag="flags/bahrain.svg"
                    value="Bahrain"
                >
                    Bahrain
                </option>
                <option
                    data-kt-flag="flags/bangladesh.svg"
                    value="Bangladesh"
                >
                    Bangladesh
                </option>
                <option
                    data-kt-flag="flags/barbados.svg"
                    value="Barbados"
                >
                    Barbados
                </option>
                <option
                    data-kt-flag="flags/belarus.svg"
                    value="Belarus"
                >
                    Belarus
                </option>
                <option
                    data-kt-flag="flags/belgium.svg"
                    value="Belgium"
                >
                    Belgium
                </option>
                <option
                    data-kt-flag="flags/belize.svg"
                    value="Belize"
                >
                    Belize
                </option>
                <option
                    data-kt-flag="flags/benin.svg"
                    value="Benin"
                >
                    Benin
                </option>
                <option
                    data-kt-flag="flags/bermuda.svg"
                    value="Bermuda"
                >
                    Bermuda
                </option>
                <option
                    data-kt-flag="flags/bhutan.svg"
                    value="Bhutan"
                >
                    Bhutan
                </option>
                <option
                    data-kt-flag="flags/bolivia.svg"
                    value="Bolivia, Plurinational State of"
                >
                    Bolivia, Plurinational State of
                </option>
                <option
                    data-kt-flag="flags/bonaire.svg"
                    value="Bonaire, Sint Eustatius and Saba"
                >
                    Bonaire, Sint Eustatius and Saba
                </option>
                <option
                    data-kt-flag="flags/bosnia-and-herzegovina.svg"
                    value="Bosnia and Herzegovina"
                >
                    Bosnia and Herzegovina
                </option>
                <option
                    data-kt-flag="flags/botswana.svg"
                    value="Botswana"
                >
                    Botswana
                </option>
                <option
                    data-kt-flag="flags/brazil.svg"
                    value="Brazil"
                >
                    Brazil
                </option>
                <option
                    data-kt-flag="flags/british-indian-ocean-territory.svg"
                    value="British Indian Ocean Territory"
                >
                    British Indian Ocean Territory
                </option>
                <option
                    data-kt-flag="flags/brunei.svg"
                    value="Brunei Darussalam"
                >
                    Brunei Darussalam
                </option>
                <option
                    data-kt-flag="flags/bulgaria.svg"
                    value="Bulgaria"
                >
                    Bulgaria
                </option>
                <option
                    data-kt-flag="flags/burkina-faso.svg"
                    value="Burkina Faso"
                >
                    Burkina Faso
                </option>
                <option
                    data-kt-flag="flags/burundi.svg"
                    value="Burundi"
                >
                    Burundi
                </option>
                <option
                    data-kt-flag="flags/cambodia.svg"
                    value="Cambodia"
                >
                    Cambodia
                </option>
                <option
                    data-kt-flag="flags/cameroon.svg"
                    value="Cameroon"
                >
                    Cameroon
                </option>
                <option
                    data-kt-flag="flags/canada.svg"
                    value="Canada"
                >
                    Canada
                </option>
                <option
                    data-kt-flag="flags/cape-verde.svg"
                    value="Cape Verde"
                >
                    Cape Verde
                </option>
                <option
                    data-kt-flag="flags/cayman-islands.svg"
                    value="Cayman Islands"
                >
                    Cayman Islands
                </option>
                <option
                    data-kt-flag="flags/central-african-republic.svg"
                    value="Central African Republic"
                >
                    Central African Republic
                </option>
                <option
                    data-kt-flag="flags/chad.svg"
                    value="Chad"
                >
                    Chad
                </option>
                <option
                    data-kt-flag="flags/chile.svg"
                    value="Chile"
                >
                    Chile
                </option>
                <option
                    data-kt-flag="flags/china.svg"
                    value="China"
                >
                    China
                </option>
                <option
                    data-kt-flag="flags/christmas-island.svg"
                    value="Christmas Island"
                >
                    Christmas Island
                </option>
                <option
                    data-kt-flag="flags/cocos-island.svg"
                    value="Cocos (Keeling) Islands"
                >
                    Cocos (Keeling) Islands
                </option>
                <option
                    data-kt-flag="flags/colombia.svg"
                    value="Colombia"
                >
                    Colombia
                </option>
                <option
                    data-kt-flag="flags/comoros.svg"
                    value="Comoros"
                >
                    Comoros
                </option>
                <option
                    data-kt-flag="flags/cook-islands.svg"
                    value="Cook Islands"
                >
                    Cook Islands
                </option>
                <option
                    data-kt-flag="flags/costa-rica.svg"
                    value="Costa Rica"
                >
                    Costa Rica
                </option>
                <option
                    data-kt-flag="flags/ivory-coast.svg"
                    value="Côte d'Ivoire"
                >
                    Côte d'Ivoire
                </option>
                <option
                    data-kt-flag="flags/croatia.svg"
                    value="Croatia"
                >
                    Croatia
                </option>
                <option
                    data-kt-flag="flags/cuba.svg"
                    value="Cuba"
                >
                    Cuba
                </option>
                <option
                    data-kt-flag="flags/curacao.svg"
                    value="Curaçao"
                >
                    Curaçao
                </option>
                <option
                    data-kt-flag="flags/czech-republic.svg"
                    value="Czech Republic"
                >
                    Czech Republic
                </option>
                <option
                    data-kt-flag="flags/denmark.svg"
                    value="Denmark"
                >
                    Denmark
                </option>
                <option
                    data-kt-flag="flags/djibouti.svg"
                    value="Djibouti"
                >
                    Djibouti
                </option>
                <option
                    data-kt-flag="flags/dominica.svg"
                    value="Dominica"
                >
                    Dominica
                </option>
                <option
                    data-kt-flag="flags/dominican-republic.svg"
                    value="Dominican Republic"
                >
                    Dominican Republic
                </option>
                <option
                    data-kt-flag="flags/ecuador.svg"
                    value="Ecuador"
                >
                    Ecuador
                </option>
                <option
                    data-kt-flag="flags/egypt.svg"
                    value="Egypt"
                >
                    Egypt
                </option>
                <option
                    data-kt-flag="flags/el-salvador.svg"
                    value="El Salvador"
                >
                    El Salvador
                </option>
                <option
                    data-kt-flag="flags/equatorial-guinea.svg"
                    value="Equatorial Guinea"
                >
                    Equatorial Guinea
                </option>
                <option
                    data-kt-flag="flags/eritrea.svg"
                    value="Eritrea"
                >
                    Eritrea
                </option>
                <option
                    data-kt-flag="flags/estonia.svg"
                    value="Estonia"
                >
                    Estonia
                </option>
                <option
                    data-kt-flag="flags/ethiopia.svg"
                    value="Ethiopia"
                >
                    Ethiopia
                </option>
                <option
                    data-kt-flag="flags/falkland-islands.svg"
                    value="Falkland Islands (Malvinas)"
                >
                    Falkland Islands (Malvinas)
                </option>
                <option
                    data-kt-flag="flags/fiji.svg"
                    value="Fiji"
                >
                    Fiji
                </option>
                <option
                    data-kt-flag="flags/finland.svg"
                    value="Finland"
                >
                    Finland
                </option>
                <option
                    data-kt-flag="flags/france.svg"
                    value="France"
                >
                    France
                </option>
                <option
                    data-kt-flag="flags/french-polynesia.svg"
                    value="French Polynesia"
                >
                    French Polynesia
                </option>
                <option
                    data-kt-flag="flags/gabon.svg"
                    value="Gabon"
                >
                    Gabon
                </option>
                <option
                    data-kt-flag="flags/gambia.svg"
                    value="Gambia"
                >
                    Gambia
                </option>
                <option
                    data-kt-flag="flags/georgia.svg"
                    value="Georgia"
                >
                    Georgia
                </option>
                <option
                    data-kt-flag="flags/germany.svg"
                    value="Germany"
                >
                    Germany
                </option>
                <option
                    data-kt-flag="flags/ghana.svg"
                    value="Ghana"
                >
                    Ghana
                </option>
                <option
                    data-kt-flag="flags/gibraltar.svg"
                    value="Gibraltar"
                >
                    Gibraltar
                </option>
                <option
                    data-kt-flag="flags/greece.svg"
                    value="Greece"
                >
                    Greece
                </option>
                <option
                    data-kt-flag="flags/greenland.svg"
                    value="Greenland"
                >
                    Greenland
                </option>
                <option
                    data-kt-flag="flags/grenada.svg"
                    value="Grenada"
                >
                    Grenada
                </option>
                <option
                    data-kt-flag="flags/guam.svg"
                    value="Guam"
                >
                    Guam
                </option>
                <option
                    data-kt-flag="flags/guatemala.svg"
                    value="Guatemala"
                >
                    Guatemala
                </option>
                <option
                    data-kt-flag="flags/guernsey.svg"
                    value="Guernsey"
                >
                    Guernsey
                </option>
                <option
                    data-kt-flag="flags/guinea.svg"
                    value="Guinea"
                >
                    Guinea
                </option>
                <option
                    data-kt-flag="flags/guinea-bissau.svg"
                    value="Guinea-Bissau"
                >
                    Guinea-Bissau
                </option>
                <option
                    data-kt-flag="flags/haiti.svg"
                    value="Haiti"
                >
                    Haiti
                </option>
                <option
                    data-kt-flag="flags/vatican-city.svg"
                    value="Holy See (Vatican City State)"
                >
                    Holy See (Vatican City State)
                </option>
                <option
                    data-kt-flag="flags/honduras.svg"
                    value="Honduras"
                >
                    Honduras
                </option>
                <option
                    data-kt-flag="flags/hong-kong.svg"
                    value="Hong Kong"
                >
                    Hong Kong
                </option>
                <option
                    data-kt-flag="flags/hungary.svg"
                    value="Hungary"
                >
                    Hungary
                </option>
                <option
                    data-kt-flag="flags/iceland.svg"
                    value="Iceland"
                >
                    Iceland
                </option>
                <option
                    data-kt-flag="flags/india.svg"
                    value="India"
                >
                    India
                </option>
                <option
                    data-kt-flag="flags/indonesia.svg"
                    value="Indonesia"
                >
                    Indonesia
                </option>
                <option
                    data-kt-flag="flags/iran.svg"
                    value="Iran, Islamic Republic of"
                >
                    Iran, Islamic Republic of
                </option>
                <option
                    data-kt-flag="flags/iraq.svg"
                    value="Iraq"
                >
                    Iraq
                </option>
                <option
                    data-kt-flag="flags/ireland.svg"
                    value="Ireland"
                >
                    Ireland
                </option>
                <option
                    data-kt-flag="flags/isle-of-man.svg"
                    value="Isle of Man"
                >
                    Isle of Man
                </option>
                <option
                    data-kt-flag="flags/israel.svg"
                    value="Israel"
                >
                    Israel
                </option>
                <option
                    data-kt-flag="flags/italy.svg"
                    value="Italy"
                >
                    Italy
                </option>
                <option
                    data-kt-flag="flags/jamaica.svg"
                    value="Jamaica"
                >
                    Jamaica
                </option>
                <option
                    data-kt-flag="flags/japan.svg"
                    value="Japan"
                >
                    Japan
                </option>
                <option
                    data-kt-flag="flags/jersey.svg"
                    value="Jersey"
                >
                    Jersey
                </option>
                <option
                    data-kt-flag="flags/jordan.svg"
                    value="Jordan"
                >
                    Jordan
                </option>
                <option
                    data-kt-flag="flags/kazakhstan.svg"
                    value="Kazakhstan"
                >
                    Kazakhstan
                </option>
                <option
                    data-kt-flag="flags/kenya.svg"
                    value="Kenya"
                >
                    Kenya
                </option>
                <option
                    data-kt-flag="flags/kiribati.svg"
                    value="Kiribati"
                >
                    Kiribati
                </option>
                <option
                    data-kt-flag="flags/north-korea.svg"
                    value="Korea"
                >
                    Korea, Democratic People's Republic of
                </option>
                <option
                    data-kt-flag="flags/kuwait.svg"
                    value="Kuwait"
                >
                    Kuwait
                </option>
                <option
                    data-kt-flag="flags/kyrgyzstan.svg"
                    value="Kyrgyzstan"
                >
                    Kyrgyzstan
                </option>
                <option
                    data-kt-flag="flags/laos.svg"
                    value="Lao"
                >
                    Lao People's Democratic Republic
                </option>
                <option
                    data-kt-flag="flags/latvia.svg"
                    value="Latvia"
                >
                    Latvia
                </option>
                <option
                    data-kt-flag="flags/lebanon.svg"
                    value="Lebanon"
                >
                    Lebanon
                </option>
                <option
                    data-kt-flag="flags/lesotho.svg"
                    value="Lesotho"
                >
                    Lesotho
                </option>
                <option
                    data-kt-flag="flags/liberia.svg"
                    value="Liberia"
                >
                    Liberia
                </option>
                <option
                    data-kt-flag="flags/libya.svg"
                    value="Libya"
                >
                    Libya
                </option>
                <option
                    data-kt-flag="flags/liechtenstein.svg"
                    value="Liechtenstein"
                >
                    Liechtenstein
                </option>
                <option
                    data-kt-flag="flags/lithuania.svg"
                    value="Lithuania"
                >
                    Lithuania
                </option>
                <option
                    data-kt-flag="flags/luxembourg.svg"
                    value="Luxembourg"
                >
                    Luxembourg
                </option>
                <option
                    data-kt-flag="flags/macao.svg"
                    value="Macao"
                >
                    Macao
                </option>
                <option
                    data-kt-flag="flags/madagascar.svg"
                    value="Madagascar"
                >
                    Madagascar
                </option>
                <option
                    data-kt-flag="flags/malawi.svg"
                    value="Malawi"
                >
                    Malawi
                </option>
                <option
                    data-kt-flag="flags/malaysia.svg"
                    value="Malaysia"
                >
                    Malaysia
                </option>
                <option
                    data-kt-flag="flags/maldives.svg"
                    value="Maldives"
                >
                    Maldives
                </option>
                <option
                    data-kt-flag="flags/mali.svg"
                    value="Mali"
                >
                    Mali
                </option>
                <option
                    data-kt-flag="flags/malta.svg"
                    value="Malta"
                >
                    Malta
                </option>
                <option
                    data-kt-flag="flags/marshall-island.svg"
                    value="Marshall Islands"
                >
                    Marshall Islands
                </option>
                <option
                    data-kt-flag="flags/martinique.svg"
                    value="Martinique"
                >
                    Martinique
                </option>
                <option
                    data-kt-flag="flags/mauritania.svg"
                    value="Mauritania"
                >
                    Mauritania
                </option>
                <option
                    data-kt-flag="flags/mauritius.svg"
                    value="Mauritius"
                >
                    Mauritius
                </option>
                <option
                    data-kt-flag="flags/mexico.svg"
                    value="Mexico"
                >
                    Mexico
                </option>
                <option
                    data-kt-flag="flags/micronesia.svg"
                    value="Micronesia"
                >
                    Micronesia, Federated States of
                </option>
                <option
                    data-kt-flag="flags/moldova.svg"
                    value="Moldova"
                >
                    Moldova, Republic of
                </option>
                <option
                    data-kt-flag="flags/monaco.svg"
                    value="Monaco"
                >
                    Monaco
                </option>
                <option
                    data-kt-flag="flags/mongolia.svg"
                    value="Mongolia"
                >
                    Mongolia
                </option>
                <option
                    data-kt-flag="flags/montenegro.svg"
                    value="Montenegro"
                >
                    Montenegro
                </option>
                <option
                    data-kt-flag="flags/montserrat.svg"
                    value="Montserrat"
                >
                    Montserrat
                </option>
                <option
                    data-kt-flag="flags/morocco.svg"
                    value="Morocco"
                >
                    Morocco
                </option>
                <option
                    data-kt-flag="flags/mozambique.svg"
                    value="Mozambique"
                >
                    Mozambique
                </option>
                <option
                    data-kt-flag="flags/myanmar.svg"
                    value="Myanmar"
                >
                    Myanmar
                </option>
                <option
                    data-kt-flag="flags/namibia.svg"
                    value="Namibia"
                >
                    Namibia
                </option>
                <option
                    data-kt-flag="flags/nauru.svg"
                    value="Nauru"
                >
                    Nauru
                </option>
                <option
                    data-kt-flag="flags/nepal.svg"
                    value="Nepal"
                >
                    Nepal
                </option>
                <option
                    data-kt-flag="flags/netherlands.svg"
                    value="Netherlands"
                >
                    Netherlands
                </option>
                <option
                    data-kt-flag="flags/new-zealand.svg"
                    value="New Zealand"
                >
                    New Zealand
                </option>
                <option
                    data-kt-flag="flags/nicaragua.svg"
                    value="Nicaragua"
                >
                    Nicaragua
                </option>
                <option
                    data-kt-flag="flags/niger.svg"
                    value="Niger"
                >
                    Niger
                </option>
                <option
                    data-kt-flag="flags/nigeria.svg"
                    value="Nigeria"
                >
                    Nigeria
                </option>
                <option
                    data-kt-flag="flags/niue.svg"
                    value="Niue"
                >
                    Niue
                </option>
                <option
                    data-kt-flag="flags/norfolk-island.svg"
                    value="Norfolk Island"
                >
                    Norfolk Island
                </option>
                <option
                    data-kt-flag="flags/northern-mariana-islands.svg"
                    value="Northern Mariana Islands"
                >
                    Northern Mariana Islands
                </option>
                <option
                    data-kt-flag="flags/norway.svg"
                    value="Norway"
                >
                    Norway
                </option>
                <option
                    data-kt-flag="flags/oman.svg"
                    value="Oman"
                >
                    Oman
                </option>
                <option
                    data-kt-flag="flags/pakistan.svg"
                    value="Pakistan"
                >
                    Pakistan
                </option>
                <option
                    data-kt-flag="flags/palau.svg"
                    value="Palau"
                >
                    Palau
                </option>
                <option
                    data-kt-flag="flags/palestine.svg"
                    value="Palestinian Territory"
                >
                    Palestinian Territory, Occupied
                </option>
                <option
                    data-kt-flag="flags/panama.svg"
                    value="Panama"
                >
                    Panama
                </option>
                <option
                    data-kt-flag="flags/papua-new-guinea.svg"
                    value="Papua New Guinea"
                >
                    Papua New Guinea
                </option>
                <option
                    data-kt-flag="flags/paraguay.svg"
                    value="Paraguay"
                >
                    Paraguay
                </option>
                <option
                    data-kt-flag="flags/peru.svg"
                    value="Peru"
                >
                    Peru
                </option>
                <option
                    data-kt-flag="flags/philippines.svg"
                    value="Philippines"
                >
                    Philippines
                </option>
                <option
                    data-kt-flag="flags/poland.svg"
                    value="Poland"
                >
                    Poland
                </option>
                <option
                    data-kt-flag="flags/portugal.svg"
                    value="Portugal"
                >
                    Portugal
                </option>
                <option
                    data-kt-flag="flags/puerto-rico.svg"
                    value="Puerto Rico"
                >
                    Puerto Rico
                </option>
                <option
                    data-kt-flag="flags/qatar.svg"
                    value="Qatar"
                >
                    Qatar
                </option>
                <option
                    data-kt-flag="flags/romania.svg"
                    value="Romania"
                >
                    Romania
                </option>
                <option
                    data-kt-flag="flags/russia.svg"
                    value="Russian Federation"
                >
                    Russian Federation
                </option>
                <option
                    data-kt-flag="flags/rwanda.svg"
                    value="Rwanda"
                >
                    Rwanda
                </option>
                <option
                    data-kt-flag="flags/st-barts.svg"
                    value="Saint Barthélemy"
                >
                    Saint Barthélemy
                </option>
                <option
                    data-kt-flag="flags/saint-kitts-and-nevis.svg"
                    value="Saint Kitts and Nevis"
                >
                    Saint Kitts and Nevis
                </option>
                <option
                    data-kt-flag="flags/st-lucia.svg"
                    value="Saint Lucia"
                >
                    Saint Lucia
                </option>
                <option
                    data-kt-flag="flags/sint-maarten.svg"
                    value="Saint Martin (French part)"
                >
                    Saint Martin (French part)
                </option>
                <option
                    data-kt-flag="flags/st-vincent-and-the-grenadines.svg"
                    value="Saint Vincent and the Grenadines"
                >
                    Saint Vincent and the Grenadines
                </option>
                <option
                    data-kt-flag="flags/samoa.svg"
                    value="Samoa"
                >
                    Samoa
                </option>
                <option
                    data-kt-flag="flags/san-marino.svg"
                    value="San Marino"
                >
                    San Marino
                </option>
                <option
                    data-kt-flag="flags/sao-tome-and-prince.svg"
                    value="Sao Tome and Principe"
                >
                    Sao Tome and Principe
                </option>
                <option
                    data-kt-flag="flags/saudi-arabia.svg"
                    value="Saudi Arabia"
                >
                    Saudi Arabia
                </option>
                <option
                    data-kt-flag="flags/senegal.svg"
                    value="Senegal"
                >
                    Senegal
                </option>
                <option
                    data-kt-flag="flags/serbia.svg"
                    value="Serbia"
                >
                    Serbia
                </option>
                <option
                    data-kt-flag="flags/seychelles.svg"
                    value="Seychelles"
                >
                    Seychelles
                </option>
                <option
                    data-kt-flag="flags/sierra-leone.svg"
                    value="Sierra Leone"
                >
                    Sierra Leone
                </option>
                <option
                    data-kt-flag="flags/singapore.svg"
                    value="Singapore"
                >
                    Singapore
                </option>
                <option
                    data-kt-flag="flags/sint-maarten.svg"
                    value="Sint Maarten (Dutch part)"
                >
                    Sint Maarten (Dutch part)
                </option>
                <option
                    data-kt-flag="flags/slovakia.svg"
                    value="Slovakia"
                >
                    Slovakia
                </option>
                <option
                    data-kt-flag="flags/slovenia.svg"
                    value="Slovenia"
                >
                    Slovenia
                </option>
                <option
                    data-kt-flag="flags/solomon-islands.svg"
                    value="Solomon Islands"
                >
                    Solomon Islands
                </option>
                <option
                    data-kt-flag="flags/somalia.svg"
                    value="Somalia"
                >
                    Somalia
                </option>
                <option
                    data-kt-flag="flags/south-africa.svg"
                    value="South Africa"
                >
                    South Africa
                </option>
                <option
                    data-kt-flag="flags/south-korea.svg"
                    value="South Korea"
                >
                    South Korea
                </option>
                <option
                    data-kt-flag="flags/south-sudan.svg"
                    value="South Sudan"
                >
                    South Sudan
                </option>
                <option
                    data-kt-flag="flags/spain.svg"
                    value="Spain"
                >
                    Spain
                </option>
                <option
                    data-kt-flag="flags/sri-lanka.svg"
                    value="Sri Lanka"
                >
                    Sri Lanka
                </option>
                <option
                    data-kt-flag="flags/sudan.svg"
                    value="Sudan"
                >
                    Sudan
                </option>
                <option
                    data-kt-flag="flags/suriname.svg"
                    value="Suriname"
                >
                    Suriname
                </option>
                <option
                    data-kt-flag="flags/swaziland.svg"
                    value="Swaziland"
                >
                    Swaziland
                </option>
                <option
                    data-kt-flag="flags/sweden.svg"
                    value="Sweden"
                >
                    Sweden
                </option>
                <option
                    data-kt-flag="flags/switzerland.svg"
                    value="Switzerland"
                >
                    Switzerland
                </option>
                <option
                    data-kt-flag="flags/syria.svg"
                    value="Syrian Arab Republic"
                >
                    Syrian Arab Republic
                </option>
                <option
                    data-kt-flag="flags/taiwan.svg"
                    value="Taiwan, Province of China"
                >
                    Taiwan, Province of China
                </option>
                <option
                    data-kt-flag="flags/tajikistan.svg"
                    value="Tajikistan"
                >
                    Tajikistan
                </option>
                <option
                    data-kt-flag="flags/tanzania.svg"
                    value="Tanzania"
                >
                    Tanzania, United Republic of
                </option>
                <option
                    data-kt-flag="flags/thailand.svg"
                    value="Thailand"
                >
                    Thailand
                </option>
                <option
                    data-kt-flag="flags/togo.svg"
                    value="Togo"
                >
                    Togo
                </option>
                <option
                    data-kt-flag="flags/tokelau.svg"
                    value="Tokelau"
                >
                    Tokelau
                </option>
                <option
                    data-kt-flag="flags/tonga.svg"
                    value="Tonga"
                >
                    Tonga
                </option>
                <option
                    data-kt-flag="flags/trinidad-and-tobago.svg"
                    value="Trinidad and Tobago"
                >
                    Trinidad and Tobago
                </option>
                <option
                    data-kt-flag="flags/tunisia.svg"
                    value="Tunisia"
                >
                    Tunisia
                </option>
                <option
                    data-kt-flag="flags/turkey.svg"
                    value="Turkey"
                >
                    Turkey
                </option>
                <option
                    data-kt-flag="flags/turkmenistan.svg"
                    value="Turkmenistan"
                >
                    Turkmenistan
                </option>
                <option
                    data-kt-flag="flags/turks-and-caicos.svg"
                    value="Turks"
                >
                    Turks and Caicos Islands
                </option>
                <option
                    data-kt-flag="flags/tuvalu.svg"
                    value="Tuvalu"
                >
                    Tuvalu
                </option>
                <option
                    data-kt-flag="flags/uganda.svg"
                    value="Uganda"
                >
                    Uganda
                </option>
                <option
                    data-kt-flag="flags/ukraine.svg"
                    value="Ukraine"
                >
                    Ukraine
                </option>
                <option
                    data-kt-flag="flags/united-arab-emirates.svg"
                    value="United Arab Emirates"
                >
                    United Arab Emirates
                </option>
                <option
                    data-kt-flag="flags/united-kingdom.svg"
                    value="United Kingdom"
                >
                    United Kingdom
                </option>
                <option
                    data-kt-flag="flags/united-states.svg"
                    value="United States"
                >
                    United States
                </option>
                <option
                    data-kt-flag="flags/uruguay.svg"
                    value="Uruguay"
                >
                    Uruguay
                </option>
                <option
                    data-kt-flag="flags/uzbekistan.svg"
                    value="Uzbekistan"
                >
                    Uzbekistan
                </option>
                <option
                    data-kt-flag="flags/vanuatu.svg"
                    value="Vanuatu"
                >
                    Vanuatu
                </option>
                <option
                    data-kt-flag="flags/venezuela.svg"
                    value="Venezuela"
                >
                    Venezuela, Bolivarian Republic of
                </option>
                <option
                    data-kt-flag="flags/vietnam.svg"
                    value="Vietnam"
                >
                    Vietnam
                </option>
                <option
                    data-kt-flag="flags/virgin-islands.svg"
                    value="Virgin Islands"
                >
                    Virgin Islands
                </option>
                <option
                    data-kt-flag="flags/yemen.svg"
                    value="Yemen"
                >
                    Yemen
                </option>
                <option
                    data-kt-flag="flags/zambia.svg"
                    value="Zambia"
                >
                    Zambia
                </option>
                <option
                    data-kt-flag="flags/zimbabwe.svg"
                    value="Zimbabwe"
                >
                    Zimbabwe
                </option>
            </select>
           
        </>
    )
}

export default Country