import React, { useRef, useEffect } from "react";
import useState from "react-usestateref";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./Header";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Modal, Table, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import apiService from "../core/service/v1/index";
import { postMethod, getMethod } from "../core/service/common.api";
import { PDFAnnotate } from "../utils/pdfAnnotate";
import { fabric } from "fabric";
fabric.Object.NUM_FRACTION_DIGITS = 18;

function TemplateMoreFiter() {
  fabric.Object.NUM_FRACTION_DIGITS = 18;

  const value = {
    fullName: "",
    email: "",
    category: "",
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [Signersdata, setSignersdata, Signersdataref] = useState([]);
  const [formData, setFormData] = useState(value);
  const [lawLoader, setlawLoader] = useState(false);
  const [loader, setloader] = useState(false);
  const [FullNameErr, setFullNameErr, FullNameErrref] = useState(false);
  const [EmailErr, setEmailErr, EmailErrref] = useState(false);
  const [signers, setSigners, signersref] = useState([]);
  const [pdf, setpdf, pdfref] = useState("");
  const [pdfname, setpdfname, pdfnameref] = useState("");
  const [validateErr, setvalidateErr] = useState("");
  const [result, setResult] = useState([]);
  const [nextap, setnextap] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [redoStack, setRedoStack] = useState([]);
  const [flag, setFlag] = useState("");
  const [isText, setIsText] = useState(false);
  const [buttonType, setButtonType] = useState("");
  const [selectedFile, setSelectedFile, selectedFileRef] = useState(null);
  const iframeRef = useRef(null);
  const navigate = useNavigate();
  const tempRef = useRef(null);
  const selectedFileref = useRef(null);
  const [pdfInstance, setPdfInstance] = useState(null);
  const [Jsondata, setJsondata, Jsondataref] = useState(false);

  console.log("nextap", nextap)

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const validation = () => {
    const error = {};
    if (formData.fullName == "") {
      error.fullName = "FullName is Required";
      setFullNameErr(true);
    } else if (formData.fullName.length < 3) {
      error.fullName = "Enter atleast 3 Letter";
      setFullNameErr(true);
    } else if (formData.fullName.length > 20) {
      error.fullName = "Enter 20 Letter only";
      setFullNameErr(true);
    } else {
      setFullNameErr(false);
    }

    if (formData.email == "") {
      error.email = "Email is Required";
      setEmailErr(true);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
    ) {
      error.email = "Invalid Email address";
      setEmailErr(true);
    } else {
      setEmailErr(false);
    }

    // if (formData.category == "") {
    //   setcategoryErr(true);
    // } else {
    //   setcategoryErr(false);
    // }

    setvalidateErr(error);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // console.log(formData, "formData");
  };
  const handleAddSigner = () => {
    try {
      validation();
      console.log("comings")
      if (
        formData.fullName &&
        formData.email &&
        // formData.category &&
        EmailErrref.current == false &&
        FullNameErrref.current == false
      ) {
        setSigners((prevSigners) => [...prevSigners, { ...formData }]);
        setFormData({
          fullName: "",
          email: "",
          // category: "",
        });
        // console.log(signersref.current, "signers");
        setModalOpen(false);
      } else {
        // console.log("field is empty");
      }
    } catch (error) {
      // console.log(error, "error-unVisibleObject")
    }

  };
  const handleDeleteSigner = (index) => {
    const updatedSigners = [...signers];
    updatedSigners.splice(index, 1);
    setSigners(updatedSigners);
  };
  const Nexttap = () => {

    if (signersref.current.length > 0) {
      setnextap(true);
      handleFileChange(selectedFile);
    } else {
      toast.error("Add Signer");
    }
  };
  const handleFile = (e) => {
    setSelectedFile(e);
  };
  const handleFileChange = (e) => {

    try {
      const pdfFile = e;
      var fileReader = new FileReader();
      fileReader.onload = function () {
        var typedarray = new Uint8Array(this.result);

        var pdf = new PDFAnnotate("pdf-container", typedarray, {
          onPageUpdated(page, oldData, newData) {
            // console.log(page, oldData, newData);
          },
          ready(canvases) {
            // console.log("Plugin initialized successfully");
            // console.log({
            //   canvases,
            // });

            canvases?.forEach((canvas) => {
              canvas.on("mouse:up", (e) => {
                // console.log("mouse clicked", e);
              });

              canvas?.on("mouse:dblclick", (e) => {
                if (e.target) {
                  // console.log(e.target, "-----e", e.target.left);
                  if (e.target.type === "rect") {
                    var text = new fabric.Text("Updated Sign", {
                      fill: "green",
                      left: e.target.left,
                      top: e.target.top,
                      selectable: false,
                    });
                    canvas.remove(e.target);
                    canvas.add(text);
                    canvas.requestRenderAll();
                  }
                }
              });
            });
          },
          scale: 1.5,
          pageImageCompression: "MEDIUM", // FAST, MEDIUM, SLOW(Helps to control the new PDF file size)
        });
        setPdfInstance(pdf);
      };
      fileReader.readAsArrayBuffer(pdfFile);
    } catch (error) {
      // console.log(error, "error-unVisibleObject")
    }


  };

  useEffect(() => {
    if (selectedFile) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        selectedFileref.current = e.target.result;
      };
      fileReader.readAsDataURL(selectedFile);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (isText) {
      setIsText(false);
    }
  }, [result]);

  const [signedDocData, setSignedDocData, signedDocDataref] = useState([]);

  useEffect(() => {
    getData();
    // completeData();
  }, []);

  async function getData() {
    let data = await getMethod({ apiUrl: apiService.getAllSignDocs });
    setSignedDocData(data);
    // console.log(signedDocDataref.current, "signedDocData");
  }

  // async function completeData() {
  //   let data = await getMethod({ apiUrl: apiService.completeDatas });
  //   setSignedDocData(data);
  // }

  const [showPdf, setShowPdf] = useState(false);

  function replaceJsonDataForPDF(canvasData, userData) {

    console.log({
      canvasData,
      userData
    })

    try {
      canvasData.pages.forEach((canvasPage, canvasPageIndex) => {
        canvasPage.objects.forEach(
          (canvasPageObjects, canvasPageObjectsIndex) => {

            userData.pages.forEach((userPages, userPagesIndex) => {
              userPages.objects.forEach(
                (userPageObjects, userPagesObjectsIndex) => {

                  if (userPageObjects.type == "group") {

                    console.log("check this boy", 
                    canvasData.pages[canvasPageIndex].objects[canvasPageObjectsIndex].top , userData.pages[userPagesIndex].objects[userPagesObjectsIndex].top)

                    console.log({ "canvasData.pages[canvasPageIndex].top": canvasData.pages[canvasPageIndex].objects[canvasPageObjectsIndex].top, "userData.pages[userPagesIndex].top": userData.pages[userPagesIndex].objects[userPagesObjectsIndex].top })
                    if (
                      // userPageObjects.top == canvasPageObjects.top 
                    Math.round(canvasData.pages[canvasPageIndex].objects[canvasPageObjectsIndex].top) - 15 == Math.round(userData.pages[userPagesIndex].objects[userPagesObjectsIndex].top)
                    ) {


                      // let filter= canvasData.pages[canvasPageIndex].objects.filter((item, index) =>  index != canvasPageObjectsIndex)



                      canvasData.pages[canvasPageIndex].objects[
                        canvasPageObjectsIndex
                      ] =
                        userData.pages[userPagesIndex].objects[
                        userPagesObjectsIndex
                        ];





                      // canvasData.pages[canvasPageIndex].objects = userData.pages[userPagesIndex].objects;


                      //   canvasData.pages[canvasPageIndex].objects[
                      //   canvasPageObjectsIndex
                      // ].push()
                    }
                  }

                }
              );
            });
          }
        );
      });



      // console.log({ canvasData}, "canvas data ")
      return canvasData;

    } catch (error) {
      // console.log(error, "error-unVisibleObject")
    }


  }

  const handleViewDocument = async (e) => {

    try {
      let index = e.target.getAttribute("data-index");

      setShowPdf(true);
      let data = signedDocDataref.current?.Data[index];
      // console.log(data, "data");

      let canvasDataJson = [];
      let signerDataJson = [];

      data?.Signer?.forEach((item) => {
        // console.log(item, "--dijfiso");

        signerDataJson.push(item);

        if (item?.Jsonfile?.length !== 0) {
          if (canvasDataJson.length === 0) {
            canvasDataJson = replaceJsonDataForPDF(
              JSON.parse(data.Jsondata),
              JSON.parse(item.Jsonfile)
            );
          } else {
            canvasDataJson = replaceJsonDataForPDF(
              canvasDataJson,
              JSON.parse(item.Jsonfile)
            );
          }
        } else {
          // console.log("item.Jsonfile", item.Jsonfile.length);
        }
      });

      setSignersdata(signerDataJson);
      // console.log(Signersdataref.current, "Signersdata");

      // console.log({ canvasDataJson });

      if (canvasDataJson.length === 0) {
        canvasDataJson = data.Jsondata;
      }
      let pdfDomElement = document.getElementById("pdf-container");

      if (pdfDomElement) {
        // console.log({ pdfDomElement });
        pdfDomElement.replaceChildren();
      }

      const pdfFile = "https://gateway.pinata.cloud/ipfs/" + data.File;
      var pdf = new PDFAnnotate("pdf-container", pdfFile, {
        onPageUpdated(page, oldData, newData) {
        },
        ready(canvases) {

          let inputElements =
            typeof canvasDataJson === "string"
              ? JSON.parse(canvasDataJson)
              : canvasDataJson;


          inputElements.pages.forEach((item, index) => {
            item.objects.forEach((obj) => {

              if (obj.type === "image") {
                fabric.Image.fromObject(obj, function (oImg) {
                  oImg.selectable = false;
                  canvases[index].add(oImg);
                });
              }

              if (obj.type === "line") {
                fabric.Line.fromObject(obj, function (oImg) {
                  oImg.selectable = false;

                  canvases[index].add(oImg);
                });
              }

              if (obj.type === "text") {
                fabric.Text.fromObject(obj, function (oImg) {
                  oImg.selectable = false;

                  canvases[index].add(oImg);
                });
              }

              if (obj.type === "group") {
                console.log("group", obj)
                fabric.Group.fromObject(obj, (oGroup) => {
                  oGroup.selectable = false;
                  canvases[index].add(oGroup);
                });
              }
            });
            canvases[index].requestRenderAll();
          });
        },
        scale: 1.5,
        pageImageCompression: "MEDIUM",
      });
      setpdf(pdf);
    } catch (error) {
      // console.log(error, "error-unVisibleObject")
    }

  };
  const handleViewDocuments = async (e) => {
    console.log(e, "e");

    try {
      setShowPdf(true);
      let data = signedDocDataref.current?.Data[e];

      console.log(data, "data");

      let canvasDataJson = [];
      let signerDataJson = [];

      data?.Signer?.forEach((item) => {

        signerDataJson.push(item);

        if (item?.Jsonfile?.length !== 0) {


          if (canvasDataJson.length === 0) {

            canvasDataJson = replaceJsonDataForPDF(
              JSON.parse(data.Jsondata),
              JSON.parse(item.Jsonfile)
            );
          } else {
            canvasDataJson = replaceJsonDataForPDF(
              canvasDataJson,
              JSON.parse(item.Jsonfile)
            );
          }
        } else {
        }
      });

      setSignersdata(signerDataJson);

      if (canvasDataJson.length === 0) {
        canvasDataJson = data.Jsondata;
      }
      let pdfDomElement = document.getElementById("pdf-container");

      if (pdfDomElement) {
        pdfDomElement.replaceChildren();
      }

      const pdfFile = "https://gateway.pinata.cloud/ipfs/" + data.File;
      var pdf = new PDFAnnotate("pdf-container", pdfFile, {
        onPageUpdated(page, oldData, newData) {
          console.log(page, oldData, newData, "miknikninininini");
        },
        ready(canvases) {

          let inputElements =
            typeof canvasDataJson === "string"
              ? JSON.parse(canvasDataJson)
              : canvasDataJson;


          inputElements.pages.forEach((item, index) => {
            item.objects.forEach((obj) => {
              if (obj.type === "image") {
                fabric.Image.fromObject(obj, function (oImg) {
                  oImg.selectable = false;

                  canvases[index].add(oImg);
                });
              }

              console.log("after objects", obj)
              if (obj.type === "line") {
                fabric.Line.fromObject(obj, function (oImg) {
                  oImg.selectable = false;

                  canvases[index].add(oImg);
                });
              }

              if (obj.type === "group") {
                fabric.Group.fromObject(obj, (oGroup) => {
                  oGroup.selectable = false;
                  canvases[index].add(oGroup);
                });
              }
            });
            canvases[index].requestRenderAll();
          });
        },
        scale: 1.5,
        pageImageCompression: "MEDIUM",
      });
      setpdf(pdf);
    } catch (error) {
    }

  };
  const Submitdocs = async () => {
    try {
      if (pdfref.current !== "") {
        const JsonFile = pdfInstance.serializePdf(async (string) => {
          var obj = {
            Signers: signersref.current,
            File: pdfref.current,
            Filename: pdfnameref.current,
            Jsondata: string,
          };
          var data = {
            apiUrl: apiService.docsigner,
            payload: obj,
          };

          var resp = await postMethod(data);

          setlawLoader(false);
          if (resp.Status == true) {
            toast.success(resp.Message);

            window.location.reload();


            // navigate("/uploadoc");
          } else {
            toast.success(resp.Message);
            // navigate("/uploadoc");
            window.location.reload();
          }
        });
      } else {
        // console.log("something went wrong try again later");
        setlawLoader(false);
      }
    } catch (error) {
      // console.log(error, "error");
      setlawLoader(false);
    }
  };
  const imageUpload = async () => {

    try {
      setlawLoader(true);
      const val = selectedFileRef.current;
      // console.log("val===", val);
      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      setpdfname(fileName);
      if (fileExtension != "pdf") {
        toast.error("File does not support. You must use .pdf only");
        setlawLoader(false);
        return false;
      } else if (fileSize > 10000000) {
        toast.error("Please upload a file smaller than 10 MB");
        setlawLoader(false);
        return false;
      } else {
        const data = new FormData();
        try {
          const apiKey = "0d5254112175e1060a22";
          const apiSecret =
            "8d57390dcfa2ad05856d3e6956e24fa6e3d2fbb987a6b12bf48e250d419f726e";
          const apiToken =
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJiN2VjMmM5MC1iZWRkLTQ0NzktYTc5YS04ZmZhZWQ3YjcxNDUiLCJlbWFpbCI6Im11Z2VzaGt1bWFyLmJlbGVhZkETHWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicGluX3BvbGljeSI6eyJyZWdpb25zIjpbeyJpZCI6IkZSQTEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX0seyJpZCI6Ik5ZQzEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX1dLCJ2ZXJzaW9uIjoxfSwibWZhX2VuYWJsZWQiOmZhbHNlLCJzdGF0dXMiOiJBQ1RJVkUifSwiYXV0aGVudGljYXRpb25UeXBlIjoic2NvcGVkS2V5Iiwic2NvcGVkS2V5S2V5IjoiMGQ1MjU0MTEyMTc1ZTEwNjBhMjIiLCJzY29wZWRLZXlTZWNyZXQiOiI4ZDU3MzkwZGNmYTJhZDA1ODU2ZDNlNjk1NmUyNGZhNmUzZDJmYmI5ODdhNmIxMmJmNDhlMjUwZDQxOWY3MjZlIiwiaWF0IjoxNjk2NTcxMDY1fQ.FGyVLVIu0j_rFhKBXCZwp2LvqgDhXWqvIlwX9R5kmlg";

          data.append("file", val);
          const resFile = await axios({
            method: "post",
            url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
            data: data,
            headers: {
              pinata_api_key: apiKey,
              pinata_secret_api_key: apiSecret,
              Authorization: `Bearer ${apiToken}`,
              "Content-Type": "multipart/form-data",
            },
          });
          // console.log("IPFS Hash:", resFile.data.IpfsHash);
          setpdf(resFile.data.IpfsHash);
          Submitdocs();
        } catch (error) {
          setlawLoader(false);
          console.error("Error uploading file to IPFS:", error);
        }
      }
    } catch (error) {
      // console.log(error, "error-unVisibleObject")
    }


  };
  const handleClick = (e) => {
    pdfInstance.setColor("white");
    pdfInstance.setBorderColor("black");
    pdfInstance.enableRectangle(e);
  };
  const setsearch = async (e) => {
    try {
      var obj = {
        key: e,
      };
      var data = {
        apiUrl: apiService.searchcontract,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.Status) {
        // console.log(resp);
        setSignedDocData(resp);
        // console.log(signedDocDataref.current, "signedDocData");
      } else {
        // console.log(resp.Message);
      }
    } catch (error) {
      // console.log(error, "error");
    }
  };
  const Deletecontract = async (e) => {
    // console.log(e, "Click");
    try {
      var obj = {
        id: e,
      };
      // console.log(apiService, "apiService");
      var data = {
        apiUrl: apiService.deletecontract,
        payload: obj,
      };
      var resp = await postMethod(data);

      if (resp.Status == true) {
        getData();
        toast.success(resp.Message);
      } else {
        // console.log(resp.Message);
      }
    } catch (error) { }
  };

  const handleDownloadPdf = () => {
    if (pdf) {
      pdf.savePdf();
      pdf.getFileAsArrayBuffer();

      console.log(pdf.pdfFileAsString, "file")
    }
  };


  return (
    <>
      <Header />
      <main class="mt-24">
        <section class="relative pt-10 pb-24">
          <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <img
              src={require("../img/gradient_light.jpg")}
              alt="gradient"
              class="h-full w-full"
            />
          </picture>
          <>
            <div class="px-6 xl:px-24">
              {!selectedFileRef.current ? (
                <>
                  <div class="flex flex-wrap justify-between">
                    <div class="flexn space-x-2 mb-2">
                      <h3 class="mt-0.5 text-l TextPrimary font-display">
                        Manage Contract
                      </h3>
                      <p>
                        View, sign, manage, and download your contracts here.
                      </p>
                    </div>
                    <div class="flex space-x-2 mb-2">
                      {showPdf && (
                        <button
                          onClick={handleDownloadPdf}
                          class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                        >
                          <span class="dark:text-white group-hover:text-white">
                            Download Pdf
                          </span>
                        </button>
                      )}
                      <label class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                        <span class="dark:text-white group-hover:text-white">
                          Create New Contract
                        </span>
                        <input
                          type="file"
                          class="custom-file-input d-none"
                          accept=".pdf"
                          id="customFile"
                          onChange={(e) => handleFile(e.target.files[0])}
                        />
                      </label>
                    </div>
                  </div>
                  <div class="lg:flex mt-6">
                    <div class="lg:w-1/5 mb-5 js-collections-sidebar lg:h-[calc(100vh_-_232px)] lg:overflow-auto lg:sticky lg:top-32 lg:mr-12 pr-4 scrollbar-custom divide-y divide-jacarta-100 dark:divide-jacarta-600 margin_pading_left-0 sidbcontact">
                      <div>
                        <h2 id="filters-collections-heading">
                          <button
                            class="accordion-button relative flex w-full items-center justify-between py-3 text-left font-display text-l text-jacarta-700 dark:text-white"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#filters-collections"
                            aria-expanded="true"
                            aria-controls="filters-collections"
                          >
                            <input
                              type="file"
                              class="custom-file-input d-none"
                              accept=".pdf"
                              id="customFile"
                              onChange={(e) => handleFile(e.target.files[0])}
                            />
                            <span>All Contracts</span>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-square-rounded-plus"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z"></path>
                              <path d="M15 12h-6"></path>
                              <path d="M12 9v6"></path>
                            </svg>
                          </button>
                        </h2>

                        {signedDocDataref.current &&
                          signedDocDataref.current?.Data?.map((item, i) => {
                          
                            let signstatus = true;
                            return (
                              <h2 id="filters-collections-heading">
                                <button class="accordion-button relative flex w-full items-center justify-between py-3 text-left font-display text-s text-jacarta-700 dark:text-white">
                                  <span
                                    data-index={i}
                                    onClick={handleViewDocument}
                                  >
                                    {" "}
                                    {`${i + 1} . ${item.Filename.split(".")[0]
                                      }`}
                                  </span>

                                  {signedDocDataref.current.Data[i].Signer.map(
                                    (item, i) => {
                                      if (item.Status !== 1) {
                                        signstatus = false;
                                      }

                                    }
                                  )}

                                  {signstatus == true ? (
                                    <>
                                      <img
                                        src={require("../img/correct.png")}
                                        width="25px"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={require("../img/wrong.png")}
                                        width="25px"
                                      />
                                    </>
                                  )}
                                </button>
                              </h2>
                            );
                          })}
                      </div>
                    </div>

                    {showPdf === false && (
                      <div class="lg:w-4/5 js-collections-content">
                        <div class="group relative flex max-w-xxl flex-col items-center justify-center rounded-lg border-2 border-dashed border-jacarta-100 bg-white py-20 px-5 text-center">
                          <div class="relative z-10 cursor-pointer">
                            {loader == false ? (
                              <>
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  class="mb-4 inline-block fill-jacarta-500 dark:fill-white"
                                >
                                  <path fill="none" d="M0 0h24v24H0z" />
                                  <path d="M16 13l6.964 4.062-2.973.85 2.125 3.681-1.732 1-2.125-3.68-2.223 2.15L16 13zm-2-7h2v2h5a1 1 0 0 1 1 1v4h-2v-3H10v10h4v2H9a1 1 0 0 1-1-1v-5H6v-2h2V9a1 1 0 0 1 1-1h5V6zM4 14v2H2v-2h2zm0-4v2H2v-2h2zm0-4v2H2V6h2zm0-4v2H2V2h2zm4 0v2H6V2h2zm4 0v2h-2V2h2zm4 0v2h-2V2h2z" />
                                </svg> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="30"
                                  height="30"
                                  class="mb-4 inline-block fill-jacarta-500" viewBox="0 0 24 24" fill="currentColor"><path d="M4 1V4H1V6H4V9H6V6H9V4H6V1H4ZM3 20.0066V11H5V19H13V14C13 13.45 13.45 13 14 13L19 12.999V5H11V3H20.0066C20.5552 3 21 3.45576 21 4.00247V15L15 20.996L4.00221 21C3.4487 21 3 20.5551 3 20.0066ZM18.171 14.999L15 15V18.169L18.171 14.999Z"></path></svg>
                                <p class="mx-auto max-w-xs text-xs dark:text-jacarta-300">
                                  Supported File type are PDF
                                </p>
                              </>
                            ) : (
                              <>
                                <img
                                  width="60"
                                  height="60"
                                  class="mb-4 inline-block fill-jacarta-500 dark:fill-white"
                                  src={require("../img/loadinggif.gif")}
                                ></img>
                                <p class="mx-auto max-w-xs text-xs dark:text-jacarta-300">
                                  Please wait uploading...
                                </p>
                              </>
                            )}
                          </div>
                          <div class="absolute inset-4 cursor-pointer rounded bg-jacarta-50 opacity-0"></div>
                          <input
                            type="file"
                            id="file-upload"
                            class="absolute inset-0 z-20 cursor-pointer opacity-0"
                            name="image"
                            accept=".pdf"
                            onChange={(e) => handleFile(e.target.files[0])}
                          />
                        </div>

                        <div class="tab-content mt-3">
                          <div
                            class="tab-pane fade show active"
                            id="view-grid"
                            role="tabpanel"
                            aria-labelledby="view-grid-tab"
                          >
                            <div class="">
                              <article>
                                <div class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                                  <section class="relative py-5">
                                    <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
                                      <img
                                        src="img/gradient_light.jpg"
                                        alt="gradient"
                                        class="h-full w-full"
                                      />
                                    </picture>
                                    <div class="container">
                                      <div class="mb-8 flex flex-wrap items-center justify-between">
                                        <div class="flex flex-wrap items-center">
                                          <h2 class="text-l TextPrimary bold">
                                            Uploaded Contracts
                                          </h2>
                                        </div>

                                        <div class="dropdown my-1 cursor-pointer search_dev Bglight">
                                          <input
                                            class=" rounded-lg border bg-none"
                                            type="text"
                                            placeholder="Contract Name"
                                            onChange={(e) =>
                                              setsearch(e.target.value)
                                            }
                                          ></input>
                                          <button
                                            style={{ cursor: "pointer" }}
                                            class="cursor-default rounded-full bg-accent-lighter py-3 px-8 bg-none font-semibold text-white transition-all search_butn"
                                          >
                                            <img
                                              src={require("../img//searcc.png")}
                                              class="icon_width searchfilter"
                                              alt="Xhibiter | NFT Marketplace"
                                            />
                                            
                                          </button>
                                        </div>
                                      </div>
                                      <div className="uploadtbl">
                                        {/* <Table hover responsive>
                                          <thead class="mb-3 border-bottom mx-auto max-w-m text-m dark:text-jacarta-300 ">
                                            <tr>
                                              <th>#</th>
                                              <th>Contract Name</th>
                                              <th>Status</th>
                                              <th class="text-right mb-3">
                                                Action
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody
                                            class="max-w-xs text-xs white:text-jacarta-300"
                                            style={{ color: "#ffffff" }}
                                          >
                                            {signedDocData &&
                                              signedDocData?.Data?.map(
                                                (item, i) => {
                                                  // console.log(
                                                    signedDocData,
                                                    "signedDocData"
                                                  );
                                                  let signstatus = true;
                                                  return (
                                                    <tr key={i}>
                                                      <th scope="row">
                                                        {" "}
                                                        {i + 1}{" "}
                                                      </th>
                                                      <th>{item.Filename}</th>

                                                      {signedDocData.Data[
                                                        i
                                                      ].Signer.map(
                                                        (item, i) => {
                                                          if (
                                                            item.Status !== 1
                                                          ) {
                                                            signstatus = false;
                                                          }
                                                        }
                                                      )}

                                                      <th scope="row">
                                                        {" "}
                                                        {signstatus == true ? (
                                                          <>
                                                            <p className="text-success">
                                                              Completed
                                                            </p>
                                                          </>
                                                        ) : (
                                                          <>
                                                            {" "}
                                                            <p className="text-danger">
                                                              Pending
                                                            </p>{" "}
                                                          </>
                                                        )}{" "}
                                                      </th>
                                                      <td>
                                                        <div class="flex justify-end">
                                                          <button
                                                            class="dropdown-toggle group mr-2 mb-1 mt-1 flex h-9 items-center rounded-lg border border-jacarta-100 bg-white px-4 font-display text-sm font-semibold text-jacarta-700 transition-colors hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:hover:bg-accent"
                                                            id="categoriesFilter"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                            data-index={i}
                                                            onClick={
                                                              handleViewDocument
                                                            }
                                                          >
                                                            <span>View</span>
                                                          </button>

                                                          <button
                                                            class="dropdown-toggle group mb-1 mt-1 flex h-9 items-center rounded-lg border border-jacarta-100 bg-white px-4 font-display text-sm font-semibold text-jacarta-700 transition-colors hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:hover:bg-accent"
                                                            id="categoriesFilter"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                          >
                                                            <span>Delete</span>
                                                          </button>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        </Table> */}

                                        <Table hover responsive>
                                          <thead className="mb-3 border-bottom mx-auto max-w-m text-m TextPrimary ">
                                            <tr>
                                              <th>#</th>
                                              <th>Contract Name</th>
                                              <th>Status</th>
                                              <th className="text-right mb-3">
                                                Action
                                              </th>
                                            </tr>
                                          </thead>
                                          {signedDocDataref.current?.Data &&
                                            signedDocDataref.current.Data.length >
                                            0 ? (
                                            <tbody
                                              className="text-s"
                                              style={{ color: "rgb(128 128 128)" }}
                                            >
                                              {signedDocDataref.current.Data.map(
                                                (item, i) => {
                                                  // console.log(
                                                  {/* signedDocDataref.current,
                                                    "signedDocData"
                                                  ); */}
                                                  let signstatus = true;
                                                  return (
                                                    <tr key={i}>
                                                      <th scope="row">
                                                        {i + 1}
                                                      </th>
                                                      <th>{item.Filename}</th>
                                                      {item.Signer.map(
                                                        (signerItem, j) => {
                                                          if (
                                                            signerItem.Status !==
                                                            1
                                                          ) {
                                                            signstatus = false;
                                                          }
                                                        }
                                                      )}
                                                      <th scope="row">
                                                        {signstatus === true ? (
                                                          <p className="text-success">
                                                            Completed
                                                          </p>
                                                        ) : (
                                                          <p className="text-danger">
                                                            Pending
                                                          </p>
                                                        )}
                                                      </th>
                                                      <td>
                                                        <div className="flex justify-end">
                                                          <button
                                                            className="dropdown-toggle group mr-2 mb-1 mt-1 flex h-9 items-center rounded-lg border border-jacarta-100 bg-white px-4 font-display text-sm font-semibold transition-colors hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:hover:bg-accent"
                                                            onClick={() =>
                                                              handleViewDocuments(
                                                                i
                                                              )
                                                            }
                                                          >
                                                            <span>View</span>
                                                          </button>
                                                          <button
                                                            className="dropdown-toggle group mb-1 mt-1 flex h-9"
                                                            onClick={() =>
                                                              Deletecontract(
                                                                item._id
                                                              )
                                                            }
                                                          >
                                                            <span>
                                                            
                                  <svg xmlns="http://www.w3.org/2000/svg"  width="20"
                                  height="20"
                                  class="my-2 inline-block" viewBox="0 0 24 24" fill="red"><path d="M17 4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7V2H17V4ZM9 9V17H11V9H9ZM13 9V17H15V9H13Z"></path></svg>
                                                            </span>
                                                          </button>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </tbody>
                                          ) : (
                                            <tbody>
                                              <tr>
                                                <td
                                                  colSpan="4"
                                                  class="text-center"
                                                >
                                                  No data available
                                                </td>
                                              </tr>
                                            </tbody>
                                          )}
                                        </Table>
                                      </div>
                                    </div>
                                  </section>
                                </div>
                              </article>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {showPdf && (
                      <div class="lg:w-4/5 js-collections-content justify-center">
                        <div id="pdf-container"> </div>

                        <div class="tab-content mt-3">
                          <div
                            class="tab-pane w_88 fade show active"
                            id="view-grid"
                            role="tabpanel"
                            aria-labelledby="view-grid-tab"
                          >
                            <div class="">
                              <article>
                                <div class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                                  <section class="relative py-5">
                                    <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
                                      <img
                                        src="img/gradient_light.jpg"
                                        alt="gradient"
                                        class="h-full w-full"
                                      />
                                    </picture>
                                    <div class="container">
                                      <div className="uploadtbl">
                                        <Table hover responsive>
                                          <thead class="mb-3 border-bottom max-w-m text-m dark:text-jacarta-300">
                                            <tr>
                                              <th>#</th>
                                              <th>Signer Name</th>
                                              <th>Signer Email</th>
                                              <th>Status</th>
                                            </tr>
                                          </thead>
                                          <tbody
                                            class="max-w-xs text-xs white:text-jacarta-300"
                                            style={{ color: "#182753" }}
                                          >
                                            {Signersdataref.current.length >
                                              0 ? (
                                              Signersdataref.current.map(
                                                (item, i) => {
                                                  return (
                                                    <tr key={i} class="m-3">
                                                      <th scope="row">
                                                        {" "}
                                                        {i + 1}{" "}
                                                      </th>
                                                      <th>{item.Name}</th>
                                                      <th>{item.Email}</th>
                                                      <th>
                                                        {item.Status == 1 ? (
                                                          <>
                                                            <img
                                                              className="d-block mx-auto my-3"
                                                              src={require("../img/correct.png")}
                                                              width="5%"
                                                            />
                                                          </>
                                                        ) : (
                                                          <>
                                                            <img
                                                              className="d-block mx-auto my-3"
                                                              src={require("../img/wrong.png")}
                                                              width="5%"
                                                            />
                                                          </>
                                                        )}
                                                      </th>
                                                    </tr>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <tr>
                                                <th>-</th>
                                                <th>-</th>
                                                <th>-</th>
                                                <th>-</th>
                                              </tr>
                                            )}
                                          </tbody>
                                        </Table>
                                      </div>
                                    </div>
                                  </section>
                                </div>
                              </article>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : nextap == true ? (
                <>
                  <div class="lg:flex justify-center">
                    <div class="lg:w-3/5 js-collections-content">
                      <div class="mb-4 flex flex-wrap justify-between">
                        <h1 class="pt-1 mb-2 font-display text-xl font-medium text-jacarta-700 dark:text-white">
                          Add Signature
                        </h1>
                        <h2 class="pt-1 mb-2 font-display text-xl font-medium text-jacarta-700 dark:text-white">
                          {/* {lawLoader == false ? (
                            signersref.current.length > 0 ? (
                              signersref.current.map((item, i) => {
                                return (
                                  <select
                                    name="category"
                                    
                                    onClick={() => handleClick(item.email)}
                                    className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                                    required
                                  >
                                    <option value="">Select an Option</option>
                                    <option value={item.email}>
                                      {item.email}
                                    </option>
                                  </select>
                                );
                              })
                            ) : (
                              <></>
                            )
                          ) : (
                            ""
                          )} */}
                          {lawLoader === false &&
                            signersref.current.length > 0 && (
                              <select
                                name="category"
                                className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                                required
                                onChange={(event) =>
                                  handleClick(event.target.value)
                                }
                              >
                                <option value="">Select an Option</option>
                                {[
                                  ...new Set(
                                    signersref.current.map((item) => item.email)
                                  ),
                                ].map((uniqueEmail, i) => (
                                  <option key={i} value={uniqueEmail}>
                                    {uniqueEmail}
                                  </option>
                                ))}
                              </select>
                            )}
                        </h2>
                      </div>
                      <div class="tab-content">
                        <div
                          class="tab-pane fade show active"
                          id="view-grid"
                          role="tabpanel"
                          aria-labelledby="view-grid-tab"
                        >
                          <div class="">
                            {lawLoader == true ? (
                              <div class="grid grid-cols-1 mt-20 gap-3 md:grid-cols-1 md:gap-[1.875rem] lg:grid-cols-1">
                                <img
                                  width="100"
                                  height="100"
                                  class="mb-4 d-block fill-jacarta-500 dark:fill-white mx-auto"
                                  src={require("../img/loadinggif.gif")}
                                ></img>
                              </div>
                            ) : (
                              <article>
                                <div class="rounded-2.5xl mx-5 border justify-center border-jacarta-100 bg-white p-[1.1875rem] transition-shadow Bglight scrollers">
                                  <div id="pdf-container"> </div>
                                </div>
                                <div class="flex flex-wrap justify-end" onClick={() =>
                                  imageUpload(selectedFileRef.current)
                                }>
                                  <div class="flex space-x-2 my-4 mr-4">
                                    <label class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                                      <span

                                        class="dark:text-white group-hover:text-white"
                                      >
                                        Send File{" "}
                                        <i class="fa fa-file-export"></i>
                                      </span>
                                      <input
                                        type="button"
                                        class="custom-file-input d-none"
                                      />
                                    </label>
                                  </div>
                                </div>
                              </article>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div class="lg:flex mt-6 justify-center">
                    <div class="lg:w-4/5 js-collections-content">
                      <div class="flex flex-wrap justify-between">
                        <div class="flexn space-x-2 mb-2">
                          <h2 class="pt-3 mb-2 font-display text-xl font-medium text-jacarta-700 dark:text-white">
                            Add Signer
                          </h2>
                          <p>
                            View, sign, manage, and download your document here.
                          </p>
                        </div>
                        <div class="flex space-x-2 mb-2">
                          <label class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                            <span class="dark:text-white group-hover:text-white">
                              Re - upload
                            </span>
                            <input
                              type="file"
                              class="custom-file-input d-none"
                              accept=".pdf"
                              id="customFile"
                              onChange={(e) => handleFile(e.target.files[0])}
                            />
                          </label>

                          <label class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                            <span class="dark:text-white group-hover:text-white">
                              <i class="fa fa-pen-nib"></i> Add Signer
                            </span>
                            <input
                              type="button"
                              class="custom-file-input d-none"
                              accept=".pdf"
                              id="customFile"
                              onClick={toggleModal}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="lg:flex mt-6 justify-center">
                    <div class="lg:w-4/5 js-collections-content">
                      <div class="mb-8 pb-px"></div>
                      <div class="tab-content">
                        <div
                          class="tab-pane fade show active"
                          id="view-grid"
                          role="tabpanel"
                          aria-labelledby="view-grid-tab"
                        >
                          <div class="">
                            <article>
                              <div class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                                <div className="uploadtbl">
                                  <Table bordered hover responsive>
                                    <thead>
                                      <tr
                                        className="mb-3"
                                        style={{ color: "#182753" }}
                                      >
                                        <th>#</th>
                                        <th>Signer Name</th>
                                        <th>Signer Email</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {signersref.current.length > 0 ? (
                                        signersref.current.map((item, i) => {
                                          return (
                                            <tr key={i}>
                                              <td>{i + 1}</td>
                                              <td>{item.fullName}</td>
                                              <td>{item.email}</td>
                                              <td>
                                                <i
                                                  className="fa fa-trash text-danger"
                                                  onClick={() =>
                                                    handleDeleteSigner(i)
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                ></i>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <th>-</th>
                                          <td>-</td>
                                          <td>-</td>
                                          <td>-</td>
                                          <td>-</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>

                              <div class="flex mt-10 flex-wrap justify-center">
                                <div class="flex space-x-2 mb-2">
                                  <label class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                                    <span class="dark:text-white group-hover:text-white">
                                      <i class="fa fa-light fa-backward"></i>{" "}
                                      Back
                                    </span>
                                    <a href="/uploadoc">
                                      <input
                                        type="button"
                                        class="custom-file-input d-none"
                                      />
                                    </a>
                                  </label>
                                  <label class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                                    <span class="dark:text-white group-hover:text-white">
                                      Next{" "}
                                      <i class="fa fa-light fa-forward"></i>
                                    </span>
                                    <input
                                      type="button"
                                      class="custom-file-input d-none"
                                      onClick={Nexttap}
                                    />
                                  </label>
                                </div>
                              </div>
                            </article>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <Modal
              isOpen={modalOpen}
              className="Signclass mx-auto d-block"
              toggle={toggleModal}
            >
              <div class="text-center modal-header">
                <h5 class="modal-title">
                  <h2 class="mb-0 font-display text-xl text-jacarta-700 dark:text-white lg:text-3xl xl:text-3xl">
                    <span class="animate-gradient">Add Signer</span>
                  </h2>
                </h5>
              </div>
              <ModalBody>
                <div className="mx-auto  md:flex wrap_s">
                  <div className="mb-1  md:pr-8 w-100_n">
                    <div class="mb-6">
                      <label
                        for="item-name"
                        class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleInputChange}
                        class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 Bglight"
                        placeholder="Full Name"
                        required
                      />
                      {FullNameErrref.current == true ? (
                        <p style={{ color: "red" }}>{validateErr.fullName}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="mb-1  md:pr-8 w-100_n">
                    <div class="mb-6">
                      <label
                        for="item-email"
                        class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 Bglight"
                        placeholder=" Signer Email "
                        required
                      />
                      {EmailErrref.current == true ? (
                        <p style={{ color: "red" }}>{validateErr.email}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                </div>
              </ModalBody>
              <ModalFooter className="mx-auto d-blck">
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>{" "}
                <Button color="primary" onClick={handleAddSigner}>
                  Add
                </Button>
              </ModalFooter>
            </Modal>
          </>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default TemplateMoreFiter;



// <div className="mb-1 md:pr-8 w-100_n">
//              <div className="mb-6">
//                <label
//                  htmlFor="item-email"
//                  className="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
//                >
//                  Select Option
//                </label>
//                <select
//                  name="category"
//                  value={formData.category}
//                  onChange={handleInputChange}
//                  className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
//                  required
//                >
//                  <option value="" disabled>
//                    Select an option
//                  </option>
//                  <option value="Priority-1">Priority - 1</option>
//                  <option value="Priority-2">Priority - 2</option>
//                  <option value="Priority-3">Priority - 3</option>
//                </select>
//                {categoryErrref.current == true ? (
//                  <p style={{ color: "red" }}>Country is required</p>
//                ) : (
//                  ""
//                )}
//              </div>
//            </div>