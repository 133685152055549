import React, { useState, useEffect } from 'react'
import apiService from '../core/service/v1'
import { postMethod } from '../core/service/common.api'
import { toast } from 'react-toastify'
import { ToastBody } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import validator from 'validator'

function ForgotPassword() {

    const navigate = useNavigate()
    const [resetpassword, setresetpassword] = useState(false)
    const email = {
        Email: ""
    }
    const [EmailValue, setEmailValue] = useState(email)
    const [EmailValueErr, setEmailValueErr] = useState(false)
    const [userEmail, setuserEmail] = useState("email")
    const getEmail = (e) => {
        const { name, value } = e.target;
        const formData = { ...EmailValue, ...{ [name]: value } };
        setEmailValue(formData);
    }

    useEffect(() => {
        var url = window.location.href.split("?")[1]
        // console.log(url, "=-=--=-=-")
        if (url == "" || url == undefined) {
            setresetpassword(false)
        } else {
            setuserEmail(url)
            setresetpassword(true)
        }
    }, [])


    const formSubmit = async (e) => {
        e.preventDefault();
        if (EmailValue.Email != "") {
            setEmailValueErr(false)
            var data = {
                apiUrl: apiService.ForgotPassword,
                payload: EmailValue,
            };
            var resp = await postMethod(data);
            if (resp) {
                toast(resp.Message)
                setEmailValue("")
            }
        } else {
            setEmailValueErr(true)
        }
    }

    const passWordValue = {
        NewPassword: "",
        Conpassword: ""
    }
    const [passwordValue, setpasswordValue] = useState(passWordValue)
    const [NewPasswordErr, setNewPasswordErr] = useState(false)
    const [conasswordErr, setconasswordErr] = useState(false)
    const [ValidationErr, setValidationErr] = useState(false)
    const getpasswordvalue = (e) => {
        const { name, value } = e.target;
        const passWordData = { ...passwordValue, ...{ [name]: value } };
        setpasswordValue(passWordData);
        // console.log(passwordValue, '==')
    }

    const resetpasswordSubmit = async (e) => {
        e.preventDefault();
        validation();
        if (passwordValue.Conpassword != "" && passwordValue.NewPassword != "") {
            var obj = {
                NewPassword: passwordValue.NewPassword,
                Conpassword: passwordValue.Conpassword,
                Email: userEmail
            }
            var data = {
                apiUrl: apiService.resetpassword,
                payload: obj,
            };
            var resp = await postMethod(data);
            if (resp.Status == true) {
                toast(resp.Message)
                navigate("/login")
            } else {
                toast(resp.Message)
            }
        } else {
        }
    }
    const validation = () => {
        const error = {};
        if (passwordValue.NewPassword == "") {
            error.NewPassword = "New Password is required"
            setNewPasswordErr(true)
        } else if (
            validator.isStrongPassword(passwordValue.NewPassword, {
                minLength: 8,
                minLowercase: 1,
                minUppercase: 1,
                minNumbers: 1,
                minSymbols: 1,
            })
        ) {
            setNewPasswordErr(false);
        } else {
            error.NewPassword = "Enter strong password";
            setNewPasswordErr(true);
        }
        if (passwordValue.Conpassword == "") {
            error.Conpassword = "Confirm Password is required"
            setconasswordErr(true)
        }
        if (passwordValue.Conpassword != passwordValue.NewPassword) {
            error.Conpassword = "New password and Confirm password is not match"
            setconasswordErr(true)
        }
        setValidationErr(error)

    }


    return (
        <section class="relative h-screen">
            <div class="lg:flex lg:h-full">
                <div class="relative text-center lg:w-1/2">
                    <img
                        src={require("../img/pexels.jpg")}
                        alt="login"
                        class="absolute h-full w-full object-cover"
                    />
                    <a href="/" class="relative inline-block py-36">
                        <img
                            src={require("../img/darklogo.png")}
                            class="inline-block img_logo_width"
                            alt="Xhibiter | NFT JustiChain"
                        />
                    </a>
                </div>

                <div class="relative flex items-center justify-center p-[10%] lg:w-1/2 login_for_pading">
                    <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
                        <img
                            src={require("../img/gradient_light.jpg")}
                            alt="gradient"
                            class="h-full w-full"
                        />
                    </picture>
                    <div class="w-full max-w-[25.625rem] text-center max_width_100">
                        {resetpassword == false ?
                            <div class="tab-content">
                                <div
                                    class="tab-pane fade show active"
                                    id="wallet-connect"
                                    role="tabpanel"
                                    aria-labelledby="wallet-connect-tab"
                                >
                                    <h1 class="mb-6 font-display text-4xl text-jacarta-700 dark:text-white">
                                        Forgot Password
                                    </h1>

                                    <form className="with_form_section">
                                        <div class="mb-6">
                                            <label
                                                for="item-name"
                                                class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                                            >
                                                Email<span class="text-red">*</span>
                                            </label>
                                            <input
                                                type="email"
                                                id="item-name"
                                                name="Email"
                                                onChange={getEmail}
                                                class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                                                placeholder="Email"
                                                required
                                            />
                                            {EmailValueErr == true ? <p style={{ color: "red" }}>Email is required</p> : ""}
                                        </div>
                                        <div className="form_betwine">
                                            <button class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                                                <button type="submit" onClick={formSubmit} >
                                                    Submit
                                                </button>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            :
                            <div class="tab-content">
                                <div
                                    class="tab-pane fade show active"
                                    id="wallet-connect"
                                    role="tabpanel"
                                    aria-labelledby="wallet-connect-tab"
                                >
                                    <h2 class="mb-6 font-display text-4xl text-jacarta-700 dark:text-white">
                                        Reset Password
                                    </h2>

                                    <form className="with_form_section">
                                        <div class="mb-6">
                                            <label
                                                for="item-name"
                                                class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                                            >
                                                New Password
                                            </label>
                                            <input
                                                type="password"
                                                id="item-name"
                                                name="NewPassword"
                                                onChange={getpasswordvalue}
                                                class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                                                placeholder="New Password"
                                                required
                                            />
                                            {NewPasswordErr == true ? <p style={{ color: "red" }}>{ValidationErr.NewPassword}</p> : ""}
                                        </div>
                                        <div class="mb-6">
                                            <label
                                                for="item-name"
                                                class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                                            >
                                                Confirm Password
                                            </label>
                                            <input
                                                type="password"
                                                id="item-name"
                                                name="Conpassword"
                                                onChange={getpasswordvalue}
                                                class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                                                placeholder="Confirm Password"
                                                required
                                            />
                                            {conasswordErr == true ? <p style={{ color: "red" }}>{ValidationErr.Conpassword}</p> : ""}
                                        </div>
                                        <div className="form_betwine">
                                            <button class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                                                <button type="submit" onClick={resetpasswordSubmit} >
                                                    Submit
                                                </button>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ForgotPassword