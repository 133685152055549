import React, { useRef, useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import Header from "./Header";
import Footer from "./Footer";
import DragAndDrop from "../common/DragAndDrop";
import { Editor } from "@tinymce/tinymce-react";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Report } from "notiflix/build/notiflix-report-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import { eventsdata, getTxnStatus } from "../utils/contract";
import { toast } from "react-toastify";
// var mammoth = require("mammoth");
import mammoth from "mammoth/mammoth.browser";
import { formats } from "../utils/fileFormats";
import Country from "../components/Country";
import { Table } from "reactstrap";
import Axios from "axios";
import html2canvas from "html2canvas";
import { getWeb3, keys } from "../utils/getWeb3";
import { NFTStorage, File, Blob } from "nft.storage";
import LawContract from "../utils/lawblocks.json";
import LawTokenContract from "../utils/lawblocks.json";
import useStateRef from "react-usestateref";
import {
  LAWBLOCKS_CONTRACT,
  LAWBLOCKS_TOKEN_CONTRACT,
  FILE_URL,
  NETWORK_NAME,
} from "../utils/constant";
import { useLocation } from "react-router-dom";
import truncateEthAddress from "truncate-eth-address";
import { env } from "../core/service/envconfig";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import logo from "../img/logo_pdf.png";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Link,
} from "@react-pdf/renderer";

const ethers = require("ethers");

function ContractUpload() {
  const upload = useRef(null);
  var web3 = getWeb3();
  var address = "";

  function toXdc(data) {
    // console.log(data, "datadatadatadata===datadatadatadatadata");
    return "xdc" + String(data).slice(2);
  }

  const NFT_STORAGE_TOKEN =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDVBMkM5YTUxQWE2NmUzMTk2YjJjQTdBODg2NjFkMTUwODVjMDczODAiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3ODUyNTQxMzIxOSwibmFtZSI6ImhvcHB5aGFyZXMifQ.CYTqIjhUqVUlViBTjN-mmQoDvBugYwghGIpTc7C6DoY";
  const client = new NFTStorage({ token: NFT_STORAGE_TOKEN });

  var mammoth = require("mammoth");
  const StorxStorage = require("storx-storage-sdk");
  const storxStorageFunctions = StorxStorage();

  const [uploadDetails, setUploadDetails, uploadDetailsref] = useStateRef({
    shortContent: "",
    uploadStatus: "",
    viewStatus: "",
    imagePreview: false,
    fileName: "",
    fileext: "",
    text: "",
    currentPage: "draft",
  });

  var location = useLocation();

  useEffect(() => {
    getData();
    balancedata();
  }, []);

  useEffect(() => {
    if (location.state !== null && Object.keys(location.state).length > 0) {
      // console.log(location, "location");
      const reader = new FileReader();
      setUploadDetails({
        ...uploadDetails,
        shortContent: location.state.downloadedData.shortContent,
        uploadStatus: true,
        viewStatus: true,
        fileName: location.state.downloadedData.fileName,
        fileext: location.state.downloadedData.fileext,
        text: location.state.downloadedData.text,
      });
      // console.log(uploadDetailsref.current, "location");
    }
  }, [location.state]);

  const [draftList, setDraftList, draftListref] = useStateRef([]);
  const [documentList, setDocumentList, documentListref] = useStateRef([]);
  const [shareList, setShareList, shareListref] = useStateRef([]);
  const [modelShow, setmodelShow, modelShowref] = useStateRef("");
  const [modelShowPdf, setmodelShowPdf, modelShowPdfref] = useStateRef("");
  const [bloburls, setbloburls, bloburlsref] = useStateRef("");
  const [signerView, setsignerView, signerViewref] = useStateRef("");
  const [lawContract, setlawContract, lawContractref] = useStateRef("");
  const [lawTokenContract, setlawTokenContract, lawTokenContractref] =
    useStateRef("");
  const [addressdata, setaddressdata, addressdataref] = useStateRef("");
  const [country, setcountry, countryref] = useStateRef("");
  const [countryValidation, setcountryValidation, countryValidationref] =
    useStateRef("");
  const [Balances, setBalances, Balancesref] = useStateRef("");
  const [uploadloader, setuploadloader, uploadloaderref] = useStateRef(false);

  function onViewSigner(data) {
    // console.log(data, "=-=-=-=-=-data");
    setmodelShow({ modelShow: true });
    console.log(data, "data.signerList");
    setsignerView(data);
  }

  function onViewPdf(data) {
    // console.log(data, "=-=-=-=-=-data");
    // setbloburls(data);
    setmodelShowPdf(true);
  }

  function onCloseModal() {
    setmodelShow(false);
  }
  function onCloseModalPdf(data) {
    setmodelShowPdf(false);
  }

  async function onpreview() {
    if (uploadDetails.imagePreview == true) {
      setUploadDetails({
        ...uploadDetails,
        imagePreview: false,
      });
    } else {
      setUploadDetails({
        ...uploadDetails,
        imagePreview: true,
      });
    }
  }

  async function onDraftUpload() {
    try {
      if (countryref.current != "") {
        setcountryValidation(false);

        Loading.standard("Uploading Document", {
          messageColor: "#0d102d",
          svgColor: "#0d102d",
          svgSize: "100px",
          backgroundColor: "#eff7ff",
        });

        // console.log(countryref.current, "countryref.current");

        const Data = {
          originalDraft: uploadDetails.text,
          draftName: uploadDetails.fileName,
          country: countryref.current.country,
        };
        Axios.post(`${env.apiHost}advocate/advocate/draft`, Data)
          .then(function (response) {
            Loading.remove();
            Report.success("Document Uploaded Successfully", "", "View", () => {
              getData();
              setUploadDetails({
                ...uploadDetails,
                shortContent: "",
                uploadStatus: false,
                viewStatus: false,
                fileName: "",
                country: "",
              });
            });
            setUploadDetails({
              ...uploadDetails,

              shortContent: "",
              uploadStatus: false,
              viewStatus: false,
              fileName: "",
              country: "",
            });
          })
          .catch(function (error) {
            Loading.remove();
          });
      } else {
        setcountryValidation(true);

        Loading.remove();
      }
    } catch (e) {
      Notify.failure(`Error Occured Try Again`);
    }
  }

  async function getData() {
    Loading.standard("Loading...", {
      messageColor: "#0d102d",
      svgColor: "#0d102d",
      svgSize: "100px",
      backgroundColor: "#eff7ff",
    });
    try {
      Axios.get(`${env.apiHost}advocate/advocate/draft`)
        .then((result) => {
          setDraftList(result.data);
          // console.log(draftListref.current, "draftList");
          // console.log(uploadDetailsref.current, "location");
        })
        .catch((err) => { });
      Axios.get(`${env.apiHost}advocate/advocate/document`)
        .then((result) => {
          setDocumentList(result.data);
          // console.log(documentListref.current, "documentList");
          // console.log(uploadDetailsref.current, "location");

          Loading.remove();
        })
        .catch((err) => { });

      Axios.get(`${env.apiHost}advocate/advocate/sharelist`)
        .then((result) => {
          setShareList(result.data);
          // console.log(shareListref.current, "shareList");
          // console.log(uploadDetailsref.current, "location");
        })
        .catch((err) => {
          // this.setState({
          //   isLoading: true,
          // });
        });
    } catch (error) {
      // console.log(error, "error-unVisibleObject")
    }
  }

  async function onSubmitUpload() {
    try {
      if (countryref.current != "") {
        setcountryValidation(false);

        if (!web3) {
          Notify.failure(`Connection Error`);
          return null;
        }

        var fileBuffer;
        // console.log(uploadDetails, "uploadDetails");
        if (uploadDetails.fileext == "document") {
          fileBuffer = new Blob([uploadDetails.shortContent], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
        } else {
          fileBuffer = uploadDetails.shortContent;
        }
        try {
          setuploadloader(true);

          // console.log(Balancesref.current, "=-=-=-=-");
          var getGas = Number(await web3.eth.getGasPrice());
          var fee = getGas / 1000000000;
          var fee1 = fee / 1000000000;
          var fee2 = fee1 * 2100000;
          var limit = fee1 + 0.00007882225;
          if (Balancesref.current > limit) {
            // console.log(Balancesref.current, "=-==-blance", limit);

            // Loading.standard("Uploading Document to IPFS", {
            //   messageColor: "#fff",
            //   svgColor: "#fff",
            //   svgSize: "100px",
            //   backgroundColor: "#eff7ff",
            // });

            var ipfsHash;

            // console.log(env.REACT_APP_STORAGE_TYPE, "ipfsHash");
            if (env.REACT_APP_STORAGE_TYPE === "ipfs") {
              let added = await client.storeBlob(fileBuffer);
              ipfsHash = `${added}`;
              // console.log(ipfsHash, "ipfsHash");
            } else if (env.REACT_APP_STORAGE_TYPE === "storx") {
              let form = new FormData();
              form.append("xfile", fileBuffer, uploadDetails.fileName);
              let added = await storxStorageFunctions.uploadFileToFolder({
                data: form, // file object
                folderId: env.REACT_APP_STORX_FOLDER_ID, // string
                mnemonic: env.REACT_APP_STORX_MNEMONIC,
                apiAccessKey: env.REACT_APP_STORX_ACCESS_KEY,
              });
              var addedresult = added.result;
              Loading.remove();

              ipfsHash = `${addedresult.fileId}`;
              // console.log(ipfsHash, "ipfsHash2");
            } else {
              Loading.remove();
              // console.log();
            }

            // Loading.standard("Uploading to Blockchain", {
            //   messageColor: "#fff",
            //   svgColor: "#fff",
            //   svgSize: "100px",
            //   backgroundColor: "#eff7ff",
            // });
            let signature = web3.eth.accounts.sign(ipfsHash, keys());
            // console.log(ethers, "ethersethers");
            const ethersProvider = new ethers.JsonRpcProvider(
              "https://erpc.xinfin.network"
            );
            // console.log(LAWBLOCKS_CONTRACT, "LAWBLOCKS_CONTRACT--------- showed");
            const ethersWallet = new ethers.Wallet(keys(), ethersProvider);
            const lawEthersInstance = new ethers.Contract(
              LAWBLOCKS_CONTRACT,
              LawContract,
              ethersWallet
            );

            var txConfig = {
              gasLimit: "2100000",
            };

            const uploadHash = await lawEthersInstance.uploadFile(
              ipfsHash.toString(),
              uploadDetails.fileName.toString(),
              signature.toString(),
              txConfig
            );
            await uploadHash.wait(1).then(async function () {
              // Loading.standard(
              //   "Please wait while Confirming Transaction in Blockchain",
              //   {
              //     messageColor: "#fff",
              //     svgColor: "#fff",
              //     svgSize: "100px",
              //     backgroundColor: "#eff7ff",
              //   }
              // );
              const [txhash, status, blockNumber] = await getTxnStatus(
                uploadHash.hash
              );
              // console.log(txhash, status, blockNumber);
              const documentNo = await lawEthersInstance._contractIds();
              var start = setInterval(callTransaction, 3000);
              async function callTransaction() {
                if (!status) {
                  Report.failure(
                    "Error Occured on Blockchain Transaction",
                    `Try Again!!`,
                    "View",
                    () => {
                      window.open(
                        `https://explorer.xinfin.network/tx/${uploadHash.hash}`
                      );
                    }
                  );
                  Loading.remove();
                  return null;
                } else {
                  const walletadd = toXdc(addressdataref.current);
                  // console.log(walletadd, "walletadd");

                  const Data = {
                    fileName: uploadDetails.fileName,
                    txnHash: uploadHash.hash,
                    ipfsHash: ipfsHash,
                    documentNo: Number(documentNo) - 1,
                    walletAddress: walletadd,
                    storageType: env.REACT_APP_STORAGE_TYPE,
                  };
                  // console.log(Data, "======");
                  Axios.post(`${env.apiHost}advocate/advocate/document`, Data)
                    .then(function (response) {
                      setuploadloader(false);
                      Loading.remove();
                      Report.success(
                        "Document Uploaded Successfully",
                        "",
                        "View",
                        () => {
                          window.open(
                            `https://explorer.xinfin.network/tx/${uploadHash.hash}`
                          );
                          window.location.reload();
                        }
                      );
                      clearInterval(start);
                    })
                    .catch(function (error) {
                      setuploadloader(false);
                      Loading.remove();
                      Notify.failure(`Error Occured Try Again`);
                    });
                }
              }
            });
          } else {
            setuploadloader(false);
            Loading.remove();
            const feesData = {
              to: String(address),
            };
            await Axios.post(
              `${env.apiHost}advocate/advocate/transfer`,
              feesData
            );
            Notify.failure(
              `Insufficient balance in your Wallet!, Try again after 5 minutes`
            );
            // console.log(feesData, "=-=-=-feesData");
          }
        } catch (e) {
          // console.log(e, "=-=-=-=-=-");
          setuploadloader(false);
          Loading.remove();
        }
      } else {
        setcountryValidation(true);
        // setUploadDetails({
        //   ...uploadDetails,

        //   countryValidation: true,
        // });
        Loading.remove();
      }
    } catch (error) {
      // console.log(error, "error-unVisibleObject")
    }
  }

  const btnUpload = (e) => {
    var files = e.target.files;
    uploadFile(files);
  };

  const uploadFile = async (files, callback) => {
    try {
      if (files.length === 0) {
        toast.error(`Select a file to Upload`);
        return null;
      }

      var fileName = files[0].name;
      setUploadDetails({
        ...uploadDetails,
        fileext: files[0],
      });
      var validExts = formats;
      var fileExt = fileName;
      fileExt = fileExt.substring(fileExt.lastIndexOf("."));
      if (validExts.indexOf(fileExt) < 0) {
        toast.error(
          "Invalid file selected, valid files are of " +
          validExts.toString() +
          " types."
        );
        return false;
      }

      const reader = new FileReader();

      const userEmail = "";
      localStorage.setItem("userEmail", userEmail);
      // console.log({ fileUploaded: files[0] }, "check that bhaiya");
      if (fileExt == ".docx" || fileExt == ".doc") {
        try {
          reader.onload = async (e) => {
            const content = e.target.result;
            // console.log(e, "reuslt e");
            try {
              var result = mammoth.convertToHtml({ arrayBuffer: content });
              // console.log(result, "result");
              if (result) {
                setUploadDetails({
                  ...uploadDetails,
                  shortContent: (await result).value,
                  uploadStatus: true,
                  viewStatus: true,
                  fileName: fileName,
                  fileext: "document",
                  text: (await result).value,
                });
              }
              // console.log(uploadDetailsref.current, "uploadDetails");
            } catch (err) {
              // console.log("logg the error", err);
              toast.error(`Invalid file!, Please provide the valid file!`);
            }
          };
          reader.readAsArrayBuffer(files[0]);
        } catch (error) {
          // console.log("error", error);
        }
      } else if (fileExt != ".pdf") {
        reader.onload = async (e) => {
          var image = e.target.result;
          // console.log(e.target.result, "upload result");
          var ImageUrl = image;
          const base64Response = await fetch(ImageUrl);
          const blobs = await base64Response.blob();
          var files = new Blob([blobs], {
            type: `application/${String(fileExt).substring(1)}`,
          });
          setUploadDetails({
            ...uploadDetails,
            shortContent: files,
            uploadStatus: false,
            viewStatus: true,
            viewPdf: false,
            fileext: "nonPDf",
            fileName: fileName,
            text: ImageUrl,
          });
        };
        reader.readAsDataURL(files[0]);
      } else {
        reader.onload = async (e) => {
          var image = e.target.result;
          var ImageUrl = image;
          // console.log(image, "image");
          const base64Response = await fetch(ImageUrl);
          const blobs = await base64Response.blob();
          var files = new Blob([blobs], {
            type: `application/${String(fileExt).substring(1)}`,
          });
          // console.log(files, "files");
          var blobURL = URL.createObjectURL(files);
          // console.log(blobURL, "blobURL");
          setUploadDetails({
            ...uploadDetails,
            shortContent: files,
            originalDraft: fileName,
            fileName: fileName,
            viewStatus: true,
            viewPdf: true,
            removeView: true,
            text: ImageUrl,
            bloburls: blobURL,
          });
        };
        reader.readAsDataURL(files[0]);
      }
    } catch (error) {
      // console.log(error, "error-unVisibleObject")
    }
  };

  function onChange(e) {
    setcountry({
      [e.target.name]: e.target.value,
    });
  }

  async function balancedata() {
    try {
      var balance = 0;
      var web3 = getWeb3();
      // console.log(web3, "web3");
      // console.log(
      //   web3.eth.accounts.privateKeyToAccount(keys()),
      //   "web3.eth.accounts"
      // );
      // console.log(uploadDetailsref.current, "location");

      const account = web3.eth.accounts.privateKeyToAccount(keys());
      web3.eth.accounts.wallet.add(account);
      var address = web3.eth.accounts.wallet[0].address;
      // console.log(address, "address ==========++++");
      setaddressdata(address);
      if (address) {
        const lawContractdata = new web3.eth.Contract(
          LawContract,
          LAWBLOCKS_CONTRACT
        );
        const lawTokenContractdata = new web3.eth.Contract(
          LawTokenContract,
          LAWBLOCKS_TOKEN_CONTRACT
        );

        setlawContract(lawContractdata);
        setlawTokenContract(lawTokenContractdata);
      }

      // console.log("address is here", Number(await web3.eth.getBalance(address)));
      balance = web3.utils.fromWei(
        Number(await web3.eth.getBalance(address)),
        "ether"
      );
      // console.log(balance, "=-=-=-balance");
      setBalances(balance);
    } catch (error) {
      // console.log(error, "error-unVisibleObject")
    }
  }

  const [documentData, setDocumentData] = useState({});

  async function onViewIPFS(result) {
    let url = `https://nftstorage.link/ipfs/${result.ipfsHash}`;

    window.open(url, "_blank");
  }

  async function onViewDocs(result) {
    console.log("clicked url show please ", result);

    const Splitdata = result.fileName.split(".")[1];

    var qrCodeUrl;
    if (result.storageType === "ipfs") {
      qrCodeUrl = `https://nftstorage.link/ipfs/${result.ipfsHash}`;
    } else if (result.storageType === "storx") {
      let shareresult = await storxStorageFunctions.generateShareToken({
        isFolder: false,
        itemId: result.ipfsHash,
        mnemonic: env.REACT_APP_STORX_MNEMONIC,
        apiAccessKey: env.REACT_APP_STORX_ACCESS_KEY,
        views: 0,
      });
      qrCodeUrl = `${env.REACT_APP_STORX_SHARE_URL}/${shareresult.result ? shareresult.result.token : ""
        }`;
    }

    var signerList = result.signerList;

    const documentData = {
      documentNo: result.documentNo,
      date: result.date,
      ipfsUrl:
        Splitdata == "docx"
          ? `https://ipfs.infura.io/ipfs/${result.ipfsHash}`
          : qrCodeUrl,
      ownerEmail: result.user ? result.user.email : "N/A",
      ownerName: result.user ? result.user.name : "N/A",
      txnHash: result.txnHash,
      signerEmail: result.signerEmail,
      tableList: signerList,
      htmlContent: "",
      signerLength: signerList.length,
      fileName: result.ipfsHash,
      txnUrl: `https://explorer.xinfin.network/tx/${result.txnHash}`,
    };

    setDocumentData(documentData);

    onViewPdf(documentData);

    // const Splitdata = result.fileName.split('.')[1];

    // Loading.standard("Please Wait", {
    //   messageColor: "#fff",
    //   svgColor: "#fff",
    //   svgSize: "100px",
    //   backgroundColor: "#eff7ff",
    // });
    // try {
    //   var fileContents;
    //   if (result.storageType === "ipfs") {
    //     const cid = (await client.check(result.ipfsHash).catch()).cid;
    //     console.log(cid, "cid");

    //     const url = `https://nftstorage.link/ipfs/${cid}`;
    //     // const url = `https://ipfs.infura.io/ipfs/${cid}`;

    //     console.log(url, "url");

    //     const response = await fetch(url);
    //     console.log(response, "response");
    //     if (response.ok == true) {
    //       fileContents = await response.blob();
    //       // console.log(fileContents, "fileContents");
    //     } else {
    //       console.error(`Error retrieving file: ${response.statusText}`);
    //     }
    //   } else if (result.storageType === "storx") {
    //     const response = await fetch(
    //       `${env.REACT_APP_STORX_URL}/api/storage/file/${result.ipfsHash}`,
    //       {
    //         headers: {
    //           accept: "application/json, application/pdf, text/plain, */*",
    //           "accept-language": "en-US,en;q=0.9",
    //           "storx-client": "drive-web",
    //           "storx-version": "2.0.0",
    //           cookie:
    //             "__stripe_mid=aba1f632-6b07-4dc5-aa74-dd5b6338652da74ede; ajs_anonymous_id=8cfcad49-5e17-4f71-adf5-46d6c95618d5; ajs_user_id=51d6d130-bbc0-450c-a1f3-3bdca0611b1f; REFERRAL=undefined; __stripe_sid=e39e6fb5-ee07-4f37-a801-b16f7955d3578ca826",
    //           Referer: `${env.REACT_APP_STORX_URL}/app`,
    //           "Referrer-Policy": "strict-origin-when-cross-origin",
    //           "storx-mnemonic": env.REACT_APP_STORX_MNEMONIC,
    //           "x-api-access-key": env.REACT_APP_STORX_ACCESS_KEY,
    //         },
    //         body: null,
    //         method: "GET",
    //       }
    //     );
    //     const resp = await response.arrayBuffer();
    //     fileContents = resp;
    //   } else {
    //     return null;
    //   }

    //   var signerList = result.signerList;
    //   var signerEmail = result.signerEmail;
    //   var signerTable = ``;
    //   var promises;
    //   if (signerList && signerList.length > 0) {
    //     promises = await signerList.map((res, index) => {
    //       signerTable += `<tr>
    //                         <td class="service">${index + 1}</td>
    //                         <td class="desc">${signerEmail[index]}</td>
    //                         <td class="unit"><a href=https://explorer.xinfin.network/address/${res}>${res}</a></td>
    //                     </tr>`;
    //       return signerTable;
    //     });
    //   }
    //   try {
    //     var qrCodeUrl;
    //     if (result.storageType === "ipfs") {
    //       qrCodeUrl = `https://nftstorage.link/ipfs/${result.ipfsHash}`;
    //     } else if (result.storageType === "storx") {
    //       let shareresult = await storxStorageFunctions.generateShareToken({
    //         isFolder: false,
    //         itemId: result.ipfsHash,
    //         mnemonic: env.REACT_APP_STORX_MNEMONIC,
    //         apiAccessKey: env.REACT_APP_STORX_ACCESS_KEY,
    //         views: 0,
    //       });
    //       qrCodeUrl = `${env.REACT_APP_STORX_SHARE_URL}/${shareresult.result ? shareresult.result.token : ""
    //         }`;
    //     }

    //     const tableList = await Promise.all(promises);
    //     const documentData = {
    //       documentNo: result.documentNo,
    //       date: result.date,
    //       ipfsUrl: Splitdata == "docx" ? `https://ipfs.infura.io/ipfs/${result.ipfsHash}` : qrCodeUrl,
    //       ownerEmail: result.user ? result.user.email : "N/A",
    //       ownerName: result.user ? result.user.name : "N/A",
    //       txnHash: result.txnHash,
    //       tableList: signerTable,
    //       htmlContent: "",
    //       signerLength: signerList.length,
    //       fileName: result.ipfsHash,
    //       txnUrl: `https://explorer.xinfin.network/tx/${result.txnHash}`,
    //     };
    //     let downloadResult = await Axios.post(
    //       `${env.apiHost}advocate/advocate/downloadpdf`, documentData);
    //     if (downloadResult.data) {
    //       Loading.remove();
    //       console.log("inside", downloadResult.data.filename);
    //       onViewPdf(downloadResult.data.filename);
    //     }
    //   } catch (err) {
    //     Loading.remove();
    //   }
    // } catch (error) {
    //   // console.log(error, "error-unVisibleObject");
    //   Loading.remove();
    // }
  }

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(,|$)/
      );
  }

  // async function onShare(result) {
  //   if (!web3) {
  //     Notify.failure(`Something Went Wrong Try Again Later`);
  //     return null;
  //   }
  //   try {
  //     Confirm.prompt(
  //       "Are  you share ?",
  //       "Enter the email ID to share",
  //       "",
  //       "Send",
  //       "Cancel",
  //       async (clientAnswer) => {
  //         if (
  //           clientAnswer != null &&
  //           clientAnswer !== undefined &&
  //           clientAnswer.trim() !== ""

  //         ) {
  //           const emailArray = clientAnswer.split(',').map(email => email.trim());
  //           const invalidEmails = emailArray.filter(email => !validateEmail(email));

  //           if (invalidEmails.length > 0) {
  //             Notify.failure(`Invalid Email(s): ${invalidEmails.join(', ')}`);
  //             return;
  //           }

  //           try {
  //             Axios.post(
  //               "http://localhost:3033/advocate/advocate/getUserAddress",
  //               {
  //                 email: emailArray,
  //               }
  //             ).then(async function (response) {
  //               let data = response && response.data;
  //               if (data.status) {
  //                 Loading.standard(
  //                   "Please wait while Confirming Transaction in Blockchain",
  //                   {
  //                     messageColor: "#fff",
  //                     svgColor: "#fff",
  //                     svgSize: "50px",
  //                     backgroundColor: "#eff7ff",
  //                   }
  //                 );
  //                 // console.log(data, "data----");
  //                 let shareUserWalletAddress = data.walletAddress.replace(
  //                   "xdc",
  //                   "0x"
  //                 );
  //                 const ethersProvider = new ethers.JsonRpcProvider(
  //                   "https://erpc.xinfin.network"
  //                 );
  //                 // console.log(keys(), "keysss", LAWBLOCKS_CONTRACT);
  //                 const ethersWallet = new ethers.Wallet(
  //                   keys(),
  //                   ethersProvider
  //                 );
  //                 const lawEthersInstance = new ethers.Contract(
  //                   LAWBLOCKS_CONTRACT,
  //                   LawContract,
  //                   ethersWallet
  //                 );
  //                 // console.log({ lawEthersInstance, shareUserWalletAddress });
  //                 let shareStatusTx = await lawEthersInstance.shareFile(
  //                   result.documentNo,
  //                   result.ipfsHash,
  //                   shareUserWalletAddress
  //                 );
  //                 // console.log(shareStatusTx);
  //                 await shareStatusTx.wait(1).then(async function () {
  //                   const [txhash, status, blockNumber] = await getTxnStatus(
  //                     shareStatusTx.hash
  //                   );
  //                   // console.log("-----share ", txhash);
  //                   if (!status) {
  //                     Report.failure(
  //                       "Error Occured on Blockchain Transaction",
  //                       `Try Again!!`,
  //                       "View",
  //                       () => {
  //                         window.open(
  //                           `https://explorer.xinfin.network/tx/${txhash}`
  //                         );
  //                       }
  //                     );
  //                     Loading.remove();
  //                     return null;
  //                   }
  //                   result.txnHash = txhash;
  //                   result.email = clientAnswer;
  //                   // console.log(result, "result");
  //                   Axios.post(
  //                     "http://localhost:3033/advocate/advocate/documentstore",
  //                     result
  //                   )
  //                     .then(function (response) {
  //                       Loading.remove();
  //                       Report.success(
  //                         "Document Shared Successfully",
  //                         "",
  //                         "View",
  //                         () => {
  //                           window.open(
  //                             `https://explorer.xinfin.network/tx/${txhash}`
  //                           );
  //                           getData();
  //                         }
  //                       );
  //                     })
  //                     .catch(function (error) {
  //                       Loading.remove();
  //                       Notify.failure(`Error Occured Try Again`);
  //                     });
  //                 });
  //               } else {
  //                 Notify.failure(`User not found for this Email address!`);
  //               }
  //             });
  //           } catch (err) {
  //             Notify.failure(`Transaction Failed!`);
  //           }
  //         } else {
  //           Notify.failure(`Please enter the email ID`);
  //         }
  //       },
  //       (clientAnswer) => {
  //         Notify.failure(`User Cancelled `);
  //       },
  //       {}
  //     );
  //   } catch (e) {
  //     Notify.failure(`Error Occured Try Again`);
  //   }
  // }

  async function onShare(result) {
    if (!web3) {
      Notify.failure(`Something Went Wrong. Try Again Later`);
      return null;
    }

    try {
      Confirm.prompt(
        "Are  you share ?",
        "Enter the email ID to share",
        "",
        "Send",
        "Cancel",
        async (clientAnswer) => {
          if (
            clientAnswer != null &&
            clientAnswer !== undefined &&
            clientAnswer.trim() !== ""
          ) {
            const emailArray = clientAnswer
              .split(",")
              .map((email) => email.trim());
            const invalidEmails = emailArray.filter(
              (email) => !validateEmail(email)
            );

            if (invalidEmails.length > 0) {
              Notify.failure(`Invalid Email(s): ${invalidEmails.join(", ")}`);
              return;
            }

            try {
              // Iterate over each email address and perform the share operation
              for (const email of emailArray) {
                await shareDocumentWithUser(result, email);
              }
            } catch (err) {
              Notify.failure(`Transaction Failed!`);
            }
          } else {
            Notify.failure(`Please enter the email ID`);
          }
        },
        (clientAnswer) => {
          Notify.failure(`User Cancelled`);
        },
        {}
      );
    } catch (e) {
      Notify.failure(`Error Occurred. Try Again`);
    }
  }

  async function shareDocumentWithUser(result, email) {
    try {
      const response = await Axios.post(
        `${env.apiHost}advocate/advocate/getUserAddress`,
        { email: email }
      );

      const data = response && response.data;
      if (data.status) {
        Loading.standard(
          "Please wait while Confirming Transaction in Blockchain",
          {
            messageColor: "#0d102d",
            svgColor: "#0d102d",
            svgSize: "50px",
            backgroundColor: "#eff7ff",
          }
        );

        let shareUserWalletAddress = data.walletAddress.replace("xdc", "0x");
        const ethersProvider = new ethers.JsonRpcProvider(
          "https://erpc.xinfin.network"
        );
        // console.log(keys(), "keysss", LAWBLOCKS_CONTRACT);
        const ethersWallet = new ethers.Wallet(keys(), ethersProvider);
        const lawEthersInstance = new ethers.Contract(
          LAWBLOCKS_CONTRACT,
          LawContract,
          ethersWallet
        );
        // console.log({ lawEthersInstance, shareUserWalletAddress });
        let shareStatusTx = await lawEthersInstance.shareFile(
          result.documentNo,
          result.ipfsHash,
          shareUserWalletAddress
        );
        // console.log(shareStatusTx);
        await shareStatusTx.wait(1).then(async function () {
          const [txhash, status, blockNumber] = await getTxnStatus(
            shareStatusTx.hash
          );
          // console.log("-----share ", txhash);
          if (!status) {
            Report.failure(
              "Error Occured on Blockchain Transaction",
              `Try Again!!`,
              "View",
              () => {
                window.open(`https://explorer.xinfin.network/tx/${txhash}`);
              }
            );
            Loading.remove();
            return null;
          }
          result.txnHash = txhash;
          result.email = email;
          // console.log(result, "result");
          Axios.post(`${env.apiHost}advocate/advocate/documentstore`, result)
            .then(function (response) {
              Loading.remove();
              Report.success("Document Shared Successfully", "", "View", () => {
                window.open(`https://explorer.xinfin.network/tx/${txhash}`);
                getData();
              });
            })
            .catch(function (error) {
              Loading.remove();
              Notify.failure(`Error Occured Try Again`);
            });
        });
      } else {
        Notify.failure(`User not found for this Email address!`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function onSign(result) {
    // console.log(result, "result");
    if (!web3) {
      Notify.failure(`Connect Your Wallet`);
      return null;
    }
    let signature = await web3.eth.accounts.sign(result.ipfsHash, keys());
    // console.log(signature);
    // console.log(addressdataref.current, { result });
    await lawContract.methods
      .signContract(result.documentNo, result.ipfsHash, signature)
      .send({ from: addressdataref, gas: 2100000 })
      .on("transactionHash", async function (transactionHash) {
        Loading.standard("Getting Transaction Hash", {
          messageColor: "#0d102d",
          svgColor: "#0d102d",
          svgSize: "100px",
          backgroundColor: "#eff7ff",
        });

        const [txhash, status] = await getTxnStatus(transactionHash);
        // console.log(status, "=-=-==-status");
        if (status != true) {
          Report.failure(
            "Error Occured on Blockchain Transaction",
            `Try Again!!`,
            "View",
            () => {
              window.open(
                `https://explorer.xinfin.network/tx/${transactionHash}`
              );
            }
          );
          Loading.remove();
          return null;
        }
      });
  }

  return (
    <div>
      <Header />
      <main class="mt-24">
        <section class="relative pt-16 pb-24">
          <div
            className="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="container" id="kt_content_container">
              <div class="mb-10 ">
                <DragAndDrop>
                  <div
                    class="group relative flex max-w-xxl flex-col  items-center justify-center rounded-lg border-2 border-dashed border-jacarta-100 bg-white py-20 mb-5 px-5 text-center"
                    onClick={() => {
                      upload.current.click();
                    }}
                  >
                    <div class="relative z-10 cursor-pointer">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40"
                        height="40"
                        class="mb-4 inline-block fill-jacarta-500" viewBox="0 0 24 24" fill="currentColor"><path d="M4 1V4H1V6H4V9H6V6H9V4H6V1H4ZM3 20.0066V11H5V19H13V14C13 13.45 13.45 13 14 13L19 12.999V5H11V3H20.0066C20.5552 3 21 3.45576 21 4.00247V15L15 20.996L4.00221 21C3.4487 21 3 20.5551 3 20.0066ZM18.171 14.999L15 15V18.169L18.171 14.999Z"></path></svg>
                      <p class="mx-auto max-w-xs text-l TextPrimary dark:text-jacarta-300">
                        Click Here to Upload File
                      </p>
                    </div>
                    <div class="absolute inset-4 cursor-pointer rounded bg-jacarta-50 opacity-0 dark:bg-jacarta-600"></div>
                    <input
                      type="file"
                      id="file-upload"
                      class="absolute inset-0 z-20 cursor-pointer opacity-0"
                      ref={upload}
                      style={{ display: "none" }}
                      onChange={btnUpload}
                    />
                  </div>
                </DragAndDrop>
                <div id="imageViewerDiv"></div>
                {uploadDetailsref.current.viewStatus && (
                  <div className="imadsetfd mb-5">
                    {uploadDetailsref.current.uploadStatus == true ? (
                      <div>
                        {uploadloaderref.current == false ? (
                          <React.Fragment>
                            <Editor
                              apiKey="1q31mvbn8z72qjyalrqpcjnrx3ge16jscq0lpfxtiue7pi0t"
                              value={uploadDetailsref.current.shortContent}
                              init={{
                                height: 400,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen powerpaste casechange",
                                  "directionality advcode visualchars mediaembed codesample pagebreak",
                                  "nonbreaking tableofcontents checklist tinymcespellchecker editimage",
                                  "formatpainter permanentpen linkchecker emoticons advtable export autosave",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                menubar: false,
                                content_style: "body img{ width:100%; }",
                                toolbar:
                                  "undo redo | styleselect | formatselect | bold italic backcolor | \
                                                                alignleft aligncenter alignright alignjustify | \
                                                                bullist numlist outdent indent | removeformat | table tabledelete | \
                                                                tableprops tablerowprops tablecellprops | \
                                                                tableinsertrowbefore tableinsertrowafter tabledeleterow | \
                                                                tableinsertcolbefore tableinsertcolafter tabledeletecol | help",
                              }}
                            // onEditorChange={handleEditorShortChange}
                            />
                            <div
                              style={{
                                // background: "aqua",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                                marginBottom: "30px",
                              }}
                            >
                              <b className="TextPrimary">
                                File Name : {uploadDetailsref.current.fileName}
                              </b>
                            </div>

                            <div

                            >
                              <div className="countryselect">
                                <Country
                                  uploadDetails={uploadDetails}
                                  changeContent={onChange}
                                />
                                <div className=" text-left text-danger">
                                  {countryValidationref.current == true ? (
                                    <p style={{ color: "red" }}>
                                      Country is mandatory field
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="btncountry" style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}>
                                <label
                                  class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                  onClick={() => onDraftUpload()}
                                >
                                  <span class="dark:text-white group-hover:text-white">
                                    Save As Draft
                                  </span>
                                </label>
                                <label
                                  class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                  onClick={() => onSubmitUpload()}
                                >
                                  <span class="dark:text-white group-hover:text-white">
                                    Upload Contract
                                  </span>
                                </label>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <>
                            <img
                              width="100"
                              height="100"
                              class="my-5 block mx-auto fill-jacarta-500 dark:fill-white"
                              src={require("../img/loadinggif.gif")}
                            ></img>
                            <p class="text-center text-l dark:text-jacarta-300">
                              Please Wait, Your transaction is uploading to ETH
                              Blockchain
                            </p>
                          </>
                        )}
                      </div>
                    ) : (
                      <div>
                        {uploadloaderref.current == false ? (
                          <React.Fragment>
                            {uploadDetails.viewPdf == false ? (
                              <div>
                                {uploadDetails.imagePreview == false ? (
                                  <div
                                    className="flex flex-col"
                                    style={{
                                      // background: "aqua",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      className=""
                                      style={{ fontSize: "200px" }}
                                    >
                                      <i class="ri-image-fill image_icon"></i>
                                    </span>
                                    <b className="TextPrimary">
                                      File Name : {uploadDetails.fileName}
                                    </b>
                                  </div>
                                ) : (
                                  <div>
                                    <div className="imn_dd">
                                      <img
                                        style={{ width: "100%" }}
                                        src={uploadDetails.text}
                                      ></img>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <b className="TextPrimary">
                                        File Name : {uploadDetails.fileName}
                                      </b>{" "}
                                    </div>
                                  </div>
                                )}

                                <div className="disflex">
                                  <div className="countryselect">
                                    <Country
                                      uploadDetails={uploadDetails}
                                      changeContent={onChange}
                                    />
                                    <div className=" text-left text-danger">
                                      {countryValidationref.current == true ? (
                                        <p style={{ color: "red" }}>
                                          Country is mandatory field
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="btncountry disflexs">
                                    {uploadDetails.imagePreview == true ? (
                                      <label
                                        class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                        onClick={() => onpreview()}
                                      >
                                        <span class="dark:text-white group-hover:text-white">
                                          Hide Preview
                                        </span>
                                      </label>
                                    ) : (
                                      <label
                                        class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                        onClick={() => onpreview()}
                                      >
                                        <span class="dark:text-white group-hover:text-white">
                                          Show Preview
                                        </span>
                                      </label>
                                    )}
                                    <label
                                      class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                      onClick={() => onDraftUpload()}
                                    >
                                      <span class="dark:text-white group-hover:text-white">
                                        Save As Draft
                                      </span>
                                    </label>
                                    <label
                                      class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                      onClick={() => onSubmitUpload()}
                                    >
                                      <span class="dark:text-white group-hover:text-white">
                                        Upload Contract
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                {uploadDetails.imagePreview == false ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      className=""
                                      style={{ fontSize: "200px" }}
                                    >
                                      <i class="ri-file-pdf-line image_icon"></i>
                                    </span>
                                    <b className="TextPrimary">
                                      File Name : {uploadDetails.fileName}
                                    </b>
                                  </div>
                                ) : (
                                  <div>
                                    <iframe
                                      id="pdfFrame"
                                      src={uploadDetails.bloburls}
                                      width="100%"
                                      height="500"
                                    ></iframe>

                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <b className="TextPrimary">
                                        File Name : {uploadDetails.fileName}
                                      </b>{" "}
                                    </div>
                                  </div>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="countryselect">
                                    <Country
                                      uploadDetails={uploadDetails}
                                      changeContent={onChange}
                                    />
                                    <div className=" text-left text-danger">
                                      {countryValidationref.current == true ? (
                                        <p style={{ color: "red" }}>
                                          Country is mandatory field
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="btncountry">
                                    {uploadDetails.imagePreview == true ? (
                                      <label
                                        class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                        onClick={() => onpreview()}
                                      >
                                        <span class="dark:text-white group-hover:text-white">
                                          Hide Preview
                                        </span>
                                      </label>
                                    ) : (
                                      <label
                                        class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                        onClick={() => onpreview()}
                                      >
                                        <span class="dark:text-white group-hover:text-white">
                                          Show Preview
                                        </span>
                                      </label>
                                    )}
                                    <label
                                      class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                      onClick={() => onDraftUpload()}
                                    >
                                      <span class="dark:text-white group-hover:text-white">
                                        Save As Draft
                                      </span>
                                    </label>
                                    <label
                                      class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                      onClick={() => onSubmitUpload()}
                                    >
                                      <span class="dark:text-white group-hover:text-white">
                                        Upload Contract
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        ) : (
                          <>
                            <img
                              width="100"
                              height="100"
                              class="my-5 block mx-auto fill-jacarta-500 dark:fill-white"
                              src={require("../img/loadinggif.gif")}
                            ></img>
                            <p class="text-center text-l dark:text-jacarta-500">
                              Please Wait, Your transaction is uploading to ETH
                              Blockchain
                            </p>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="row">
                <ul
                  class="nav nav-tabs flex items-center justify-center flex-wrap"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
                      id="offers-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#offers"
                      type="button"
                      onClick={() =>
                        setUploadDetails({
                          ...uploadDetails,
                          currentPage: "draft",
                        })
                      }
                      role="tab"
                      aria-controls="offers"
                      aria-selected="true"
                    >
                      <span class="font-display text-base font-medium">
                        Saved Drafts ({draftList.length})
                      </span>
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
                      id="offers-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#offers"
                      onClick={() =>
                        setUploadDetails({
                          ...uploadDetails,
                          currentPage: "upload",
                        })
                      }
                      type="button"
                      role="tab"
                      aria-controls="offers"
                      aria-selected="true"
                    >
                      <span class="font-display text-base font-medium">
                        Uploaded Contract ({documentList.length})
                      </span>
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
                      id="offers-tab"
                      data-bs-toggle="tab"
                      onClick={() =>
                        setUploadDetails({
                          ...uploadDetails,
                          currentPage: "share",
                        })
                      }
                      data-bs-target="#offers"
                      type="button"
                      role="tab"
                      aria-controls="offers"
                      aria-selected="true"
                    >
                      <span class="font-display text-base font-medium">
                        Shared Contract ({shareList.length})
                      </span>
                    </button>
                  </li>
                </ul>

                {uploadDetails.currentPage === "draft" && (
                  <div className="col-md-12">
                    <div className="card mb-5 pt-4 mb-xl-10">
                      <div class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg ">
                        <div className="uploadtbl">
                          {/*begin::Table*/}
                          <Table className="bordered hover responsive">
                            {" "}
                            {/*begin::Thead*/}
                            <thead className="border-gray-200 fs-5 fw-bold bg-lighten">
                              <tr>
                                <th className="TextPrimary">S.No</th>
                                <th className="TextPrimary">Draft Name</th>
                                <th className="TextPrimary">Owner</th>
                                <th className="TextPrimary">View</th>
                              </tr>
                            </thead>
                            {/*end::Thead*/}
                            {/*begin::Tbody*/}
                            <tbody className="fw-6 fw-bold text-gray-600">
                              {draftListref.current && draftList.length > 0 ? (
                                draftList.map((result, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{result.draftName}</td>
                                      <td>
                                        {result.user ? result.user.name : "~"}
                                      </td>
                                      <td>
                                        <label class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                                          <span class="dark:text-white group-hover:text-white">
                                            <a
                                              href={`/viewdraft/${result._id}`}
                                              target={"_blank"}
                                            >
                                              <button className="btn btn-sm btn-primary">
                                                view
                                              </button>
                                            </a>
                                          </span>
                                        </label>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="4">
                                    <span className="w-100 text-center d-block">
                                      No History found!
                                    </span>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                            {/*end::Tbody*/}
                          </Table>
                          {/*end::Table*/}
                        </div>
                        {/*end::Table wrapper*/}
                      </div>
                      {/*end::Card body*/}
                    </div>
                  </div>
                )}
                {uploadDetails.currentPage === "upload" && (
                  <div className="col-md-12">
                    <div className="card mb-5 pt-4 mb-xl-10">
                      {/*begin::Card body*/}
                      <div class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                        <div className="uploadtbl">
                          {/*begin::Table*/}
                          <Table className="bordered hover responsive">
                            {/*begin::Thead*/}
                            <thead className="border-gray-200 fs-5 fw-bold bg-lighten">
                              <tr>
                                <th className="TextPrimary">S.No</th>
                                <th className="TextPrimary">Doc No</th>
                                <th className="TextPrimary">Doc Name</th>
                                <th className="TextPrimary">Txn Hash</th>
                                <th className="TextPrimary">View</th>
                                <th className="TextPrimary">File</th>
                                <th className="TextPrimary">Share</th>
                                <th className="TextPrimary">Signed By</th>
                              </tr>
                            </thead>
                            {/*end::Thead*/}
                            {/*begin::Tbody*/}
                            <tbody className="fw-6 fw-bold text-gray-600">
                              {documentListref.current &&
                                documentList.length > 0 ? (
                                documentList.map((result, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{result.documentNo}</td>
                                      <td className="table-line-break">
                                        {result.fileName}
                                      </td>
                                      <td>
                                        <a
                                          target={"_blank"}
                                          href={`https://explorer.xinfin.network/tx/${result.txnHash}`}
                                        >
                                          {truncateEthAddress(result.txnHash)}
                                        </a>
                                      </td>

                                      <td>
                                        <label
                                          class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                          onClick={() => onViewDocs(result)}
                                        >
                                          <span class="dark:text-white group-hover:text-white">
                                            <button className="btn btn-sm btn-primary">
                                              View
                                            </button>
                                          </span>
                                        </label>
                                      </td>

                                      <td>
                                        <label
                                          class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                          onClick={() => onViewIPFS(result)}
                                        >
                                          <span class="dark:text-white group-hover:text-white">
                                            <button className="btn btn-sm btn-primary">
                                              IPFS
                                            </button>
                                          </span>
                                        </label>
                                      </td>

                                      <td>
                                        <label
                                          class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                          onClick={() => onShare(result)}
                                        >
                                          <span class="dark:text-white group-hover:text-white">
                                            <button className="btn btn-sm btn-primary">
                                              Share
                                            </button>
                                          </span>
                                        </label>
                                      </td>

                                      <td>
                                        <label
                                          class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                          onClick={() => onViewSigner(result)}
                                        >
                                          <span class="dark:text-white group-hover:text-white">
                                            <button className="btn btn-sm btn-primary">
                                              Signed By
                                            </button>
                                          </span>
                                        </label>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="7">
                                    <span className="w-100 text-center d-block">
                                      No History found!
                                    </span>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                            {/*end::Tbody*/}
                          </Table>
                          {/*end::Table*/}
                        </div>
                        {/*end::Table wrapper*/}
                      </div>
                      {/*end::Card body*/}
                    </div>
                  </div>
                )}

                {uploadDetails.currentPage === "share" && (
                  <div className="col-md-12">
                    <div className="card mb-5 pt-4 mb-xl-10">
                      <div class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                        <div className="uploadtbl">
                          <Table bordered hover responsive>
                            <thead className="border-gray-200 fs-5 fw-bold bg-lighten">
                              <tr>
                                <th className="TextPrimary">S.No</th>
                                <th className="TextPrimary">Doc No</th>
                                <th className="TextPrimary">Doc Name</th>
                                <th className="TextPrimary">Txn Hash</th>
                                <th className="TextPrimary">View</th>
                                {/* <th className="text-white">Sign</th> */}
                              </tr>
                            </thead>
                            <tbody className="fw-6 fw-bold text-gray-600">
                              {shareListref.current && shareList.length > 0 ? (
                                shareList.map((result, index) => {
                                  const signerList = result.signerList;
                                  const signerEmail = result.signerEmail;
                                  var findDoc = signerList.find(
                                    (x) => x === toXdc(address)
                                  );

                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{result.documentNo}</td>
                                      <td className="table-line-break">
                                        {result.fileName}
                                      </td>
                                      <td>
                                        <a
                                          target={"_blank"}
                                          href={`https://explorer.xinfin.network/tx/${result.txnHash}`}
                                        >
                                          {truncateEthAddress(result.txnHash)}
                                        </a>
                                      </td>
                                      <td>
                                        <label
                                          class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                          onClick={() => onViewDocs(result)}
                                        >
                                          <span class="dark:text-white group-hover:text-white">
                                            <button className="btn btn-sm btn-primary">
                                              View
                                            </button>
                                          </span>
                                        </label>
                                      </td>
                                      {/* <td>
                                        {!findDoc && (
                                          <label
                                            class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                            onClick={() => onSign(result)}
                                          >
                                            <span class="dark:text-white group-hover:text-white">
                                              <button className="btn btn-sm btn-primary">
                                                Sign
                                              </button>
                                            </span>
                                          </label>

                                        )}
                                        {findDoc && (
                                          <label
                                            class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                            onClick={() => onViewSigner(result.signerList)}
                                          >
                                            <span class="dark:text-white group-hover:text-white">
                                              <button className="btn btn-sm btn-primary">
                                                Signer
                                              </button>
                                            </span>
                                          </label>

                                        )}
                                      </td> */}
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="5">
                                    <span className="w-100 text-center d-block">
                                      No History found!
                                    </span>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div class="d-flex flex-column flex-root">
            <div className="page d-flex flex-row flex-column-fluid">
              <Modal open={modelShow} onClose={onCloseModal} center>
                <div className="px-4">
                  <h3>Signed By</h3>
                  <table className="table align-middle table-row-dashed fs-6 gy-5">
                    {signerView &&
                      signerView.signerEmail.map((result) => {
                        return (
                          <tr>
                            <td colSpan={10} className="">
                              Name : {result}
                            </td>
                          </tr>
                        );
                      })}
                    {signerView &&
                      signerView.signerList.map((result) => {
                        return (
                          <tr>
                            <td colSpan={10} className="text-center">
                              {result}
                            </td>
                          </tr>
                        );
                      })}
                  </table>
                </div>
              </Modal>
              <Modal open={modelShowPdf} onClose={onCloseModalPdf} center>
                <div className="px-5 w-full">
                  <PDFViewer style={{ width: "100%", height: "100vh" }}>
                    <MyDocument documentData={documentData} />
                  </PDFViewer>
                </div>
              </Modal>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
export default ContractUpload;

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  st1: {
    fontSize: 10,
    fontWeight: 400,
    marginBottom: 10,
    backgroundColor: "gray",
    color: "white",
    width: 180,
    height: 19,
    textAlign: "center",
    paddingTop: 4,
  },

  headerRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },

  documentNo: {
    fontSize: 10,
    marginBottom: 10,
    border: "1px solid #000",
    color: "#000",
    width: 180,
    height: 19,
    textAlign: "center",
    paddingTop: 4,
    fontWeight: 500,
  },

  dateIssued: {
    fontSize: 10,
    color: "#252525",
  },
  rightText: {
    fontSize: 10,
    color: "#252525",
  },
  logo: {
    width: 60,
    height: 40,
  },
  image: {
    width: 60,
    height: 50,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  mainContent: {
    padding: 10,
    marginTop: 20,
    border: "1px solid #000",
    height: 160,
  },

  maintext: {
    marginTop: 5,
    fontSize: 9,
    color: " #252525",
  },
  txHashText: {
    marginTop: 10,
    fontSize: 9,
    color: " #000",
    fontWeight: 800,
  },
  link: {},
  uploadContractHeader: {
    marginTop: 15,
    backgroundColor: "gray",
    height: 30,
    color: "white",
    fontSize: 12,
    paddingLeft: 8,
    paddingTop: 8,
  },

  lawblocksNotes: {
    marginTop: 15,
    backgroundColor: "gray",
    height: 40,
    color: "white",
    fontSize: 12,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 7,
    marginBottom: 15,
  },

  tableHeader: {
    height: 20,
    borderBottom: "1px solid grey",
    color: "#000",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  tableData: {
    backgroundColor: "#F5F5F5",
    color: "#000",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 25,
  },

  tHeader1: {
    color: "#000",
    fontSize: 10,
    width: "5%",
    paddingLeft: 5,
  },
  tHeader2: {
    color: "#000",
    fontSize: 10,
    width: "30%",
  },
  tHeader3: {
    color: "#000",
    fontSize: 10,
    width: "40%",
  },
  powerTxt: {
    position: "absolute",
    fontSize: 11,
    bottom: 30,
    left: 40,
    textAlign: "center",
  },
  dockNo: {
    position: "absolute",
    fontSize: 11,
    bottom: 30,
    right: 40,
    textAlign: "center",
  },
  uploadedContractImg: {
    height: 60,
    width: 100,
  },
});

// Create Document Component
const MyDocument = ({ documentData }) => {
  console.log("document", { documentData }, logo);

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.header}>
          <View>
            <Image style={styles.logo} src={logo} />
            <Image
              style={styles.image}
              src={
                "https://image-charts.com/chart?chs=150x150&cht=qr&chl=" +
                documentData?.ipfsUrl
              }
            />
          </View>

          <View>
            <Text style={styles.st1}>Record of Contract on Blockchain</Text>

            <Text style={styles.documentNo}>
              Document Number: {documentData?.documentNo ?? ""}
            </Text>

            <Text style={styles.dateIssued}>
              Date Issued: {documentData?.date ?? ""}
            </Text>
          </View>
        </View>

        <View style={styles.header}>
          <View>
            <Text style={styles.dateIssued}>Authorized Person</Text>

            <Text style={styles.dateIssued}>
              Name: {documentData?.ownerName ?? ""}
            </Text>

            <Text style={styles.dateIssued}>
              Email: {documentData?.ownerEmail ?? ""}
            </Text>
          </View>

          <View>
            <Text style={styles.rightText}>Attention</Text>

            <Text style={styles.rightText}>
              Name: {documentData?.ownerName ?? ""}
            </Text>

            <Text style={styles.rightText}>
              Email: {documentData?.ownerEmail ?? ""}
            </Text>
          </View>
        </View>

        <View style={styles.mainContent}>
          <Text style={styles.maintext}>
            The electronic document(s) accompanying this Contract is digitally
            stamped by embedding its SHA256 hash imprint within the blockchain
            ledger maintained in the ETH network
          </Text>

          <Text style={styles.txHashText}>
            Transaction HASH : {documentData?.txnHash ?? ""}
          </Text>

          <Text style={styles.txHashText}>
            View Original Data :
            <Link href={documentData?.ipfsUrl ?? ""} style={styles.link}>
              {documentData?.ipfsUrl ?? ""}{" "}
            </Link>
          </Text>

          <Text style={styles.maintext}>
            Timestamp : {documentData?.date ?? ""} (may vary slightly subject to
            appropriate network confirmations) Number of Signers in this
            Contract: {documentData?.signerLength ?? ""}
          </Text>

          <Text style={styles.maintext}>
            The link below to ETH blockchain explorer may be used to verify this
            particular digital stamping:{" "}
            <Link href={documentData?.txnUrl ?? ""}>
              {documentData?.txnUrl ?? ""}
            </Link>
          </Text>

          <Text style={styles.maintext}>
            In case the above link is not available in future, verification may
            be sought by logging on to JustiChain website and using the above TX
            number
          </Text>
        </View>

        <View style={styles.mainContent}>
          <Text style={styles.txHashText}>Disclaimer:</Text>

          <Text style={styles.maintext}>
            1. This Contract has been sent to you by the Sender’s name indicated
            above. Should you receive this message by mistake, please delete
            immediately.
          </Text>

          <Text style={styles.maintext}>
            1. This Contract has been sent to you by the Sender’s name indicated
            above. Should you receive this message by mistake, please delete
            immediately.
          </Text>

          <Text style={styles.maintext}>
            2. JustiChain and/or its associates/affiliates do not undertake any
            responsibility for the contents and the intent of this transaction
          </Text>

          <Text style={styles.maintext}>
            3. We place the security of our Clients at a high priority. While,
            we have put efforts into ensuring that the message is error and
            virus-free, Unfortunately, full security of the email cannot be
            ensured as it passes through several layers of service providers and
            despite our efforts, the data included in emails could be infected,
            intercepted, or corrupted. Therefore, the recipient should check the
            email for threats with proper software, as we do not accept
            liability for any damage inflicted by viewing the content of this
            message.
          </Text>
        </View>
        <View style={styles.lawblocksNotes}>
          <Text>
            JustiChain certify that this document is digitally signed by the
            following stakeholders and stored in blockchain.
          </Text>
        </View>

        <View style={styles.tableHeader}>
          <Text style={styles.tHeader1}>#</Text>
          <Text style={styles.tHeader2}>Name</Text>
          <Text style={styles.tHeader3}>Wallet Address</Text>
        </View>

        {documentData &&
          documentData.tableList &&
          documentData.tableList.length > 0 &&
          documentData.tableList?.map((item, index) => {
            console.log("item", item);
            return (
              <View style={styles.tableData}>
                <Text style={styles.tHeader1}>{index + 1}</Text>
                <Text style={styles.tHeader2}>
                  {documentData?.signerEmail[index]}
                </Text>
                <Text style={styles.tHeader3}>{item}</Text>
              </View>
            );
          })}

        <View style={styles.uploadContractHeader}>
          <Text>Uploaded Contract</Text>
        </View>

        <Image
          style={styles.uploadedContractImg}
          src={
            "https://image-charts.com/chart?chs=150x150&cht=qr&chl=" +
            documentData?.ipfsUrl
          }
        />

        <Text style={styles.powerTxt}>
          Contract Powered By{" "}
          <Link href="https://market.JustiChain.io/">JustiChain</Link>
        </Text>

        <Text style={styles.dockNo}>
          Document Number: {documentData?.documentNo ?? ""}
        </Text>
      </Page>
    </Document>
  );
};
