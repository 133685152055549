import React, {useState, useEffect} from "react";
// import Sliderimg from "./Sliderimg";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Header from "./Header";
import {getMethod} from "../core/service/common.api";
import apiService from "../core/service/v1/index";
import {postMethod} from "../core/service/common.api";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

function Home() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const styles = {
    backgroundImage:
      "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/2764-fe0f.png)",
  };

  const [allAdvocate, setallAdvocate] = useState([]);
  const [lawLoader, setlawLoader] = useState(false);
  const navigate = useNavigate();
  const [getCartDetails, setCartDetails] = useState([]);

  useEffect(() => {
    getLawerDetails();
    getCartUserDetails();
  }, [0]);

  const getLawerDetails = async (e) => {
    try {
      var data = {
        apiUrl: apiService.getAllAdvocates,
      };
      setlawLoader(true);
      var resp = await getMethod(data);
      setlawLoader(false);
      // console.log(resp.Message);
      if (resp.status == true) {
        setallAdvocate(resp.Message);
      } else {
        setallAdvocate([]);
      }
    } catch (error) {
      // console.log(error, "::::error");
    }
  };

  const goToProfile = async (id) => {
    try {
      // console.log(id, "====id====");
      navigate("/profile?" + id);
    } catch (error) {
      // console.log(error, "::::error");
    }
  };
  const getCartUserDetails = async (id) => {
    try {
      var data = {
        apiUrl: apiService.getCartDetails,
      };
      // setlawLoader(true);
      var resp = await getMethod(data);
      // setlawLoader(false);
      // console.log(resp.Message);
      if (resp.status == true) {
        setCartDetails(resp.Message);
      } else {
        setCartDetails([]);
      }
    } catch (error) {
      // console.log(error, "::::error");
    }
  };

  const remove = async (item) => {
    try {
      var obj = {
        type: localStorage.getItem("type"),
        _id: item._id,
      };
      var data = {
        apiUrl: apiService.removCartDetails,
        payload: obj,
      };
      var resp = await postMethod(data);
      // console.log(resp.Message, "=-=-=-==-=-=-=-=-=-=");
      if (resp.status == true) {
        toast(resp.Message);
        getCartUserDetails();
      } else {
        toast(resp.Message);
      }
    } catch (error) {
      // console.log(error, "::::error");
    }
  };

  const checkout = async (item) => {
    try {
      // console.log(item, "=-=-=-=checkout===-=-=--");
      var obj = {
        type: localStorage.getItem("type"),
        data: item,
      };
      var data = {
        apiUrl: apiService.checkoutCart,
        payload: obj,
      };
      var resp = await postMethod(data);
      // console.log(resp.Message, "=-=-=-==-=-=-=-=-=-=");
      if (resp.status == true) {
        toast(resp.Message);
        getCartUserDetails();
      } else {
        toast(resp.Message);
      }
    } catch (error) {}
  };

  return (
    <>
      <Header />
      <main className="pt-[5.5rem] lg:pt-24">
        <div className="container my-5">
          <div class="lg:flex">
            {/* <!-- Records --> */}
            {/* <div class="mb-10 shrink-0 basis-6/12 flex gap20 lg:mb-0 lg:pr-10"> */}
            <div class="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-2 js-collections-grid">
              {getCartDetails.length > 0 ? (
                getCartDetails.map((item, i) => {
                  return (
                    <>
                      <a class="relative flex items-center wrap_flex rounded-2.5xl border border-jacarta-100 bg-white p-8 transition-shadow hover:shadow-lg">
                        <a href={"/Item?" + item._id}>
                          <figure class="mr-5 self-start ">
                            <img
                              src={require("../img/docx.png")}
                              alt="avatar 2"
                              class="rounded-2lg"
                              loading="lazy"
                            />
                          </figure>
                        </a>
                        <div>
                          <h3 class="mb-1 font-display text-base font-semibold text-jacarta-700 dark:text-white">
                            {item.name}
                          </h3>
                          <span class="mb-3 block text-sm text-jacarta-500 dark:text-jacarta-200">
                            {item.amount} {""} {"USDT"}
                          </span>
                         
                        </div>

                       

                        <div class="ml-auto rounded-full border border-jacarta-100 p-3 dark:border-jacarta-600 mr-auto0 ">
                          <button
                            onClick={() => checkout(getCartDetails)}
                            className="rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                          >
                            Checkout
                          </button>
                        </div>
                        <div
                          onClick={() => remove(item)}
                          class="ml-auto rounded-full border border-jacarta-100 p-3 dark:border-jacarta-600 mr-auto0 "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                          >
                            <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"></path>
                          </svg>
                        </div>
                      </a>
                    </>
                  );
                })
              ) : (
                <figure class="mr-5 self-start edn_marker">
                  <img
                    src={require("../img/shopNo.jpg")}
                    alt="avatar 2"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <p>No Carts found</p>
                </figure>
              )}
              {/* {getCartDetails.length > 0 ? (
                <div class="mb-10 flex items-center justify-end space-x-2.5">
                  <button
                    onClick={() => checkout(getCartDetails)}
                    className="rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  >
                    Checkout
                  </button>
                </div>
              ) : (
                ""
              )} */}
            </div>

            {/* <!-- Filters --> */}
            {/* <aside class="basis-4/12 lg:pl-5"></aside> */}
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}

export default Home;
