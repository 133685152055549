import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
// import Sliderimg from "./Sliderimg";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Header from "./Header";
import { toast } from "react-toastify";
import useStateref from "react-usestateref";
import { Editor } from "@tinymce/tinymce-react";
// import csc from 'country-state-city';
import { Parser } from 'html-to-react'

import { Form, Button } from "react-bootstrap";
import { Country, State, City } from "country-state-city";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/v1/index";
import { useNavigate, Link } from "react-router-dom";
import { Buffer } from "buffer";
import Select from "react-select";

function Home() {


  const [step, setStep] = useState(1);

  document.onkeydown = function (e) {
    if (e.keyCode == 13) {
      // console.log("enter")
      handleNext()
    }
  };
  const handleNext = () => {
    var Country = localStorage.getItem("Country")
    var state = localStorage.getItem("state")
    var document = localStorage.getItem("document")
    // console.log(Country, "==-=", state, "=-=-=", document)
    if (step == 1) {
      setStep(step + 1);
      // console.log("1111111111111111")
    }
    if (step == 2) {
      if (Country != null&&Country != "") {
        // console.log(step, "222222222222222", Country)
        setStep(step + 1);
      } else {
        toast("Country type is required")
      }
    } else
      if (step == 3) {
        if(state != null&&state != ""){
          setStep(step + 1)
        }else{
        toast("State is required")
        }
      } else
        if (step == 4) {
          if(document != null&&document != ""){
            setStep(step + 1)
          }else{
            toast("Document type is required")
          }
        }
  };

  return (<>
    <Header />
    <div className="form-container">
      {step === 1 && <Step1 />}
      {step === 2 && <Step2 />}
      {step === 3 && <Step3 />}
      {step === 4 && <Step4 />}
      {step === 5 && <Step5 />}
      <div className="container">
        <div className="button-container">
          {/* {step > 1 ?(
            <button onClick={handlePrevious} className="previous-button cursor-default rounded-full bg-accent-lighter py-3 px-8 text-center font-semibold text-white transition-all">
              Previous
            </button>
          ) : ""} */}
          {step == 1 ?
            <button onClick={handleNext} class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark d-block mx-auto">
              Next
            </button>
            : step == 2 ?
              <button onClick={handleNext} class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                Next
              </button> : step == 3 ?
                <button onClick={handleNext} class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                  Next
                </button> : step == 4 ?
                  <button onClick={handleNext} class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                    Next
                  </button> : ""}
        </div>
      </div>
    </div>
    <Footer />
  </>

  );
};

const Step1 = () => {
  return (
    <div className="step step-1">
      <div className="step-content">
        <div className="container">
          <div className="form_ui">
            <h1 className="mb-10 font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl bold text-center">Create a new legal document with <span className="animate-gradient ml-2">help of AI</span></h1>
          </div>
        </div>
      </div>
    </div>
  );
};



// const Step2 = () => {

//   const value = {
//     State: "",
//     Country: "",
//     Document: "",
//   };
//   const [inputValue, setinputValue] = useState(value);
//   const [Stateerr, setStateerr] = useState(false);
//   const [Countryerr, setCountryerr] = useState(false);
//   const [Documenterr, setDocumenterr] = useState(false);
//   const [Loader, setLoader] = useState(false);
//   const [DownLoader, setDownLoader] = useState(false);
//   const [downloadOption, setdownloadOption] = useState(false);
//   const [validate, setvalidate] = useState("");
//   const [htmlContent, sethtmlContent] = useState("");

//   const getValues = (e) => {
//     const { name, value } = e.target;
//     const formData = { ...inputValue, ...{ [name]: value } };
//     setinputValue(formData);
//   };

//   const { State, Country, Document } = inputValue;
//   const validation = () => {
//     const error = {};
//     if (inputValue.Country == "") {
//       error.Country = "Country is required";
//       setCountryerr(true);
//     }
//     if (inputValue.State == "") {
//       error.State = "State is required";
//       setStateerr(true);
//     }
//     if (inputValue.Document == "") {
//       error.Document = "Document is required";
//       setDocumenterr(true);
//     }
//     setvalidate(error);
//   };

//   const documentDownloader = async () => {
//     try {
//       if (htmlContent != "") {
//         var obj = {
//           htmlContent: htmlContent,
//         };
//         var data = {
//           apiUrl: apiService.documentDownloader,
//           payload: obj,
//         };
//         setDownLoader(true);
//         var resp = await postMethod(data);
//         setDownLoader(false);
//         if (resp.Status == true) {
//           // console.log(resp, "=-=-=-=-=-resp");
//           window.location.href = resp.data.secure_url;
//           toast("Document Downloaded successfully");
//           setdownloadOption(false);
//         }
//       } else {
//         // console.log(htmlContent, "=-=-=-=-htmlContent");
//       }
//     } catch (error) { }
//   };

//   const generateAI = async () => {
//     try {
//       validation();
//       // console.log(inputValue, "=-=-=-=-power of attorney");
//       if (
//         inputValue.Country != "" &&
//         inputValue.State != "" &&
//         inputValue.Document != ""
//       ) {
//         var content = `generate a legal document template for ${inputValue.Document}, for country ${inputValue.Country} and state ${inputValue.State} in html format`;
//         setLoader(true);
//         inputValue.Country = "";
//         inputValue.State = "";
//         inputValue.Document = "";
//         const response = await axios.post(
//           "https://api.openai.com/v1/chat/completions",
//           {
//             model: "gpt-3.5-turbo",
//             messages: [
//               {
//                 role: "user",
//                 content: content,
//               },
//             ],
//             temperature: 0.7,
//           },
//           {
//             headers: {
//               "Content-Type": "application/json",
//               Authorization:
//                 "Bearer " +
//                 "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",
//             },
//           }
//         );
//         if (response) {
//           setLoader(false);
//           toast("Document generated successfully");
//           // console.log("Response:", response.data.choices[0].message.content);
//           sethtmlContent(response.data.choices[0].message.content);
//           setdownloadOption(true);
//           // docxHtmlRef = response.data.choices[0].message.content;
//         }
//       }
//     } catch (error) {
//       setLoader(false);
//       // console.log("An error occurred:", error.response.data);
//     }
//   };
//   document.onkeydown = function (e) {
//     if (e.keyCode == 13) {
//       // console.log("enter")
//       generateAI()
//     }
//   };
//   const viewcancel = () => {
//     sethtmlContent("");
//   };
//   return (
//     <div className="step step-2">
//       <div className="step-content">
//         {htmlContent == "" ?
//           <div className="container">
//             <div className="form_cls">
//               <div class="mb-6">
//                 <label
//                   for="item-name"
//                   class="mb-2 block font-display text-jacarta-700 dark:text-white"
//                 >
//                   Country<span class="text-red"> </span>
//                 </label>
//                 <input
//                   type="text"
//                   id="item-name"
//                   value={Country}
//                   name="Enter Country"
//                   maxLength={20}
//                   onChange={getValues}
//                   class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:text-white dark:placeholder:text-jacarta-300"
//                   placeholder="Country"
//                 />
//                 <div>
//                   {Countryerr == true ? (
//                     <p style={{ color: "red" }}>{validate.Country}</p>
//                   ) : (
//                     ""
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//           :
//           <div className="container editor_value" >
//             <div className="mt-5">
//               <div className="mt-5">
//                 <h2 className="mb-10 font-display text-center text-4xl font-medium text-jacarta-700 dark:text-white">
//                   Generated Document
//                 </h2>
//               </div>
//               <div>
//                 <React.Fragment>
//                   <Editor
//                     value={htmlContent}
//                     init={{
//                       height: "50vh",
//                       plugins: [
//                         "advlist autolink lists link image charmap print preview anchor",
//                         "searchreplace visualblocks code fullscreen powerpaste casechange",
//                         "directionality advcode visualchars mediaembed codesample pagebreak",
//                         "nonbreaking tableofcontents checklist tinymcespellchecker editimage",
//                         "formatpainter permanentpen linkchecker emoticons advtable export autosave",
//                         "insertdatetime media table paste code help wordcount",
//                       ],
//                       menubar: false,
//                       content_style: "body img{ width: 100% }",
//                       toolbar:
//                         "undo redo | styleselect | formatselect | bold italic backcolor | \
//                          alignleft aligncenter alignright alignjustify | \
//                          bullist numlist outdent indent | removeformat | table tabledelete | \
//                          tableprops tablerowprops tablecellprops | \
//                          tableinsertrowbefore tableinsertrowafter tabledeleterow | \
//                          tableinsertcolbefore tableinsertcolafter tabledeletecol | help",
//                     }}
//                   />
//                 </React.Fragment>
//                 {/* {Parser().parse(htmlContent)} */}
//               </div>
//               <div className="button_footer">
//                 <button
//                   onClick={viewcancel}
//                   className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
//                 >
//                   Cancel
//                 </button>
//                 {DownLoader == true ? (
//                   <button class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
//                     Loading...
//                   </button>
//                 ) : (
//                   <button
//                     onClick={documentDownloader}
//                     class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
//                   >
//                     Download
//                   </button>
//                 )}
//               </div>
//             </div>
//           </div>
//         }
//       </div>
//     </div>
//   );
// };

const Step2 = () => {

  const [Country, setCountry] = useState(null)
  if (Country != null) {
    localStorage.setItem("Country", Country)
  }
  return (
    <div className="step step-1">
      <div className="step-content">
        <div className="container">
          <div className="form_ui">
            <div className="form_cls">
              <div class="mb-4">
                <label
                  for="item-name"
                  class="mb-2 block font-display text-jacarta-700 dark:text-white"
                >
                  Country<span class="text-red"> </span>
                </label>
                <input
                  type="text"
                  id="item-name"
                  value={Country}
                  name="Country"
                  maxLength={20}
                  onChange={(e) => setCountry(e.target.value)}
                  class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:placeholder:text-jacarta-300"
                  placeholder="Enter Country"
                />
                <div>
                  {Country == true ? (
                    <p style={{ color: "red" }}>Country is required</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Step3 = () => {

  const [state, setstate] = useState(null)
  if (state != null) {
    localStorage.setItem("state", state)
  }

  return (
    <div className="step step-1">
      <div className="step-content">
        <div className="container">
          <div className="form_ui">
            <div className="form_cls">
              <div class="mb-4">
                <label
                  for="item-name"
                  class="mb-2 block font-display text-jacarta-700 dark:text-white"
                >
                  State<span class="text-red"> </span>
                </label>
                <input
                  type="text"
                  id="item-name"
                  value={state}
                  name="Country"
                  maxLength={20}
                  onChange={(e) => setstate(e.target.value)}
                  class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:placeholder:text-jacarta-300"
                  placeholder="Enter State"
                />
                <div>
                  {state == true ? (
                    <p style={{ color: "red" }}>State is required</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Step4 = () => {

  const [document, setdocument] = useState(null)
  if (document != null) {
    localStorage.setItem("document", document)
  }
  return (
    <div className="step step-1">
      <div className="step-content">
        <div className="container">
          <div className="form_ui">
            <div class="mb-4">
              <label
                for="item-name"
                class="mb-2 block font-display text-jacarta-700 dark:text-white"
              >
                Type of Template<span class="text-red"></span>
              </label>
              <input
                type="text"
                id="item-name"
                value={document}
                name="Country"
                maxLength={1000}
                onChange={(e) => setdocument(e.target.value)}
                class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:placeholder:text-jacarta-300"
                placeholder="Enter Document type"
              />
              <div>
                {document == true ? (
                  <p style={{ color: "red" }}>Document type is required</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Step5 = () => {

  const [Loader, setLoader] = useState(false)
  const [htmlContent, sethtmlContent] = useState("")
  
  var Country = localStorage.getItem("Country")
  var state = localStorage.getItem("state")
  var document = localStorage.getItem("document")
  const [DownLoader, setDownLoader] = useState(false);
  const [downloadOption, setdownloadOption] = useState(false);

  const documentDownloader = async () => {
    try {
      // console.log(htmlContent,"htmlContent")
      if (htmlContent != "") {
      // console.log(htmlContent,"htmlContent")
        
        var obj = {
          htmlContent: htmlContent,
        };
        var data = {
          apiUrl: apiService.documentDownloader,
          payload: obj,
        };
        setDownLoader(true);
        var resp = await postMethod(data);
        setDownLoader(false);
        if (resp.Status == true) {
          // console.log(resp, "=-=-=-=-=-resp");
          window.location.href = resp.data.secure_url;
          toast("Document Downloaded successfully");
          setdownloadOption(false);
        }
      } else {
        // console.log(htmlContent, "=-=-=-=-htmlContent");
      }
    } catch (error) { 
      // console.log(error,"error")

    }
  };

  const history = useNavigate();

  const documentDownloader1 = async () =>{
    const documentname = localStorage.getItem("document");
    const FileNames = documentname + ".docx";
    // console.log(FileNames,"FileNames");
      const obj = {
        shortContent: htmlContent,
        uploadStatus: true,
        viewStatus: true,
        fileName: FileNames,
        fileext: 'document',
        text: htmlContent,
      };
      history('/contracts', { state: { downloadedData: obj } });
      localStorage.removeItem("document")

  }

  
  const generateAI = async () => {
    try {
      if (
        Country != null &&
        state != null &&
        document != null
      ) {
        var content = `generate a legal document template for ${document}, for country ${Country} and state ${state} in html format`;
        setLoader(true);
        localStorage.removeItem("Country")
        localStorage.removeItem("state")
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          {
            model: "gpt-3.5-turbo",
            messages: [
              {
                role: "user",
                content: content,
              },
            ],
            temperature: 0.7,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " +
                "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",
            },
          }
        );
        if (response) {
          setLoader(false);
          toast("Document generated successfully");
          // console.log("Response:", response.data.choices[0].message.content);
          sethtmlContent(response.data.choices[0].message.content);
          // setdownloadOption(true);
          // docxHtmlRef = response.data.choices[0].message.content;
        }
      }
    } catch (error) {
      setLoader(false);
      // console.log("An error occurred:", error.response.data);
    }
  };

  // document.onkeydown = function (e) {
  //   if (e.keyCode == 13) {
  //     // console.log("enter")
  //     generateAI()
  //   }
  // };
  return (
    <div className="step step-1">
      <div className="step-content">
        <div className="container">
          <div className="form_ui">
            {htmlContent == false ?
              <div>
                {Loader == true ? (
                  <>
                    <div className="form_ui">
                      <h2 className="mb-10 font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl text-center bold">Your document details ready<span className="animate-gradient ml-2">Click to generate Template</span></h2>
                    </div>
                    <div className="gene_button">
                      <div className="form_betwine w-100_n space_brtenn gene_button">
                        <button class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark d-block mx-auto">
                          Loading...{" "}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="form_ui">
                      <h2 className="mb-10 font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl text-center bold">Your document details ready<span className="animate-gradient ml-2">Click to generate Template</span></h2>
                    </div>
                    <div className="gene_button">
                      <div className="form_betwine w-100_n space_brtenn gene_button">
                        <button
                          class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark d-block mx-auto"
                          onClick={generateAI}
                        >
                          Generate{" "}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
              :
              <div className="container editor_value" >
                <div className="mt-5">
                  <div className="mt-5">
                    <h2 className="mb-10 font-display text-center text-4xl font-medium text-jacarta-700 dark:text-white bold">
                      Generated Document
                    </h2>
                  </div>
                  <div>
                    <React.Fragment>
                      <Editor
                      apiKey='1q31mvbn8z72qjyalrqpcjnrx3ge16jscq0lpfxtiue7pi0t'
                        value={htmlContent}
                        init={{
                          height: "50vh",
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen powerpaste casechange",
                            "directionality advcode visualchars mediaembed codesample pagebreak",
                            "nonbreaking tableofcontents checklist tinymcespellchecker editimage",
                            "formatpainter permanentpen linkchecker emoticons advtable export autosave",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          menubar: false,
                          content_style: "body img{ width: 100% }",
                          toolbar:
                            "undo redo | styleselect | formatselect | bold italic backcolor | \
                         alignleft aligncenter alignright alignjustify | \
                         bullist numlist outdent indent | removeformat | table tabledelete | \
                         tableprops tablerowprops tablecellprops | \
                         tableinsertrowbefore tableinsertrowafter tabledeleterow | \
                         tableinsertcolbefore tableinsertcolafter tabledeletecol | help",
                        }}
                      />
                    </React.Fragment>
                    {/* {Parser().parse(htmlContent)} */}
                  </div>
                  <div className="button_footer">
                  {DownLoader == true ? (
                  <button class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                    Loading...
                  </button>
                ) : (
                  <button
                    onClick={documentDownloader1}
                    class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  >
                    Upload Document to Contract
                  </button>
                )}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;


