export const formats = new Array(
    ".png",
    ".jpg",
    ".jpeg",
    ".gif",
    ".tiff",
    ".psd",
    ".pdf",
    ".eps",
    ".ai",
    ".indd",
    ".raw",
    "webm",
    "mpg",
    "mp2",
    ".mpeg",
    ".mpe",
    ".mpv",
    ".ogg",
    ".md4",
    ".m4p",
    ".m4v",
    ".avi",
    ".wmv",
    ".qt",
    ".flv",
    "..swf",
    "avchd",
    ".docx",
    ".html",
    ".htm",
    ".xls",
    ".xlsx",
    ".odt",
    ".ods",
    ".ppt",
    ".pptx",
    ".txt"
  );

