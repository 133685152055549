import React, { useState, useEffect } from "react";
// import Sliderimg from "./Sliderimg";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import { getAuthToken } from "../core/lib/localStorage";

function Home() {
  const [authToken, setAuthToke] = useState("");
  const [clsname, setclsname] = useState("invisible");
  const [clsname2, setclsname2] = useState("opacity-0");

  useEffect(() => {
    let setTokend = getAuthToken();
    setAuthToke(setTokend);
  }, [0]);

  const navigate = useNavigate();

  const mobileHeaer = (value) => {
    // console.log(value, "=====value");
    if (value == "show") {
      setclsname("Ainvisible");
      setclsname2("Aopacity-0");
    } else {
      setclsname("invisible");
      setclsname2("opacity-0");
    }
  };
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const styles = {
    backgroundImage:
      "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/2764-fe0f.png)",
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };
  const login = () => {
    navigate("/login");
  };

  return (
    <>
      <header class="js-page-header fixed top-0 z-20 w-full bg-white">
        <div class="flex items-center px-6 py-3">
          <a href="/" class="shrink-0">
            <img
              src={require("../img/darklogo.png")}
              class="max-h-7 dark:hidden"
              alt="Xhibiter | NFT JustiChain"
            />
            <img
              src={require("../img/darklogo.png")}
              class="hidden max-h-7 dark:block"
              alt="Xhibiter | NFT JustiChain"
            />
          </a>

          <div
            class={`js-mobile-menu ${clsname} lg:visible fixed inset-0 z-10 ml-auto items-center mar-right_im bg-white ${clsname2} lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent`}
          >
            <div class="t-0 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6  lg:hidden">
              <a href="index.html" class="shrink-0">
                <img
                  src={require("../img/darklogo.png")}
                  class="max-h-7 dark:hidden"
                  alt="Xhibiter | NFT JustiChain"
                />
                <img
                  src={require("../img/darklogo.png")}
                  class="hidden max-h-7 dark:block"
                  alt="Xhibiter | NFT JustiChain"
                />
              </a>

              <button
                onClick={(e) => mobileHeaer("hide")}
                class="js-mobile-close group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
                aria-label="close mobile menu"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            <nav class="navbar w-full bg_color_menu">
              <ul class="flex flex-col lg:flex-row">
                {!authToken ? (
                  ""
                ) : (
                  <li class="group">
                    <Link
                      class="flex items-center justify-between py-3.5 font-display text-base font500 text-jacarta-700 hover:text-accent focus:text-accent TextPrimary dark:focus:text-accent lg:px-5"
                      to="/templateai"
                    >
                      Create AI Template
                    </Link>
                  </li>
                )}

                {!authToken ? (
                  ""
                ) : (
                  <li class="group">
                    <Link
                      class="flex items-center justify-between py-3.5 font-display text-base font500 text-jacarta-700 hover:text-accent focus:text-accent TextPrimary dark:focus:text-accent lg:px-5"
                      to="/contracts"
                    >
                      Contracts
                    </Link>
                  </li>
                )}

                <li class="group">
                  <Link
                    class="flex items-center justify-between py-3.5 font-display text-base font500 text-jacarta-700 hover:text-accent focus:text-accent TextPrimary dark:focus:text-accent lg:px-5"
                    to="/lawmarketai"
                  >
                    AI Chat
                  </Link>
                </li>

                <li class="group">
                  <Link
                    to="/templates"
                    class="flex items-center justify-between py-3.5 font-display text-base font500 text-jacarta-700 hover:text-accent focus:text-accent TextPrimary dark:focus:text-accent lg:px-5"
                  >
                    Legal Documents
                  </Link>
                </li>

                <li class="group">
                  <Link
                    to="/arbitrators"
                    class="flex items-center justify-between py-3.5 font-display text-base font500 text-jacarta-700 hover:text-accent focus:text-accent TextPrimary dark:focus:text-accent lg:px-5"
                  >
                    Arbitrators
                  </Link>
                </li>

                <li class="group">
                  <Link
                    to="/mediators"
                    class="flex items-center justify-between py-3.5 font-display text-base font500 text-jacarta-700 hover:text-accent focus:text-accent TextPrimary dark:focus:text-accent lg:px-5"
                  >
                    Mediators
                  </Link>
                </li>

                <li class="group">
                  <Link
                    to="/uploadoc"
                    class="flex items-center justify-between py-3.5 font-display text-base font500 text-jacarta-700 hover:text-accent focus:text-accent TextPrimary dark:focus:text-accent lg:px-5"
                  >
                    Digital Sign
                  </Link>
                </li>
                {!authToken ? (
                  <li class="js-nav-dropdown group relative">
                    <a
                      href="/login"
                      class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white BtnPrimary"
                    >
                      Login As
                      <i class="lg:hidden">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          class="h-4 w-4 dark:fill-white"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                        </svg>
                      </i>
                    </a>
                  </li>
                ) : (
                  ""
                )}

                {/* <li class="js-nav-dropdown group relative">
                  <a
                    href="/commingsoon"
                    class="dropdown-toggle flex items-center justify-between py-3.5 font-display text-base font500 text-jacarta-700 hover:text-accent focus:text-accent TextPrimary dark:hover:text-accent dark:focus:text-accent lg:px-5"
                    id="navDropdown-4"
                    aria-expanded="false"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    Free Legal
                    <i class="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="h-4 w-4 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </a>
                </li> */}
                {/* 
                <li class="js-nav-dropdown group relative">
                  <a
                    href="/commingsoon"
                    class="dropdown-toggle flex items-center justify-between py-3.5 font-display text-base font500 text-jacarta-700 hover:text-accent focus:text-accent TextPrimary dark:hover:text-accent dark:focus:text-accent lg:px-5"
                    id="navDropdown-1"
                    aria-expanded="false"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    Arbitrators
                    <i class="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="h-4 w-4 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </a>
                  <ul
                    class="dropdown-menu group-hover:visible lg:invisible -left-6 top-[85%] z-10 hidden grid-flow-row grid-cols-[repeat(2,_1fr)] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:opacity-100 dark:bg-jacarta-800 lg:absolute lg:!grid lg:translate-y-4 lg:py-8 lg:px-5 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 rightaurhr"
                    aria-labelledby="navDropdown-1"
                  >
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          Arbitration in India
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          e-Arbitration
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          Arbitration Model Clause
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          JustiChain Domestic Arbitration <br />
                          Rules, 2019
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-accent">
                          JustiChain Arbitrators
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          Indian Institute of Arbitration
                        </span>
                      </a>
                    </li>

                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          E-ADR
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          e-Filing
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          e-Payment
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          e-Allocation
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          e-Notices
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          e-Booking
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          e-Hearing
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          e-Proceedings
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          e-Certified Copy
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          Cloud Servers
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          Personal Dashboards
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          JustiChain - Virtual ADR Hearing
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          How to Ensure a Smooth Virtual Hearing
                        </span>
                      </a>
                    </li>
                  </ul>
                </li> */}
                {/* <li class="js-nav-dropdown group relative">
                  <a
                    href="/commingsoon"
                    class="dropdown-toggle flex items-center justify-between py-3.5 font-display text-base font500 text-jacarta-700 hover:text-accent focus:text-accent TextPrimary dark:hover:text-accent dark:focus:text-accent lg:px-5"
                    id="navDropdown-4"
                    aria-expanded="false"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    Free Legal
                    <i class="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="h-4 w-4 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </a>
                  <ul
                    class="dropdown-menu group-hover:visible lg:invisible left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:opacity-100 dark:bg-jacarta-800 lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 rightaurhr"
                    aria-labelledby="navDropdown-4"
                  >
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          Facilities
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          What We Do
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          Booking
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 TextPrimary">
                          Hearing Rooms
                        </span>
                      </a>
                    </li>
                  </ul>
                </li> */}
                {/* <li class="js-nav-dropdown group relative">
                  <a
                    href="/commingsoon"
                    class="dropdown-toggle flex items-center justify-between py-3.5 font-display text-base font500 text-jacarta-700 hover:text-accent focus:text-accent TextPrimary dark:hover:text-accent dark:focus:text-accent lg:px-5"
                    id="navDropdown-4"
                    aria-expanded="false"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    Demo Graphic
                    <i class="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="h-4 w-4 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </a>
                </li> */}
                {/* <li class="js-nav-dropdown group relative">
                  <a
                    href="#"
                    class="dropdown-toggle flex items-center justify-between py-3.5 font-display text-base font500 text-jacarta-700 hover:text-accent focus:text-accent TextPrimary dark:hover:text-accent dark:focus:text-accent lg:px-5"
                    id="navDropdown-4"
                    aria-expanded="false"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    Services
                    <i class="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="h-4 w-4 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </a>
                </li> */}

                {!authToken ? (
                  ""
                ) : localStorage.getItem("type") == "advocate" ? (
                  <li class="group">
                    <Link
                      class="flex items-center justify-between py-3.5 font-display text-base font500 text-jacarta-700 hover:text-accent focus:text-accent TextPrimary dark:hover:text-accent dark:focus:text-accent lg:px-5"
                      to="/create"
                    >
                      Create
                    </Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </nav>

            {/* <div class="mt-10 w-full lg:hidden">
              <a
                href="#"
                class="js-wallet block w-full rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                data-bs-toggle="modal"
                data-bs-target="#walletModal"
              >
                Connect Wallet
              </a>

              <hr class="my-5 h-px border-0 bg-jacarta-100 dark:bg-jacarta-600" />

              <div class="flex items-center justify-center space-x-5">
                <a href="#" class="group">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="facebook"
                    class="h-5 w-5 fill-jacarta-300 group-hover:fill-accent dark:group-hover:fill-white"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
                  </svg>
                </a>
                <a href="#" class="group">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="twitter"
                    class="h-5 w-5 fill-jacarta-300 group-hover:fill-accent dark:group-hover:fill-white"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                  </svg>
                </a>
                <a href="#" class="group">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="discord"
                    class="h-5 w-5 fill-jacarta-300 group-hover:fill-accent dark:group-hover:fill-white"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path>
                  </svg>
                </a>
                <a href="#" class="group">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="instagram"
                    class="h-5 w-5 fill-jacarta-300 group-hover:fill-accent dark:group-hover:fill-white"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                  </svg>
                </a>
                <a href="#" class="group">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="tiktok"
                    class="h-5 w-5 fill-jacarta-300 group-hover:fill-accent dark:group-hover:fill-white"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"></path>
                  </svg>
                </a>
              </div>
            </div> */}

            {!authToken ? (
              " "
            ) : (
              <div class="hidden lg:flex ">
                <Link to="/checkout">
                  <div class="js-nav-dropdown group-dropdown relative">
                    <button
                      class="dropdown-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent Bgprimary"
                      // id="profileDropdown"
                      // aria-expanded="false"
                      // data-bs-toggle="dropdown"
                      aria-label="profile"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="bi bi-cart-check-fill h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white color_blsce"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
                      </svg>
                    </button>

                    {/* <div
                    class="dropdown-menu group-dropdown-hover:visible lg:invisible !-right-4 !top-[85%] !left-auto z-10 hidden min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full group-dropdown-hover:opacity-100 dark:bg-jacarta-800 lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl"
                    aria-labelledby="profileDropdown"
                  >
                    <div className="cart_menu flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600 w-100_n">
                      <div className="image">
                        <img
                          src="https://res.cloudinary.com/demo/image/upload/single_page_pdf.jpg"
                          alt="item 1"
                          class="swiper-lazy h-[430px] w-full object-cover"
                        />
                      </div>
                      <div className="contant">
                        <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                          devorce template
                        </span>
                        <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                          Lawer name
                        </span>
                      </div>
                      <div className="close">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                        >
                          <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="cart_menu flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600 w-100_n">
                      <div className="image">
                        <img
                          src="https://res.cloudinary.com/demo/image/upload/single_page_pdf.jpg"
                          alt="item 1"
                          class="swiper-lazy h-[430px] w-full object-cover"
                        />
                      </div>
                      <div className="contant">
                        <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                          devorce template
                        </span>
                        <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                          Lawer name
                        </span>
                      </div>
                      <div className="close">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                        >
                          <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="cart_menu flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600 w-100_n">
                      <div className="image">
                        <img
                          src="https://res.cloudinary.com/demo/image/upload/single_page_pdf.jpg"
                          alt="item 1"
                          class="swiper-lazy h-[430px] w-full object-cover"
                        />
                      </div>
                      <div className="contant">
                        <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                          devorce template
                        </span>
                        <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                          Lawer name
                        </span>
                      </div>
                      <div className="close">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                        >
                          <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"></path>
                        </svg>
                      </div>
                    </div>
                    <button className="cart_menu flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600 w-100_n w-100_n font_size_16">
                      View All
                    </button>
                  </div> */}
                  </div>
                </Link>

                {!authToken ? (
                  ""
                ) : (
                  <div class="js-nav-dropdown group-dropdown relative">
                    <button
                      class="dropdown-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 transition-colors hover:border-transparent focus:border-transparent Bgprimary focus:bg-accent dark:border-transparent "
                      id="profileDropdown"
                      aria-expanded="false"
                      data-bs-toggle="dropdown"
                      aria-label="profile"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                      </svg>
                    </button>
                    <div
                      class="dropdown-menu group-dropdown-hover:visible lg:invisible !-right-4 !top-[85%] !left-auto z-10 hidden min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full group-dropdown-hover:opacity-100 dark:bg-jacarta-800 lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl"
                      aria-labelledby="profileDropdown"
                    >
                      {localStorage.getItem("type") == "client" ? (
                        <>
                          <a
                            href="/clientProfilers"
                            class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors  focus:text-accent"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
                            </svg>
                            <Link to="/clientProfilers">
                              <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                                My Profile
                              </span>
                            </Link>
                          </a>

                          <a
                            href="/ClientEditProfile"
                            class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors  focus:text-accent"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M9.954 2.21a9.99 9.99 0 0 1 4.091-.002A3.993 3.993 0 0 0 16 5.07a3.993 3.993 0 0 0 3.457.261A9.99 9.99 0 0 1 21.5 8.876 3.993 3.993 0 0 0 20 12c0 1.264.586 2.391 1.502 3.124a10.043 10.043 0 0 1-2.046 3.543 3.993 3.993 0 0 0-3.456.261 3.993 3.993 0 0 0-1.954 2.86 9.99 9.99 0 0 1-4.091.004A3.993 3.993 0 0 0 8 18.927a3.993 3.993 0 0 0-3.457-.26A9.99 9.99 0 0 1 2.5 15.121 3.993 3.993 0 0 0 4 11.999a3.993 3.993 0 0 0-1.502-3.124 10.043 10.043 0 0 1 2.046-3.543A3.993 3.993 0 0 0 8 5.071a3.993 3.993 0 0 0 1.954-2.86zM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg>
                            <Link to="/ClientEditProfile">
                              <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                                Edit Profile
                              </span>
                            </Link>
                          </a>
                        </>
                      ) : (
                        <>
                          <a
                            href="/profile"
                            class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors  focus:text-accent"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
                            </svg>
                            <Link to="/profile">
                              <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                                My Profile
                              </span>
                            </Link>
                          </a>

                          <a
                            href="/editprofile"
                            class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors focus:text-accent"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              
                              width="24"
                              height="24"
                              class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
                            </svg>
                            <Link to="/editprofile">
                              <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                                Edit Profile
                              </span>
                            </Link>
                          </a>
                        </>
                      )}

                      <a
                        onClick={logout}
                        class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors focus:text-accent"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 11V8l-5 4 5 4v-3h8v-2H7z" />
                        </svg>
                        <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                          Sign out
                        </span>
                      </a>
                    </div>
                  </div>
                )}

                {/* <a
                  href="#"
                  class="js-dark-mode-trigger group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="dark-mode-light h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="dark-mode-dark hidden h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
                  </svg>
                </a> */}
              </div>
            )}
          </div>

          <div class="ml-auto flex lg:hidden mr-0">
            {!authToken ? (
              ""
            ) : (
              <>
                <div class="js-nav-dropdown group-dropdown relative">
                  <button
                    class="dropdown-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
                    id="profileDropdown"
                    aria-expanded="false"
                    data-bs-toggle="dropdown"
                    aria-label="profile"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                    </svg>
                  </button>
                  <div
                    class="dropdown-menu group-dropdown-hover:visible lg:invisible !-right-4 !top-[85%] !left-auto z-10 hidden min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full group-dropdown-hover:opacity-100 dark:bg-jacarta-800 lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl"
                    aria-labelledby="profileDropdown"
                  >
                    {localStorage.getItem("type") == "client" ? (
                      <>
                        <a
                          href="/clientProfilers"
                          class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors focus:text-accent"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
                          </svg>
                          <Link to="/clientProfilers">
                            <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                              My Profile
                            </span>
                          </Link>
                        </a>

                        <a
                          href="/ClientEditProfile"
                          class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors focus:text-accent"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M9.954 2.21a9.99 9.99 0 0 1 4.091-.002A3.993 3.993 0 0 0 16 5.07a3.993 3.993 0 0 0 3.457.261A9.99 9.99 0 0 1 21.5 8.876 3.993 3.993 0 0 0 20 12c0 1.264.586 2.391 1.502 3.124a10.043 10.043 0 0 1-2.046 3.543 3.993 3.993 0 0 0-3.456.261 3.993 3.993 0 0 0-1.954 2.86 9.99 9.99 0 0 1-4.091.004A3.993 3.993 0 0 0 8 18.927a3.993 3.993 0 0 0-3.457-.26A9.99 9.99 0 0 1 2.5 15.121 3.993 3.993 0 0 0 4 11.999a3.993 3.993 0 0 0-1.502-3.124 10.043 10.043 0 0 1 2.046-3.543A3.993 3.993 0 0 0 8 5.071a3.993 3.993 0 0 0 1.954-2.86zM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                          </svg>
                          <Link to="/ClientEditProfile">
                            <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                              Edit Profile
                            </span>
                          </Link>
                        </a>
                      </>
                    ) : (
                      <>
                        <a
                          href="/profile"
                          class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors focus:text-accent"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
                          </svg>
                          <Link to="/profile">
                            <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                              My Profile
                            </span>
                          </Link>
                        </a>

                        <a
                          href="/editprofile"
                          class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
                          </svg>
                          <Link to="/editprofile">
                            <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                              Edit Profile
                            </span>
                          </Link>
                        </a>
                      </>
                    )}

                    <a
                      onClick={logout}
                      class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 11V8l-5 4 5 4v-3h8v-2H7z" />
                      </svg>
                      <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                        Sign out
                      </span>
                    </a>
                  </div>
                </div>
                <Link to="/checkout">
                  <div class="js-nav-dropdown group-dropdown relative">
                    <button
                      class="dropdown-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
                      // id="profileDropdown"
                      // aria-expanded="false"
                      // data-bs-toggle="dropdown"
                      aria-label="profile"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="bi bi-cart-check-fill h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white color_blsce"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
                      </svg>
                    </button>

                    {/* <div
                    class="dropdown-menu group-dropdown-hover:visible lg:invisible !-right-4 !top-[85%] !left-auto z-10 hidden min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full group-dropdown-hover:opacity-100 dark:bg-jacarta-800 lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl"
                    aria-labelledby="profileDropdown"
                  >
                    <div className="cart_menu flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600 w-100_n">
                      <div className="image">
                        <img
                          src="https://res.cloudinary.com/demo/image/upload/single_page_pdf.jpg"
                          alt="item 1"
                          class="swiper-lazy h-[430px] w-full object-cover"
                        />
                      </div>
                      <div className="contant">
                        <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                          devorce template
                        </span>
                        <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                          Lawer name
                        </span>
                      </div>
                      <div className="close">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                        >
                          <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="cart_menu flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600 w-100_n">
                      <div className="image">
                        <img
                          src="https://res.cloudinary.com/demo/image/upload/single_page_pdf.jpg"
                          alt="item 1"
                          class="swiper-lazy h-[430px] w-full object-cover"
                        />
                      </div>
                      <div className="contant">
                        <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                          devorce template
                        </span>
                        <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                          Lawer name
                        </span>
                      </div>
                      <div className="close">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                        >
                          <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="cart_menu flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600 w-100_n">
                      <div className="image">
                        <img
                          src="https://res.cloudinary.com/demo/image/upload/single_page_pdf.jpg"
                          alt="item 1"
                          class="swiper-lazy h-[430px] w-full object-cover"
                        />
                      </div>
                      <div className="contant">
                        <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                          devorce template
                        </span>
                        <span class="mt-1 font-display text-sm text-jacarta-700 TextPrimary">
                          Lawer name
                        </span>
                      </div>
                      <div className="close">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                        >
                          <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"></path>
                        </svg>
                      </div>
                    </div>
                    <button className="cart_menu flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600 w-100_n w-100_n font_size_16">
                      View All
                    </button>
                  </div> */}
                  </div>
                </Link>
              </>
            )}

            {/* <a
              href="#"
              class="js-dark-mode-trigger group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="dark-mode-light h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="dark-mode-dark hidden h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
              </svg>
            </a> */}

            <button
              onClick={(e) => mobileHeaer("show")}
              class="js-mobile-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
              aria-label="open mobile menu"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
              </svg>
            </button>
          </div>
          {!authToken ? (
            ""
          ) : (
            <></>
          )}        
        </div>
      </header>
    </>
  );
}

export default Home;
