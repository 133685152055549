import React, { useEffect, useState } from "react";
import SocialLogin, { EvmRpc } from "@web5nexus/sociallogin";
import useStateref from "react-usestateref";
import { ethers } from "ethers";
var CryptoJS = require("crypto-js");


const clientId =
  "BJsReIM95qRuNSu9Z7Ww7OCz6nWE13VcXpf8Tt0DVnaVmAZUoYAU8Ny9UK_zvat5ALqgEgiSyzQho0GN4V5hxxA"; // get from https://dashboard.web3auth.io
const clientSecret =
  "1d28736155dc12945f84ebe7737f53e7db38806667f0a3582dcc2ba69f01f099";
const name = "JustiChain";
const logo = "https://res.cloudinary.com/dy4xrc6jp/image/upload/v1719685772/lbt_wjaoog.png";
const network = "sapphire_mainnet";

function WalletConnect({ setNexusSdk }) {
  const [socialLoginSdk, setSocialLoginSdk] = useState(null);
  const [showWalletInitial, setShowWalletInitial] = useState(false);
  const login = async () => {
    const whiteLabel = {
      name: name,
      logo: logo,
    };
    const params = {
      type: "web3auth",
      clientId: clientId,
      clientSecret: clientSecret,
    };

    const sdk = new SocialLogin(params, whiteLabel);

    const supportedLogin = [
      "email-passwordless",
      "google",
      "github",
      "facebook",
    ];
    await sdk.init(network, supportedLogin);

    if (showWalletInitial === false) {
      console.log("showed");
      sdk.showWallet();
      setShowWalletInitial(true);


    }

    setSocialLoginSdk(sdk);
  };
  useEffect(() => {

            if (socialLoginSdk === null) {
          // console.log("clicked");
          login(true);
        }
    
    const interval = setInterval(async () => {
      const connectWallet = async () => {
        console.log({ socialLoginSdk });

        console.log(
          socialLoginSdk?.web3auth?.connected,
          "socialLoginSdk?.web3auth?.connected"
        );

        if (socialLoginSdk?.provider && socialLoginSdk?.web3auth?.connected) {
          clearInterval(interval);

          let userInfo = await socialLoginSdk.getUserInfo();

          const rpc = new EvmRpc(socialLoginSdk?.provider);
          console.log("SHow provider", socialLoginSdk);
          const address = await rpc.getAccounts();

          const balance = await rpc.getBalance();

          const privateKey = await rpc.getPrivateKey();
          console.log({ address, privateKey });

          // localStorage.setItem("token", userInfo.idToken);
          // localStorage.setItem(
          //   "userProvider",
          //   C.stringify(socialLoginSdk)
          // );
          var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(privateKey), 'CRYPTO_EXCHANGE').toString();

          localStorage.setItem("Info", ciphertext);

          socialLoginSdk.hideWallet();

          console.log("userInfo.email", userInfo.email);

          setNexusSdk(socialLoginSdk, { ...userInfo, address, balance });

          console.log(socialLoginSdk, "connected boy", userInfo);
        }

      };
      connectWallet();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [socialLoginSdk]);

  return (
    <div>
      <div style={{ width: "100%", margin: "auto" }}>
        <div
          style={{
            width: "100%",
            margin: "auto",
          }}
          className="d-flex justify-content-center"
        ></div>
      </div>
    </div>
  );
}

export default WalletConnect;
