import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import useStateRef from "react-usestateref";
import validator from "validator";
import apiService from "../core/service/v1/index";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { PhoneInput } from "react-contact-number-input";

function Home() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const navigate = useNavigate();
  const styles = {
    backgroundImage:
      "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/2764-fe0f.png)",
  };

  // const [selectedCountry, setSelectedCountry] = useState("");
  // const [selectedState, setSelectedState] = useState("state");
  // const [selectedCity, setSelectedCity] = useState("city");

  // useEffect(() => {
  //   const getCountries = async () => {
  //     try {
  //       const result = await Country.getAllCountries();
  //       let allCountries = [];
  //       allCountries = result?.map(({isoCode, name}) => ({
  //         isoCode,
  //         name,
  //       }));
  //       const [{name: firstCountry} = {}] = allCountries;
  //       setCountries(allCountries);
  //       setSelectedCountry(firstCountry);
  //     } catch (error) {
  //       setCountries([]);
  //     }
  //   };
  //   getCountries();
  // }, []);
  // useEffect(() => {
  //   const getStates = async () => {
  //     try {
  //       const result = await State.getStatesOfCountry(selectedCountry);
  //       let allStates = [];
  //       allStates = result?.map(({isoCode, name}) => ({
  //         isoCode,
  //         name,
  //       }));
  //       const [{isoCode: firstState = ""} = {}] = allStates;
  //       setCities([]);
  //       setSelectedCity("");
  //       setStates(allStates);
  //       setSelectedState(firstState);
  //     } catch (error) {
  //       setStates([]);
  //       setCities([]);
  //       setSelectedCity("");
  //     }
  //   };
  //   getStates();
  // }, [selectedCountry]);
  // useEffect(() => {
  //   const getCities = async () => {
  //     try {
  //       const result = await City.getCitiesOfState(
  //         selectedCountry,
  //         selectedState
  //       );
  //       let allCities = [];
  //       allCities = result?.map(({name}) => ({
  //         name,
  //       }));
  //       const [{name: firstCity = ""} = {}] = allCities;
  //       setCities(allCities);
  //       setSelectedCity(firstCity);
  //     } catch (error) {
  //       setCities([]);
  //     }
  //   };
  //   getCities();
  // }, [selectedState]);

  const value = {
    FullName: "",
    Email: "",
    Password: "",
    Conpassword: "",
    // EnrollmentNumber: "",
    Expertise: "",
    StartedYear: "",
    // Pincode: "",
  };

  const [inputValue, setinputValue] = useState(value);
  const [getGender, setgetGender, getGenderref] = useStateRef("Select Gender");
  const [getState, setgetState, getStateref] = useStateRef("Select State");
  const [FullNameErr, setFullNameErr] = useState(false);
  const [EmailErr, setEmailErr, EmailErrref] = useStateRef(false);
  const [PasswordErr, setPasswordErr, PasswordErrref] = useStateRef(false);
  const [ConpasswordErr, setConpasswordErr] = useState(false);
  const [mobileInput, setmobileInput] = useState("");
  // const [EnrollmentNumberErr, setEnrollmentNumberErr] = useState(false);
  // const [ExperienceErr, setExperienceErr] = useState(false);
  const [numberErr, setnumberErr] = useState("");
  // const [PincodeErr, setPincodeErr] = useState(false);
  const [experienceHide, setexperienceHide, experienceHideref] =
    useStateRef(false);
  // const [CityErr, setCityErr] = useState(false);
  // const [stateErr, setstateErr] = useState(false);
  // const [countryErr, setcountryErr] = useState(false);
  const [genderErr, setgenderErr] = useState(false);
  const [Expertise, setExpertise] = useState(false);
  const [StartedYear, setStartedYear] = useState(false);
  const [validateErr, setvalidateErr] = useState("");
  // const [experience, setexperience] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  // const [countries, setCountries] = useState([]);
  // const [states, setStates] = useState([]);
  // const [cities, setCities] = useState([]);
  const [arbitrator, setarbitrator, arbitratorref] = useStateRef(false);
  const [mediator, setmediator, mediatorref] = useStateRef(false);

  const getValue = (e) => {
    const { name, value } = e.target;
    const formData = { ...inputValue, ...{ [name]: value } };
    setinputValue(formData);
  };

  const genderValue = (value) => {
    setgetGender(value);
    // console.log(value, "=-=-=");
    // console.log(getGenderref.current, "=-=-=");
  };

  const Value = (value) => {
    setgetState(value);
    // console.log(value, "=-=-=");
    // console.log(getStateref.current, "=-=-=");
  };

  const checkboxValue = (value) => {
    // console.log(value, "=-=-=");
    if (value == false) {
      setarbitrator(true);
    } else {
      setarbitrator(false);
    }
  };
  const checkbox2Value = (value) => {
    // console.log(value, "=-=2-=");
    if (value == false) {
      setmediator(true);
    } else {
      setmediator(false);
    }
  };

  const handleOnChange = (type) => {
    // console.log(type, "=-=-=-=-=");
    var number = type.countryCode + " " + type.phoneNumber;
    setmobileInput(number);
    setnumberErr(type.message);
  };

  // const getExperience = (e) => {
  //   // console.log(e.target.value, "====");
  //   setexperience(e.target.value);
  //   if (e.target.value > 14) {
  //     setexperienceHide(true);
  //   } else {
  //     setexperienceHide(false);
  //   }
  // };

  const formSubmit = async () => {
    try {
      // console.log(
        // selectedState,
      //   mobileInput,
      //   inputValue,
      //   "---=-=-=formSubmitformSubmit-=-=-="
      // );
      // console.log(getStateref.current, "---=-=-=getStateref.current-=-=-=");
      // console.log(getGenderref.current, "---=-=-=getGenderref.current-=-=-=");

      validation();

      const d = new Date();
      let year = d.getFullYear();
      // console.log(year, "current year");

      if (
        inputValue.FullName != "" &&
        inputValue.FullName.length > 2 &&
        inputValue.FullName.length < 19 &&
        inputValue.Email != "" &&
        inputValue.Password != "" &&
        inputValue.Password == inputValue.Conpassword &&
        EmailErrref.current == false &&
        PasswordErrref.current == false &&
        // inputValue.EnrollmentNumber != "" &&
        // inputValue.Pincode != "" &&
        mobileInput != "null null" &&
        inputValue.StartedYear != "" &&
        getGenderref.current != "Select Gender"
      ) {
        const experience = year - inputValue.StartedYear;
        // console.log(experience, "experienceexperience");
        inputValue["Gender"] = getGenderref.current;
        inputValue["Experience"] = experience;
        inputValue["Arbitrator"] = arbitratorref.current;
        inputValue["Mediator"] = mediatorref.current;
        inputValue["Mobile"] = mobileInput;
        // inputValue["Country"] = selectedCountry;
        // inputValue["State"] = selectedState;
        // inputValue["City"] = selectedCity;
        // console.log("---=-=-=-=-=-=", inputValue);
        var data = {
          apiUrl: apiService.advacateregister,
          payload: inputValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.Status == true) {
          toast.success(resp.Message);
          navigate("/login");
        } else {
          toast.error(resp.Message);
        }
      } else {
      }
    } catch (error) {}
  };

  const validation = () => {
    const error = {};

    if (inputValue.FullName == "") {
      error.FullName = "FullName is Required";
      setFullNameErr(true);
    } else if (inputValue.FullName.length < 3) {
      error.FullName = "Enter atleast 3 laters";
      setFullNameErr(true);
    } else if (inputValue.FullName.length > 20) {
      error.FullName = "Enter 20 laters only";
      setFullNameErr(true);
    }

    if (inputValue.Expertise == "") {
      error.Expertise = "Expertise is Required";
      setExpertise(true);
    }

    // if (experience == "") {
    //   setExperienceErr(true)
    // } else {
    //   setExperienceErr(false)
    // }

    if (inputValue.StartedYear == "") {
      error.StartedYear = "StartedYear is Required";
      setStartedYear(true);
    }
    if (inputValue.Email == "") {
      error.Email = "Email is Required";
      setEmailErr(true);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputValue.Email)
    ) {
      error.Email = "Invalid Email address";
      setEmailErr(true);
    } else {
      setEmailErr(false);
    }
    if (inputValue.Password == "") {
      error.Password = "Password is Required";
      setPasswordErr(true);
    } else if (
      validator.isStrongPassword(inputValue.Password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setPasswordErr(false);
    } else {
      error.Password = "Enter strong password";
      setPasswordErr(true);
    }
    if (inputValue.Password != inputValue.Conpassword) {
      error.Conpassword = "Password and confirm password not match";
      setConpasswordErr(true);
    }
    if (inputValue.Conpassword == "") {
      error.Conpassword = "Confirm password is Required";
      setConpasswordErr(true);
    }
    // if (inputValue.EnrollmentNumber == "") {
    //   error.EnrollmentNumber = "Enrollment Number is Required";
    //   setEnrollmentNumberErr(true);
    // }
    // if (selectedCity == "") {
    //   setCityErr(true);
    // }
    // if (selectedState == "") {
    //   setstateErr(true);
    // } else {
    //   setstateErr(false);
    // }
    // if (selectedCountry == "") {
    //   setcountryErr(true);
    // } else {
    //   setcountryErr(false);
    // }
    // if (selectedCity == "city") {
    //   error.City = "City is Required";
    //   setCityErr(true);
    // }
    // if (inputValue.Pincode == "") {
    //   error.Pincode = "Pincode is Required";
    //   setPincodeErr(true);
    // }
    // if (inputValue.Address == "") {
    //   error.Address = "Address is Required";
    //   setAddressErr(true);
    // }
    if (getGenderref.current == "Select Gender") {
      setgenderErr(true);
    } else {
      setgenderErr(false);
    }
    setvalidateErr(error);
  };
  return (
    <>
      <section class="relative h-screen">
        <div class="lg:flex lg:h-full">
          <div class="relative text-center lg:w-1/2">
            <img
              src={require("../img/pexels.jpg")}
              alt="login"
              class="absolute h-full w-full object-cover"
            />
            <a href="/" class="relative inline-block py-36">
              <img
                src={require("../img/darklogo.png")}
                class="inline-block img_logo_width"
                alt="Xhibiter | NFT JustiChain"
              />
            </a>
          </div>

          <div class="relative flex  p-[10%] lg:w-1/2 login_for_pading overflow_scroll">
            <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              <img
                src={require("../img/gradient_light.jpg")}
                alt="gradient"
                class="h-full w-full"
              />
            </picture>

            <div class="w-full max-w-[25.625rem] text-center max_width_100">
              <h1 class="mb-6 font-display text-4xl text-jacarta-700 dark:text-white">
                Signup as Advocate
              </h1>

              <div className="mx-auto  md:flex wrap_s">
                <div className="mb-1  md:pr-8 w-100_n">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Full Name :
                    </label>
                    <input
                      type="text"
                      id="item-name"
                      name="FullName"
                      maxLength={20}
                      onChange={getValue}
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Full Name"
                      required
                    />
                    {FullNameErr == true ? (
                      <p style={{ color: "red" }}>{validateErr.FullName}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Email :
                    </label>
                    <input
                      type="text"
                      id="item-name"
                      name="Email"
                      maxLength={35}
                      onChange={getValue}
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Email"
                      required
                    />
                    {EmailErr == true ? (
                      <p style={{ color: "red" }}>{validateErr.Email}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Password :
                    </label>
                    <input
                      type="password"
                      id="item-name"
                      name="Password"
                      maxLength={20}
                      onChange={getValue}
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Password"
                      required
                    />
                    {PasswordErr == true ? (
                      <p style={{ color: "red" }}>{validateErr.Password}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Confirm Password :
                    </label>
                    <input
                      type="password"
                      id="item-name"
                      name="Conpassword"
                      maxLength={20}
                      onChange={getValue}
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Confirm Password"
                      required
                    />
                    {ConpasswordErr == true ? (
                      <p style={{ color: "red" }}>{validateErr.Conpassword}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Mobile :
                    </label>
                    <PhoneInput class="mobileInput" onChange={handleOnChange} />
                    <p style={{ color: "red" }}>{numberErr}</p>
                  </div>
                </div>
                {/* <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Enrollment Number :
                    </label>
                    <input
                      type="number"
                      id="item-name"
                      name="EnrollmentNumber"
                      onChange={getValue}
                      maxLength={20}
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Enrollment Number"
                      required
                    />
                    {EnrollmentNumberErr == true ? (
                      <p style={{color: "red"}}>
                        {validateErr.EnrollmentNumber}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}
                <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Gender :
                    </label>
                    <div class="dropdown my-1 cursor-pointer position_rel">
                      <div
                        class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300"
                        role="button"
                        id="item-collection"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span class="">{getGenderref.current}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          class="h-4 w-4 fill-jacarta-500 dark:fill-white"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                        </svg>
                      </div>

                      <div
                        class="dropdown-menu z-10 hidden w-full whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800"
                        aria-labelledby="item-collection"
                      >
                        <ul class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                          <li onClick={() => genderValue("Male")}>
                            <a class="dropdown-item flex w-full items-center justify-between rounded-xl px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600">
                              <span class="flex items-center space-x-3">
                                <span class="text-jacarta-700 dark:text-white">
                                  Male
                                </span>
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                class="mb-[3px] h-4 w-4 fill-accent"
                              >
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                              </svg>
                            </a>
                          </li>
                          <li onClick={() => genderValue("Female")}>
                            <a class="dropdown-item flex w-full items-center rounded-xl px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600">
                              <span class="flex items-center space-x-3">
                                <span class="text-jacarta-700 dark:text-white">
                                  Female
                                </span>
                              </span>
                            </a>
                          </li>
                          <li onClick={() => genderValue("Other")}>
                            <a class="dropdown-item flex w-full items-center rounded-xl px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600">
                              <span class="flex items-center space-x-3">
                                <span class="text-jacarta-700 dark:text-white">
                                  Other
                                </span>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      {genderErr == true ? (
                        <p style={{ color: "red" }}>Gender is required</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="reade_flex_lovatio">
                  <div className="mb-1 md:w-1/1 padine_ee_rigy">
                    <div class="mb-6">
                      <label
                        for="item-name"
                        class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                      >
                        Country
                      </label>
                      <select
                        class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300 max_width_100"
                        id="cars"
                        name="Country"
                        value={selectedCountry}
                        onChange={(event) =>
                          setSelectedCountry(event.target.value)
                        }
                      >
                        {countries.length > 0 ? (
                          countries.map(({isoCode, name}) => (
                            <option
                              class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                              value={isoCode}
                              key={isoCode}
                            >
                              {name}
                            </option>
                          ))
                        ) : (
                          <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                            No Countries Found!
                          </option>
                        )}
                      </select>
                      {countryErr == true ? (
                        <p style={{color: "red"}}>Country is required</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="mb-1 md:w-1/1 padine_ee_rigy widthu-197 ">
                    <div class="mb-6">
                      <label
                        for="item-name"
                        class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                      >
                        State
                      </label>
                      <select
                        class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300 max_width_100"
                        name="state"
                        value={selectedState}
                        onChange={(event) =>
                          setSelectedState(event.target.value)
                        }
                      >
                        {states.length > 0 ? (
                          states.map(({isoCode, name}) => (
                            <option
                              class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                              value={isoCode}
                              key={isoCode}
                            >
                              {name}
                            </option>
                          ))
                        ) : (
                          <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                            No States Found!
                          </option>
                        )}
                      </select>
                      {stateErr == true ? (
                        <p style={{color: "red"}}>State is required</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="mb-1 md:w-1/1 padine_ee_rigy">
                    <div class="mb-6">
                      <label
                        for="item-name"
                        class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                      >
                        City
                      </label>
                      <select
                        class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300 max_width_100"
                        id="cars"
                        name="city"
                        value={selectedCity}
                        onChange={(event) =>
                          setSelectedCity(event.target.value)
                        }
                      >
                        {cities.length > 0 ? (
                          cities.map(({isoCode, name}) => (
                            <option
                              class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                              value={isoCode}
                              key={isoCode}
                            >
                              {name}
                            </option>
                          ))
                        ) : (
                          <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                            No Cities Found!
                          </option>
                        )}
                      </select>
                      {CityErr == true ? (
                        <p style={{color: "red"}}>City is required</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div> */}
                {/* <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Experience
                    </label>
                    <input
                      type="number"
                      id="item-name"
                      name="Experience"
                      onChange={getExperience}
                      maxLength={3}
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Ex : 7"
                      required
                    />
                    {ExperienceErr == true ? (
                      <p style={{color: "red"}}>
                      Experience is Required
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}
                <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Expertise
                    </label>
                    <input
                      type="text"
                      id="item-name"
                      name="Expertise"
                      onChange={getValue}
                      maxLength={20}
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Ex : Criminal Lawyer"
                      required
                    />
                    {Expertise == true ? (
                      <p style={{ color: "red" }}>{validateErr.Expertise}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {experienceHideref.current == true ? (
                  <>
                    <div className="mb-1 md:w-1/2 md:pr-8">
                      <div class="mb-6">
                        <input
                          type="checkbox"
                          id="item-name"
                          name="Expertise"
                          onChange={(e) => checkboxValue(arbitratorref.current)}
                          placeholder="Ex : Criminal Lawyer"
                          required
                        />
                        <label for="item-name">Are you Arbitrator</label>
                        {/* {Expertise == true ? (
                      <p style={{color: "red"}}>{validateErr.Expertise}</p>
                    ) : (
                      ""
                    )} */}
                      </div>
                    </div>
                    <div className="mb-1 md:w-1/2 md:pr-8">
                      <div class="mb-6">
                        <input
                          type="checkbox"
                          id="item-name"
                          name="Expertise"
                          onChange={(e) => checkbox2Value(mediatorref.current)}
                          placeholder="Ex : Criminal Lawyer"
                          required
                        />
                        <label for="item-name">Are you Mediator</label>
                        {/* {Expertise == true ? (
                      <p style={{color: "red"}}>{validateErr.Expertise}</p>
                    ) : (
                      ""
                    )} */}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Started Year
                    </label>
                    <input
                      type="number"
                      id="item-name"
                      name="StartedYear"
                      onChange={getValue}
                      maxLength={20}
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Enter Started Year"
                      required
                    />
                    {StartedYear == true ? (
                      <p style={{ color: "red" }}>{validateErr.StartedYear}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Pincode
                    </label>
                    <input
                      type="number"
                      id="item-name"
                      name="Pincode"
                      onChange={getValue}
                      maxLength={20}
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Enter Pincode"
                      required
                    />
                    {PincodeErr == true ? (
                      <p style={{color: "red"}}>{validateErr.Pincode}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}
                {/* <div className="mb-0 md:w-1/1 md:pr-8 w-100_n">
                  <div class="mb-6 ">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Address
                    </label>
                    <textarea
                      id="profile-bio"
                      name="Address"
                      onChange={getValue}
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      required
                      placeholder="Address"
                    ></textarea>
                    {AddressErr == true ? (
                      <p style={{color: "red"}}>{validateErr.Address}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}
                <div className="form_betwine w-100_n space_brtenn md:pr-8 mb-6">
                  <Link
                    class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                    to="/login"
                  >
                    Login
                  </Link>
                  {buttonLoader == true ? (
                    <button class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                      Loading...{" "}
                    </button>
                  ) : (
                    <button
                      class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                      onClick={formSubmit}
                    >
                      Register{" "}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
