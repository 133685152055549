import React, { useEffect, useState } from "react";
import useStateRef from "react-usestateref";
import { PhoneInput } from "react-contact-number-input";
import ConnectWallet from "../components/WalletConnect";

import Header from "./Header";
import SelectUserType from "../components/SelectUserType";
import AdvocateSignupForm from "../components/AdvocateSignupForm";
import ClientSignupForm from "../components/ClientSignupForm";
import { env } from "../core/service/envconfig";
import { toast } from "react-toastify";



function WebNexusUser() {
  const [pageStep, setPageStep] = useState(null);
  const [getGender, setgetGender, getGenderref] = useStateRef("Select Gender");

  const [socialLoginSdk, setSocialLoginSdk] = useState(null);
  const [nexusUser, setNexusUser] = useState("")


  const setNexusSdk = (sdk, userInfo) => {
  
    setSocialLoginSdk(sdk);
    setNexusUser(userInfo)
    setPageStep("userType")
  }


  const showAdvocateOrClientSignup = ({ isUserAdvocateRegistered, isUserClientRegistered, token, decrypted, Message }) => {
    // console.log({ isUserAdvocateRegistered, isUserClientRegistered, token , decrypted})

    if(isUserAdvocateRegistered){
      localStorage.setItem("user_token", token);
      localStorage.setItem(
        "decodes_advocate",
        decrypted + "*advocates2024"
      );
      localStorage.setItem("type", "advocate");
      if (
        localStorage.getItem("user_token") != "" &&
        localStorage.getItem("user_token") != null &&
        localStorage.getItem("user_token") != undefined
      ) {
        // navigate("/profile");
        window.location.href = env.baseURL + "/profile";
      }
      toast.success(Message);
    }

    if(isUserClientRegistered){
      localStorage.setItem("user_token", token);
      localStorage.setItem("type", "client");
      localStorage.setItem(
        "decodes_client",
        decrypted + "*client2024"
      );
      if (
        localStorage.getItem("user_token") != "" &&
        localStorage.getItem("user_token") != null &&
        localStorage.getItem("user_token") != undefined
      ) {
        // navigate("/clientProfilers");
        window.location.href = env.baseURL + "/clientProfilers";
      }

      toast.success(Message);
    }

    if (isUserAdvocateRegistered === false) {
      setPageStep("advocateSignup")
    }

    if(isUserClientRegistered === false){
      setPageStep("clientSignup")
    }

  }

  useEffect(() => {
    // console.log({ pageStep }, "changed")
  }, [pageStep])

  return (
    <div>
      <header class="js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors">
        <div class="flex items-center px-6 py-6 xl:px-24">
          <a href="/" class="shrink-0">
            <img
              src={require("../img/darklogo.png")}
              class="max-h-7 dark:hidden"
              alt="Xhibiter | NFT Marketplace"
            />
            <img
              src={require("../img/darklogo.png")}
              class="hidden max-h-7 dark:block"
              alt="Xhibiter | NFT Marketplace"
            />
          </a>
        </div>
      </header>

      <main class="mt-24">
        <section class="relative pt-16 pb-24">
          {socialLoginSdk === null && <ConnectWallet setNexusSdk={setNexusSdk} />}

          <div
            className="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >

            {pageStep === "userType" && (
              <SelectUserType userEmail={nexusUser.email} userInfo={nexusUser} showAdvocateOrClientSignup={showAdvocateOrClientSignup} />
            )}
            {
              pageStep === "advocateSignup" && (
                <AdvocateSignupForm userInfo={nexusUser}/>
              )
            }
            {
              pageStep === "clientSignup" && <ClientSignupForm  userInfo={nexusUser}/>
            }


          </div>
        </section>
      </main>
    </div>
  );
}

export default WebNexusUser;
