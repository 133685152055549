export const IMAGE_URL = 'http://localhost:3033/static/';
// export const IMAGE_URL = "https://app.lawblocks.io/static/";

export const FILE_URL = "https://app.lawblocks.io/reports/"

export const UCASE = ([first, ...rest], locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join("");
export const LAWBLOCKS_CONTRACT = "0x47CEadF8B4C3C55886aD95cDEa4de4e45894cC34";
export const LAWBLOCKS_TOKEN_CONTRACT ="0x05940b2df33d6371201e7ae099ced4c363855dfe";
export const NETWORK = 50;
export const NETWORK_NAME = "Mainnet";
