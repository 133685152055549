import React, { useState, useEffect } from "react";
// import Sliderimg from "./Sliderimg";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Header from "./Header";
import { getMethod } from "../core/service/common.api";
import apiService from "../core/service/v1/index";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Cropper from "react-easy-crop";
import useStateRef from "react-usestateref";
import { Country, State, City } from "country-state-city";

function Home({
  image = false,
  style = {
    width: 120,
    height: 120,
    margin: 0,
    borderRadius: "50%",
    border: "1px solid #ddd",
  },
  label = false,
  urlUpload = "/users/set-avatar",
}) {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const styles = {
    backgroundImage:
      "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/2764-fe0f.png)",
  };

  const value = {

  };

  const [selectedCountry, setSelectedCountry] = useState("");
  const [inputValue, setinputValue] = useState(value);
  const [selectedState, setSelectedState] = useState("state");
  const [selectedCity, setSelectedCity] = useState("city");
  const [CityErr, setCityErr, CityErrref] = useStateRef(false);
  const [stateErr, setstateErr, stateErrref] = useStateRef(false);
  const [showstatusErr, setshowstatusErr, showstatusErrref] = useStateRef(false);
  const [countryErr, setcountryErr, countryErrref] = useStateRef(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const result = await Country.getAllCountries();
        let allCountries = [];
        allCountries = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));
        const [{ name: firstCountry } = {}] = allCountries;
        setCountries(allCountries);
        setSelectedCountry(firstCountry);
      } catch (error) {
        setCountries([]);
      }
    };
    getCountries();
  }, []);
  useEffect(() => {
    const getStates = async () => {
      try {
        const result = await State.getStatesOfCountry(selectedCountry);
        let allStates = [];
        allStates = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));
        const [{ isoCode: firstState = "" } = {}] = allStates;
        setCities([]);
        setSelectedCity("");
        setStates(allStates);
        setSelectedState(firstState);
      } catch (error) {
        setStates([]);
        setCities([]);
        setSelectedCity("");
      }
    };
    getStates();
  }, [selectedCountry]);
  useEffect(() => {
    const getCities = async () => {
      try {
        const result = await City.getCitiesOfState(
          selectedCountry,
          selectedState
        );
        let allCities = [];
        allCities = result?.map(({ name }) => ({
          name,
        }));
        const [{ name: firstCity = "" } = {}] = allCities;
        setCities(allCities);
        setSelectedCity(firstCity);
      } catch (error) {
        setCities([]);
      }
    };
    getCities();
  }, [selectedState]);

  const [allAdvocate, setallAdvocate] = useState([]);
  const [lawLoader, setlawLoader] = useState(false);
  const [username, setusername] = useState("");
  const [bio, setBio] = useState("");
  const [email, setEmail] = useState("");
  const [showstatus, setshowstatus] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [advateDet, setadvateDet, advateDetref] = useStateRef({});
  const [prfileIMG, setPrfileIMG] = useState("");
  const [coverIMG, setCoverIMG] = useState("");
  const [zoom, setZoom] = useState(1);
  const [loader, setLoader] = useState(false);
  const [loadeProfile, setLoaderProfile] = useState(false);

  const [usernameErr, setusernameErr] = useState(false);
  const [bioError, setbioError] = useState(false);
  const [twitterError, settwitterError] = useState(false);
  const [instagramError, setinstagramError] = useState(false);
  const [facebookError, settfbError] = useState(false);
  const [commErr, setcommErr, commErrref] = useStateRef(true);
  const [uploadingType, setUploadingType] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentFile, setcurrentFile, currentFileref] = useStateRef("");
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    getLawerDetails();
  }, [0]);

  const getLawerDetails = async (e) => {
    try {
      var data = {
        apiUrl: apiService.getAdvProfile,
      };
      // setlawLoader(true);
      var resp = await getMethod(data);
      // setlawLoader(false);
      // console.log(resp.Message);
      setadvateDet(resp.Message);
      setusername(resp.Message.fullName);
      setBio(resp.Message.bio);
      setEmail(resp.Message.email);
      setTwitter(resp.Message.twitter);
      setInstagram(resp.Message.instagram);
      setFacebook(resp.Message.facebook);
      setPrfileIMG(resp.Message.prfileIMG);
      setCoverIMG(resp.Message.coverIMG);
      setSelectedCountry(resp.Message.country);
      setSelectedState(resp.Message.state);
      setSelectedCity(resp.Message.city);
      setshowstatus(resp.Message.showstatus);


      if (resp.status == true) {
        setallAdvocate(resp.Message);
      } else {
        setallAdvocate([]);
      }
      // console.log(advateDet);
    } catch (error) {
      // console.log(error, "::::error");
    }
  };
  const croppedImageUpload = async () => {
    setSelectedImage(null);
    const fileExtension = currentFileref.current.name.split(".").at(-1);
    const fileSize = currentFileref.current.size;
    if (
      fileExtension != "jpeg" &&
      fileExtension != "png" &&
      fileExtension != "jpg"
    ) {
      toast.error("File does not support. only upload .jpeg or .png or jpg");
    } else if (fileSize > 10000000) {
      toast.error("Please upload a file smaller than 100 MB");
    } else {
      if (uploadingType == "coverPhoto") {
        setLoader(true);
      } else if (uploadingType == "profilePhoto") {
        setLoaderProfile(true);
      }
      const data = new FormData();
      data.append("file", currentFileref.current);
      data.append("upload_preset", "yckbfakp");
      data.append("cloud_name", "dapljmrfe");
      fetch("https://api.cloudinary.com/v1_1/dapljmrfe/auto/upload", {
        method: "post",
        body: data,
      })
        .then((resp) => resp.json())
        .then((data) => {
          setLoader(false);
          setLoaderProfile(false);
          if (uploadingType == "coverPhoto") {
            setCoverIMG(data.secure_url);
          }
          if (uploadingType == "profilePhoto") {
            setPrfileIMG(data.secure_url);
            // console.log(data.secure_url, "=====dataurl");
          }
        })
        .catch((err) => {
          // // console.log(err);
        });
    }
  };
  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels);

    const image = new Image();
    image.src = selectedImage;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );

      const croppedImageDataURL = canvas.toDataURL("image/jpeg");

      const croppedImage = new Image();
      croppedImage.src = croppedImageDataURL;
      croppedImage.onload = () => {
        // Do something with the cropped image (e.g., display it in an <img> element)
        // console.log("Cropped image:", croppedImage.src);
      };
      canvas.toBlob((blob) => {
        const fileName = "cropped-image.jpg"; // Specify the desired file name here
        const file = new File([blob], fileName, { type: "image/jpeg" });

        // Do something with the cropped image file (e.g., upload it, save it locally, etc.)
        // console.log("Cropped image file:", file);
        if (file) {
          return setcurrentFile(file);
        }
      }, "image/jpeg");
    };
  };

  const updateProfiles = async () => {
    try {
      const error = {};

      if (username == "") {
        setusernameErr(true);
        setcommErr(true);
      } else {
        setusernameErr(false);
        setcommErr(false);
      }
      if (bio == "") {
        setbioError(true);
        setcommErr(true);
      } else {
        setbioError(false);
        setcommErr(false);
      }
      if (twitter == "") {
        settwitterError(true);
        setcommErr(true);
      } else {
        settwitterError(false);
        setcommErr(false);
      }
      if (instagram == "") {
        setinstagramError(true);
        setcommErr(true);
      } else {
        setinstagramError(false);
        setcommErr(false);
      }
      if (facebook == "") {
        settfbError(true);
        setcommErr(true);
      } else {
        settfbError(false);
        setcommErr(false);
      }
      if (selectedCity == "") {
        setCityErr(true);
      } else {
        setCityErr(false);
      }

      if (showstatus == "") {
        setshowstatusErr(true);
      } else {
        setshowstatusErr(false);
      }

      if (selectedState == "") {
        setstateErr(true);
      } else {
        setstateErr(false);
      }
      if (selectedCountry == "") {
        setcountryErr(true);
      } else {
        setcountryErr(false);
      }
      // console.log(
      //   selectedCountry, selectedState,
      //   "=====SUBMITTED ====================="
      // );

      if (commErrref.current == false && CityErrref.current == false && stateErrref.current == false && countryErrref.current == false && showstatusErrref.current == false) {
        var obj = {
          username: username,
          bio: bio,
          twitter: twitter,
          instagram: instagram,
          facebook: facebook,
          prfileIMG: prfileIMG,
          coverIMG: coverIMG,
          Country: selectedCountry,
          State: selectedState,
          City: selectedCity,
          showstatus : showstatus
        };
        // console.log(obj, "=-=-=-=-objobjobjobj=-=-=-=-");
        var data = {
          apiUrl: apiService.updateProfile,
          payload: obj,
        };
        var resp = await postMethod(data);
        if (resp.status == true) {
          getLawerDetails();
          toast(resp.Message);
          navigate("/profile");
        } else {
          toast(resp.Message);
        }
      } else {
      }
    } catch (error) {
      // console.log(error, "::::error");
    }
  };

  // getAllAdvocates

  const imageUpload = (type, val) => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        // console.log(selectedImage, "=-=-=-=select");
      };
      if (val) {
        reader.readAsDataURL(val);
      }
      setUploadingType(type);
      // console.log(type, ":::type", ":::uploadingFile");
    } catch (error) {
      // setLoader(false);
      toast("Please try again later");
    }
  };

  return (
    <>
      <Header />
      <main className="pt-[5.5rem] lg:pt-24">
        {/* <!-- Banner --> */}

        <div class="relative cover_back">
          {coverIMG == "" || coverIMG == undefined || coverIMG == null ? (
            <img
              src={require("../img/blackbanner.png")}
              alt="banner"
              class="h-[18.75rem] object-cover"
            />
          ) : (
            <img
              src={coverIMG}
              alt="banner"
              class="h-[18.75rem] object-cover"
            />
          )}

          {loader == true ? (
            <>
              <p class="mx-auto max-w-xs text-xs dark:text-jacarta-300">
                {/* Please wait uploading... */}
                <img
                  width="60"
                  height="60"
                  class="mb-4 inline-block fill-jacarta-500 dark:fill-white"
                  src={require("../img/profileman.png")}
                ></img>
              </p>
            </>
          ) : (
            <div class="container relative -translate-y-4">
              <div class="group absolute right-0 bottom-4 flex items-center rounded-lg bg-white py-2 px-4 font-display text-sm hover:bg-accent fitcontebt">
                <input
                  type="file"
                  accept="image/*"
                  class="absolute inset-0 cursor-pointer opacity-0"
                  name="image"
                  onChange={(e) => imageUpload("coverPhoto", e.target.files[0])}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="mr-1 h-4 w-4 fill-jacarta-400 group-hover:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z"></path>
                </svg>
                <span class="mt-0.5 block group-hover:text-white">
                  Edit cover photo
                </span>
              </div>
            </div>
          )}
        </div>
        {selectedImage == null ? (
          <section class="relative py-16 dark:bg-jacarta-800 ">
            <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              <img
                src={require("../img/gradient_light.jpg")}
                alt="gradient"
                class="h-full w-full"
              />
            </picture>
            <div class="container">
              <div class="mx-auto max-w-[48.125rem] md:flex">
                <div class="mb-12 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="profile-username"
                      class="mb-1 block font-display text-sm text-jacarta-700 dark:text-white"
                    >
                      Username<span class="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="profile-username"
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent"
                      placeholder="Enter username"
                      required
                      name="username"
                      value={username}
                      onChange={(event) => setusername(event.target.value)}
                    />
                    {usernameErr == true ? (
                      <p class="text-red">User name is required!</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="mb-6">
                    <label
                      for="profile-bio"
                      class="mb-1 block font-display text-sm text-jacarta-700 dark:text-white"
                    >
                      Bio<span class="text-red">*</span>
                    </label>
                    <textarea
                      id="profile-bio"
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent"
                      required
                      placeholder="Tell the world your story!"
                      name="bio"
                      value={bio}
                      onChange={(event) => setBio(event.target.value)}
                    ></textarea>

                    {bioError == true ? (
                      <p class="text-red">Bio is required!</p>
                    ) : (
                      ""
                    )}
                  </div>


                  <div class="mb-6">
                    <label
                      for="profile-email"
                      class="mb-1 block font-display text-sm text-jacarta-700 dark:text-white"
                    >
                      Email address<span class="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="profile-email"
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent"
                      placeholder="Enter email"
                      name="email"
                      value={email}
                      disabled
                    />
                  </div>


                  {advateDetref.current.Experience >= 15 ? <>
                    <div class="mb-6">
                      <label
                        class="mb-1 block font-display text-sm text-jacarta-700 dark:text-white"
                      >
                        Arbitrator<span class="text-red">*</span>
                      </label>
                      {showstatus == "show" ?
                      <>
                      <input
                        name="showstatus"
                        type="radio"
                        value="show"
                        defaultChecked
                        onChange={(event) => setshowstatus(event.target.value)}
                      />
                      <label class="TextPrimary mr-3">Show</label>
                      </>
                      :
                      <>
                      <input
                        name="showstatus"
                        type="radio"
                        value="show"
                        onChange={(event) => setshowstatus(event.target.value)}
                      />
                      <label class="TextPrimary mr-3">Show</label>
                      </>
                      }

                      {showstatus == "hide" ?
                     <> <input
                        name="showstatus"
                        type="radio"
                        value="hide"
                        defaultChecked
                        class="ml-5"
                        onChange={(event) => setshowstatus(event.target.value)}
                      />
                      <label class="TextPrimary ml-5">Hide</label></> : 
                     <> <input
                        name="showstatus"
                        type="radio"
                        value="hide"
                        class="ml-5"
                        onChange={(event) => setshowstatus(event.target.value)}
                      />
                      <label class="TextPrimary ml-5">Hide</label></>}
                      {showstatusErr == true ? (
                        <p class="text-red">Arbitrator Status is required!</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </> :
                    <>
                    <div class="mb-6">
                      <label
                        class="mb-1 block font-display text-sm text-jacarta-700 dark:text-white"
                      >
                        Mediator<span class="text-red">*</span>
                      </label>
                      {showstatus == "show" ?
                      <><input
                        name="showstatus"
                        type="radio"
                        value="show"
                        defaultChecked
                        onChange={(event) => setshowstatus(event.target.value)}
                      />
                      <label class="TextPrimary mr-5">Show</label></>:
                      <><input
                        name="showstatus"
                        type="radio"
                        value="show"
                      onChange={(event) => setshowstatus(event.target.value)}
                      />
                      <label class="TextPrimary mr-5">Show</label></>}
                      
                      {showstatus == "hide" ?
                      <>
                      <input
                        name="showstatus"
                        type="radio"
                        value="hide"
                        class="ml-5"
                        defaultChecked
                        onChange={(event) => setshowstatus(event.target.value)}
                      />
                      <label class="TextPrimary ml-5">Hide</label>
                      </>: 
                      <> <input
                        name="showstatus"
                        type="radio"
                        value="hide"
                        class="ml-5"
                        onChange={(event) => setshowstatus(event.target.value)}
                      />
                      <label class="TextPrimary ml-5">Hide</label>
</>}
                     
                      {showstatusErr == true ? (
                        <p class="text-red">Mediator Status is required!</p>
                      ) : (
                        ""
                      )}
                    </div>
                    </>

                  }







                  <div className="">
                    <div className="mb-1 md:w-1/1 padine_ee_rigy">
                      <div class="mb-6">
                        <label
                          for="item-name"
                          class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                        >
                          Country
                        </label>
                        <select
                          class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 max_width_100"
                          id="cars"
                          name="Country"
                          value={selectedCountry}
                          onChange={(event) =>
                            setSelectedCountry(event.target.value)
                          }
                        >
                          {countries.length > 0 ? (
                            countries.map(({ isoCode, name }) => (
                              <option
                                class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                value={isoCode}
                                key={isoCode}
                              >
                                {name}
                              </option>
                            ))
                          ) : (
                            <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                              No Countries Found!
                            </option>
                          )}
                        </select>
                        {countryErr == true ? (
                          <p style={{ color: "red" }}>Country is required</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="mb-1 md:w-1/1 padine_ee_rigy">
                      <div class="mb-6">
                        <label
                          for="item-name"
                          class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                        >
                          State
                        </label>
                        <select
                          class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 max_width_100"
                          name="state"
                          value={selectedState}
                          onChange={(event) =>
                            setSelectedState(event.target.value)
                          }
                        >
                          {states.length > 0 ? (
                            states.map(({ isoCode, name }) => (
                              <option
                                class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                value={isoCode}
                                key={isoCode}
                              >
                                {name}
                              </option>
                            ))
                          ) : (
                            <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                              No States Found!
                            </option>
                          )}
                        </select>
                        {stateErr == true ? (
                          <p style={{ color: "red" }}>State is required</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="mb-1 md:w-1/1 padine_ee_rigy">
                      <div class="mb-6">
                        <label
                          for="item-name"
                          class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                        >
                          City
                        </label>
                        <select
                          class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 max_width_100"
                          id="cars"
                          name="city"
                          value={selectedCity}
                          onChange={(event) =>
                            setSelectedCity(event.target.value)
                          }
                        >
                          {cities.length > 0 ? (
                            cities.map(({ isoCode, name }) => (
                              <option
                                class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                value={isoCode}
                                key={isoCode}
                              >
                                {name}
                              </option>
                            ))
                          ) : (
                            <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                              No Cities Found!
                            </option>
                          )}
                        </select>
                        {CityErr == true ? (
                          <p style={{ color: "red" }}>City is required</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>






                  <div class="mb-6 input_pr">
                    <label
                      for="profile-twitter"
                      class="mb-1 block font-display text-sm text-jacarta-700 dark:text-white"
                    >
                      Links<span class="text-red">*</span>
                    </label>
                    <div class="relative">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="twitter"
                        class="pointer-events-none absolute top-1/2 left-4 h-4 w-4 -translate-y-1/2 fill-jacarta-300 dark:fill-jacarta-400"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                      </svg>
                      <input
                        type="text"
                        id="profile-twitter"
                        class="w-full rounded-t-lg border-jacarta-100 py-3 pl-10 hover:ring-2 hover:ring-accent/10 focus:ring-inset focus:ring-accent "
                        placeholder="Enter Twitter Link"
                        value={twitter}
                        onChange={(event) => setTwitter(event.target.value)}
                      />
                      {twitterError == true ? (
                        <p class="text-red red_text_potio">
                          Twitter link is required!
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="relative">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="instagram"
                        class="pointer-events-none absolute top-1/2 left-4 h-4 w-4 -translate-y-1/2 fill-jacarta-300 dark:fill-jacarta-400"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                      </svg>
                      <input
                        type="text"
                        id="profile-instagram"
                        class="-mt-px w-full border-jacarta-100 py-3 pl-10 hover:ring-2 hover:ring-accent/10 focus:ring-inset focus:ring-accent "
                        placeholder="Enter Instagram Link"
                        value={instagram}
                        onChange={(event) => setInstagram(event.target.value)}
                      />
                      {instagramError == true ? (
                        <p class="text-red red_text_potio">
                          Instagram link is required!
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="pointer-events-none absolute top-1/2 left-4 h-4 w-4 -translate-y-1/2 fill-jacarta-300 dark:fill-jacarta-400"
                        viewBox="0 0 24 24"
                      >
                        <path d="M14 13.5H16.5L17.5 9.5H14V7.5C14 6.47062 14 5.5 16 5.5H17.5V2.1401C17.1743 2.09685 15.943 2 14.6429 2C11.9284 2 10 3.65686 10 6.69971V9.5H7V13.5H10V22H14V13.5Z"></path>
                      </svg>

                      <input
                        type="url"
                        id="profile-website"
                        class="-mt-px w-full rounded-b-lg border-jacarta-100 py-3 pl-10 hover:ring-2 hover:ring-accent/10 focus:ring-inset focus:ring-accent "
                        placeholder="Enter Facebook Link"
                        value={facebook}
                        onChange={(event) => setFacebook(event.target.value)}
                      />
                      {facebookError == true ? (
                        <p class="text-red red_text_potio">
                          Facebook link is required!
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <button
                    onClick={updateProfiles}
                    class="rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  >
                    Update Profile
                  </button>
                </div>
                <div class="flex space-x-5 md:w-1/2 md:pl-8">
                  <form class="shrink-0">
                    {loadeProfile == true ? (
                      <img
                        width="60"
                        height="60"
                        class="mb-4 inline-block fill-jacarta-500 dark:fill-white"
                        src={require("../img/loadinggif.gif")}
                      ></img>
                    ) : (
                      <figure class="relative inline-block img_withas">
                        {prfileIMG == "" ||
                          prfileIMG == null ||
                          prfileIMG == undefined ? (
                          <img
                            src={require("../img/user/user_avatar.gif")}
                            alt="collection avatar"
                            class="rounded-xl border-[5px] border-white dark:border-jacarta-600"
                          />
                        ) : (
                          <img
                            src={prfileIMG}
                            alt="collection avatar"
                            class="rounded-xl border-[5px] border-white dark:border-jacarta-600"
                          />
                        )}

                        <div class="group absolute -right-3 -bottom-2 h-8 w-8 overflow-hidden rounded-full border border-jacarta-100 bg-white text-center hover:border-transparent hover:bg-accent">
                          <input
                            type="file"
                            accept="image/*"
                            class="absolute top-0 left-0 w-full cursor-pointer opacity-0"
                            name="image"
                            onChange={(e) =>
                              imageUpload("profilePhoto", e.target.files[0])
                            }
                          />
                          <div class="flex h-full items-center justify-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              class="h-4 w-4 fill-jacarta-400 group-hover:fill-white"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z" />
                            </svg>
                          </div>
                        </div>
                      </figure>
                    )}
                  </form>
                  <div class="mt-4">
                    <span class="mb-3 block font-display text-sm text-jacarta-700 dark:text-white">
                      Profile Image
                    </span>
                    <p class="text-sm leading-normal dark:text-jacarta-300">
                      We recommend an image of at least 300x300. Gifs work too.
                      Max 5mb.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="relative py-16 dark:bg-jacarta-800 crop_imga_contain">
            <div className="container">
              <div className="reactEasyCrop_Container">
                {uploadingType == "coverPhoto" ? (
                  <div className="crop_img">
                    <Cropper
                      image={selectedImage}
                      crop={crop}
                      zoom={zoom}
                      aspect={3}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={handleCropComplete}
                    />
                  </div>
                ) : (
                  <div className="crop_img">
                    <Cropper
                      image={selectedImage}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={handleCropComplete}
                    />
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
        {selectedImage != null ? (
          <div className="crop_imga_contain button">
            <button
              class="rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
              onClick={croppedImageUpload}
            >
              upload Image
            </button>
          </div>
        ) : (
          ""
        )}
        {/* <!-- end edit profile --> */}
      </main>

      <Footer />
    </>
  );
}

export default Home;
