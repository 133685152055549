import React, { useState, useEffect } from "react";
import Header from "../pages/Header";
import axios from "axios";
import apiService from "../core/service/v1";
import { postMethod } from "../core/service/common.api";
import { Configuration, OpenAIApi } from "openai";
import { Icons } from "react-toastify";
import Footer from "./Footer";
import useStateRef from "react-usestateref";
import { CountryDropdown } from "react-country-region-selector";
import DOMPurify from "dompurify";

function AIchatPage() {
  const [value, setvalue, valueref] = useStateRef("");
  const [replaceReq, setreplaceReq, replaceReqref] = useStateRef("");
  const [Loader, setLoader] = useState(false);
  const [divLoader, setdivLoader] = useState(false);
  const [country, setcountry] = useState("");
  const [userInput, setuserInput, userInputref] = useStateRef("");
  const [countryerr, setcountryerr] = useState(false);
  const [totalChat, settotalChat] = useState([]);

  const [respdata, setrespdata, respdataref] = useStateRef("");

  const getInputValue = (value) => {
    setreplaceReq(value);
    setrespdata(value.trim());
  };

  const selectCountry = (val) => {
    setcountry(val);
    // console.log(val)
  };

  document.onkeydown = function (e) {
    if (e.keyCode == 13) {
      // console.log("enter")
      getAIresponse()
    }
  };

  const getAIresponse = async (e) => {
    if (country != "") {
      if (respdataref.current != "") {
        setcountryerr(false)
        var inputValue = respdataref.current + " " + "legal section in" + " " + country + " " + "(In HTML format only)";
        setuserInput(inputValue);
        setcountryerr(false);
        totalChat.push({
          role: "user",
          content: userInputref.current.split('(In HTML format')[0]
        });
        setvalue(replaceReqref.current);

        setLoader(true);
        setdivLoader(true);
        setreplaceReq("");
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          {
            model: "gpt-3.5-turbo",
            messages: [
              {
                role: "user",
                content: userInputref.current,
              },
            ],
            temperature: 0.7,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + "sk-proj-2jVnBEZ1PGpO9PpN7alqT3BlbkFJQXeZnp1TiQtkqe78oIWF",
            },
          }
        );
        // console.log(response, "=-=-=-response")
        setLoader(false);
        setdivLoader(false);
        if (response) {
          var obj = {
            role: "assist",
            content: response.data.choices[0].message.content.replace(/-/g, ""),
          };
          totalChat.push(obj);
          // console.log(totalChat, '==-=-=-=-')
        }
      } else {
        setreplaceReq("");
      }
    } else {
      setcountryerr(true)
    }
  };

  const inputDetails = async (value) => {
    setrespdata(value)
    await getAIresponse()
  }

  return (
    <>
      <Header />
      <section className="section">
        <div className="container">
          <div className="chat"></div>
        </div>
      </section>
      <div className="container">
        <div className=" flex-wrap rounded-2.5xl bg-white p-10 md:flex-nowrap md:space-x-8 md:p-[4.25rem] lg:space-x-16 chat_bg">
          <div className="lg:w-[100%]">
            <div>
              <h2 className="font-display text-center text-4xl font-medium text-jacarta-700 TextPrimary">
                JustiChain AI Chat Here
              </h2>
            </div>
            <div className="AIchat">
              <div className="static_chat_content">
                <div className="height_chat">
                  {totalChat.length > 0 ?
                    <div>
                      {totalChat.map((data, i) => {
                        return (
                          <div className={`chat_user ${data.role == "user" ? "" : "bot_bg"
                            }`}>
                            <div className="user_icon">
                              <i class="ri-user-fill"></i>
                            </div>
                            <div className="msggte"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(data.content),
                              }}
                            >
                            </div>

                          </div>
                        )
                      })}
                      {divLoader == false ? (
                        ""
                      ) : (
                        <div className="chat_user bot_bg">
                          <div className="user_icon">
                            <i class="ri-user-fill"></i>
                          </div>
                          <div className="msggte">
                            <p>Loading...</p>
                          </div>
                        </div>
                      )}
                    </div>
                    :
                    <div>
                      <div className="big_head">
                        {/* <div className="icon">
                          <img
                            src={require("../img/pexels.jpg")}
                            alt="login"
                            class=" h-full w-full object-cover"
                          />
                        </div> */}
                        <div className="side_cotent">
                          <ul>
                            <li>
                              <img classname="" width="30px" src={require("../img/Aipixel.png")} />
                              Hello, I am an AI-powered bot that can answer your legal queries related to Indian Laws and give you answers relevant to your questions.
                            </li>
                            <li>
                              <img classname="" width="30px" src={require("../img/Aipixel.png")} />

                              I am not a legal advisor or lawyer. Please consult a lawyer with your query to find a solution for your legal issues.
                            </li>
                            <li>
                              <img classname="" width="30px" src={require("../img/Aipixel.png")} />

                              I can only provide a starting ground so you understand your rights better and get more information regarding your questions before consulting a lawyer.
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="default_chat pt-12">
                        <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-3">
                          <div className="">
                            <button onClick={(e) => inputDetails("Someone's pet dog bit me, what can I do?")} className="defaut_cat">
                              Someone's pet dog bit me, what can I do?
                            </button>

                          </div>
                          <div className="">
                            <button onClick={(e) => inputDetails("Someone is repeatedly calling me and har assing me, what can I do?")} className="defaut_cat">
                              Someone is repeatedly calling me and harassing
                              me, what can I do?
                            </button>

                          </div>
                          <div className="">
                            <button onClick={(e) => inputDetails("How do I file a case against a person who has not returned my money after I lent it to them?")} className="defaut_cat">
                              How do I file a case against a person who has not
                              returned my money after I lent it to them?
                            </button>

                          </div>
                          <div className="">
                            <button onClick={(e) => inputDetails("What are the steps I can take towards filing for bankruptcy?")} className="defaut_cat">
                              What are the steps I can take towards filing
                              for bankruptcy?
                            </button>

                          </div>
                          <div className="">
                            <button onClick={(e) => inputDetails("I got into a fight with someone and he hit me, what can I do?")} className="defaut_cat">
                              I got into a fight with someone and he hit me,
                              what can I do?
                            </button>

                          </div>
                          <div className="">
                            <button onClick={(e) => inputDetails("Someone hit my car, what can I do?")} className="defaut_cat">
                              Someone hit my car, what can I do?
                            </button>

                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div className="form_chat">
                  <div className="select">
                    <select onChange={(e) => selectCountry(e.target.value)} value={country}>
                      <option value="">Country</option>
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Aland Islands">Aland Islands</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia, Plurinational State of">Bolivia, Plurinational State of</option>
                      <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option>
                      <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                      <option value="Botswana">Botswana</option>
                      <option value="Brazil">Brazil</option>
                      <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                      <option value="Brunei Darussalam">Brunei Darussalam</option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="Cayman Islands">Cayman Islands</option>
                      <option value="Central African Republic">Central African Republic</option>
                      <option value="Chad">Chad</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">Christmas Island</option>
                      <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Curaçao">Curaçao</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">Dominican Republic</option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">Equatorial Guinea</option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guernsey">Guernsey</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guinea-Bissau">Guinea-Bissau</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="India">India</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Isle of Man">Isle of Man</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jersey">Jersey</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Korea">Korea, Democratic People's Republic of</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Lao">Lao People's Democratic Republic</option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libya">Libya</option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Macao">Macao</option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Martinique">Martinique</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Micronesia">Micronesia, Federated States of</option>
                      <option value="Moldova">Moldova, Republic of</option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montenegro">Montenegro</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Namibia">Namibia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherlands">Netherlands</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Niue">Niue</option>
                      <option value="Norfolk Island">Norfolk Island</option>
                      <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau">Palau</option>
                      <option value="Palestinian Territory">Palestinian Territory, Occupied</option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Romania">Romania</option>
                      <option value="Russian Federation">Russian Federation</option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="Saint Barthélemy">Saint Barthélemy</option>
                      <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                      <option value="Saint Lucia">Saint Lucia</option>
                      <option value="Saint Martin (French part)">Saint Martin (French part)</option>
                      <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                      <option value="Samoa">Samoa</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Serbia">Serbia</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="South Korea">South Korea</option>
                      <option value="South Sudan">South Sudan</option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                      <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania">Tanzania, United Republic of</option>
                      <option value="Thailand">Thailand</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks">Turks and Caicos Islands</option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Emirates">United Arab Emirates</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States">United States</option>
                      <option value="Uruguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Venezuela">Venezuela, Bolivarian Republic of</option>
                      <option value="Vietnam">Vietnam</option>
                      <option value="Virgin Islands">Virgin Islands</option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                  </div>

                  <div className="input_form">
                    <input type="text" value={replaceReqref.current} onChange={(e) => getInputValue(e.target.value)} placeholder="Shoot your queries here..." />
                    {Loader == true ? (
                      <button><i class="ri-send-plane-2-fill"></i></button>
                    ) : (
                      <button onClick={getAIresponse}><i class="ri-send-plane-2-fill"></i></button>
                    )}
                  </div>
                </div>
                <div>
                  {countryerr == true ? (
                    <p style={{ color: "red" }}>Country is required</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
          
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AIchatPage;
