import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import useStateRef from "react-usestateref";
import { getWeb3, keys } from "../utils/getWeb3";

import validator from "validator";
import apiService from "../core/service/v1/index";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PhoneInput } from "react-contact-number-input";
import Axios from "axios";

import { env } from "../core/service/envconfig";




function AdvocateSignupForm({ userInfo }) {
  const [addressdata, setaddressdata, addressdataref] = useStateRef("");

  console.log(userInfo)


  const navigate = useNavigate();


  const value = {
    FullName: "",
    Email: userInfo.email,
    Password: "",
    Conpassword: "",
    Expertise: "",
    StartedYear: "",
  };

  const [inputValue, setinputValue] = useState(value);
  const [getGender, setgetGender, getGenderref] = useStateRef("Select Gender");
  const [getState, setgetState, getStateref] = useStateRef("Select State");
  const [FullNameErr, setFullNameErr] = useState(false);
  const [EmailErr, setEmailErr, EmailErrref] = useStateRef(false);
  const [PasswordErr, setPasswordErr, PasswordErrref] = useStateRef(false);
  const [ConpasswordErr, setConpasswordErr] = useState(false);
  const [mobileInput, setmobileInput] = useState("");

  const [numberErr, setnumberErr] = useState("");
  const [experienceHide, setexperienceHide, experienceHideref] =
    useStateRef(false);

  const [genderErr, setgenderErr] = useState(false);
  const [Expertise, setExpertise] = useState(false);
  const [StartedYear, setStartedYear] = useState(false);
  const [validateErr, setvalidateErr] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);

  const [arbitrator, setarbitrator, arbitratorref] = useStateRef(false);
  const [mediator, setmediator, mediatorref] = useStateRef(false);

  const getValue = (e) => {
    const { name, value } = e.target;
    const formData = { ...inputValue, ...{ [name]: value } };
    setinputValue(formData);
  };

  const genderValue = (value) => {
    setgetGender(value);
    console.log(value, "=-=-=");
    console.log(getGenderref.current, "=-=-=");
  };


  const checkboxValue = (value) => {
    console.log(value, "=-=-=");
    if (value == false) {
      setarbitrator(true);
    } else {
      setarbitrator(false);
    }
  };
  const checkbox2Value = (value) => {
    console.log(value, "=-=2-=");
    if (value == false) {
      setmediator(true);
    } else {
      setmediator(false);
    }
  };

  const handleOnChange = (type) => {
    console.log(type, "=-=-=-=-=");
    var number = type.countryCode + " " + type.phoneNumber;
    setmobileInput(number);
    setnumberErr(type.message);
  };



  const formSubmit = async () => {
    try {


      validation();

      const d = new Date();
      let year = d.getFullYear();
      console.log(year, "current year");

      if (
        inputValue.FullName != "" &&
        inputValue.FullName.length > 2 &&
        inputValue.FullName.length < 19 &&
        inputValue.Email != "" &&

        EmailErrref.current == false &&

        mobileInput != "null null" &&
        inputValue.StartedYear != "" &&
        getGenderref.current != "Select Gender"
      ) {
        const experience = year - inputValue.StartedYear;
        console.log(experience, "experienceexperience");
        inputValue["Gender"] = getGenderref.current;
        inputValue["Experience"] = experience;
        inputValue["Arbitrator"] = arbitratorref.current;
        inputValue["Mediator"] = mediatorref.current;
        inputValue["Mobile"] = mobileInput;

        console.log("---=-=-=-=-=-=", inputValue);
        var data = {
          apiUrl: apiService.advacateregister,
          payload: {
            ...inputValue, nexusUser: {
              name: userInfo.name,
              typeOfLogin: userInfo.typeOfLogin,
              verifier: userInfo.verifier,
              verifierId: userInfo.verifierId,
              wallet: userInfo.address,
              balance: userInfo.balance,
              profileImage: ""
            }
          },
        };

        console.log({
          inputValue: data.payload
        }, "input value")
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.Status == true) {
          balancedata()
          localStorage.setItem("user_token", resp.token);
          localStorage.setItem(
            "decodes_advocate",
            resp.decrypted + "*advocates2024"
          );
          localStorage.setItem("type", "advocate");
          if (
            localStorage.getItem("user_token") != "" &&
            localStorage.getItem("user_token") != null &&
            localStorage.getItem("user_token") != undefined
          ) {
            // navigate("/profile");
            window.location.href = env.baseURL + "/profile";
          }

          toast.success(resp.Message);
          // navigate("/login");
        } else {
          toast.error(resp.Message);
        }
      } else {
      }
    } catch (error) { }
  };

  async function balancedata() {
    var web3 = getWeb3();

    const account = web3.eth.accounts.privateKeyToAccount(keys());
    web3.eth.accounts.wallet.add(account);
    var address = web3.eth.accounts.wallet[0].address;
    setaddressdata(address);

    console.log(addressdataref, "addressdataref")

    const feesData = {
      to: String(address),
    };
    const transapi = await Axios.post(
      `${env.apiHost}advocate/advocate/transfer`,
      feesData
    );
    console.log(transapi, "transapi");
  }

  const validation = () => {
    const error = {};

    if (inputValue.FullName == "") {
      error.FullName = "FullName is Required";
      setFullNameErr(true);
    } else if (inputValue.FullName.length < 3) {
      error.FullName = "Enter atleast 3 laters";
      setFullNameErr(true);
    } else if (inputValue.FullName.length > 20) {
      error.FullName = "Enter 20 laters only";
      setFullNameErr(true);
    }

    if (inputValue.Expertise == "") {
      error.Expertise = "Expertise is Required";
      setExpertise(true);
    }


    if (inputValue.StartedYear == "") {
      error.StartedYear = "StartedYear is Required";
      setStartedYear(true);
    }
    if (inputValue.Email == "") {
      error.Email = "Email is Required";
      setEmailErr(true);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputValue.Email)
    ) {
      error.Email = "Invalid Email address";
      setEmailErr(true);
    } else {
      setEmailErr(false);
    }
    if (inputValue.Password == "") {
      error.Password = "Password is Required";
      setPasswordErr(true);
    } else if (
      validator.isStrongPassword(inputValue.Password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setPasswordErr(false);
    } else {
      error.Password = "Enter strong password";
      setPasswordErr(true);
    }
    if (inputValue.Password != inputValue.Conpassword) {
      error.Conpassword = "Password and confirm password not match";
      setConpasswordErr(true);
    }
    if (inputValue.Conpassword == "") {
      error.Conpassword = "Confirm password is Required";
      setConpasswordErr(true);
    }

    if (getGenderref.current == "Select Gender") {
      setgenderErr(true);
    } else {
      setgenderErr(false);
    }
    setvalidateErr(error);
  };

  return (
    <div className="container" id="kt_content_container">
      <div class="mb-10 ">
        <div class="rounded-2.5xl h-[50px] border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadoHw-lg dark:border-jacarta-700 dark:bg-jacarta-700 flex flex-column justify-center">
          <div
            class="tab-pane fade show active"
            id="wallet-connect"
            role="tabpanel"
            aria-labelledby="wallet-connect-tab"
          >
            <h1 className="font-display mb-6 ml-8 mt-6 text-xl text-jacarta-700 dark:text-white text-left">Personal Information </h1>

            <div className="mx-auto  md:flex wrap_s">
              <div className="mb-1 md:w-1/2 md:pr-8">
                <div class="mb-6">
                  <label
                    for="item-name"
                    class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                  >
                    Full Name :
                  </label>
                  <input
                    type="text"
                    id="item-name"
                    name="FullName"
                    maxLength={20}
                    onChange={getValue}
                    class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                    placeholder="Full Name"
                    required
                  />
                  {FullNameErr == true ? (
                    <p style={{ color: "red" }}>{validateErr.FullName}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="mb-1 md:w-1/2 md:pr-8">
                <div class="mb-6">
                  <label
                    for="item-name"
                    class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                  >
                    Email :
                  </label>
                  <input
                    type="text"
                    id="item-name"
                    value={inputValue.Email}
                    disabled={true}
                    name="Email"
                    maxLength={35}
                    onChange={getValue}
                    class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                    placeholder="Email"
                    required
                  />
                  {EmailErr == true ? (
                    <p style={{ color: "red" }}>{validateErr.Email}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>


              <div className="mb-1 md:w-1/2 md:pr-8">
                <div class="mb-6">
                  <label
                    for="item-name"
                    class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                  >
                    Mobile :
                  </label>
                  <PhoneInput class="mobileInput" onChange={handleOnChange} />
                  <p style={{ color: "red" }}>{numberErr}</p>
                </div>
              </div>

              <div className="mb-1 md:w-1/2 md:pr-8">
                <div class="mb-6">
                  <label
                    for="item-name"
                    class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                  >
                    Gender :
                  </label>
                  <div class="dropdown my-1 cursor-pointer position_rel">
                    <div
                      class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300"
                      role="button"
                      id="item-collection"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span class="">{getGenderref.current}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="h-4 w-4 fill-jacarta-500 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </div>

                    <div
                      class="dropdown-menu z-10 hidden w-full whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800"
                      aria-labelledby="item-collection"
                    >
                      <ul class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                        <li onClick={() => genderValue("Male")}>
                          <a class="dropdown-item flex w-full items-center justify-between rounded-xl px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600">
                            <span class="flex items-center space-x-3">
                              <span class="text-jacarta-700 dark:text-white">
                                Male
                              </span>
                            </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              class="mb-[3px] h-4 w-4 fill-accent"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                            </svg>
                          </a>
                        </li>
                        <li onClick={() => genderValue("Female")}>
                          <a class="dropdown-item flex w-full items-center rounded-xl px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600">
                            <span class="flex items-center space-x-3">
                              <span class="text-jacarta-700 dark:text-white">
                                Female
                              </span>
                            </span>
                          </a>
                        </li>
                        <li onClick={() => genderValue("Other")}>
                          <a class="dropdown-item flex w-full items-center rounded-xl px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600">
                            <span class="flex items-center space-x-3">
                              <span class="text-jacarta-700 dark:text-white">
                                Other
                              </span>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    {genderErr == true ? (
                      <p style={{ color: "red" }}>Gender is required</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>



              <div className="mb-1 md:w-1/2 md:pr-8">
                <div class="mb-6">
                  <label
                    for="item-name"
                    class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                  >
                    Expertise
                  </label>
                  <input
                    type="text"
                    id="item-name"
                    name="Expertise"
                    onChange={getValue}
                    maxLength={20}
                    class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                    placeholder="Ex : Criminal Lawyer"
                    required
                  />
                  {Expertise == true ? (
                    <p style={{ color: "red" }}>{validateErr.Expertise}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {experienceHideref.current == true ? (
                <>
                  <div className="mb-1 md:w-1/2 md:pr-8">
                    <div class="mb-6">
                      <input
                        type="checkbox"
                        id="item-name"
                        name="Expertise"
                        onChange={(e) => checkboxValue(arbitratorref.current)}
                        placeholder="Ex : Criminal Lawyer"
                        required
                      />
                      <label for="item-name">Are you Arbitrator</label>
                      {/* {Expertise == true ? (
                      <p style={{color: "red"}}>{validateErr.Expertise}</p>
                    ) : (
                      ""
                    )} */}
                    </div>
                  </div>
                  <div className="mb-1 md:w-1/2 md:pr-8">
                    <div class="mb-6">
                      <input
                        type="checkbox"
                        id="item-name"
                        name="Expertise"
                        onChange={(e) => checkbox2Value(mediatorref.current)}
                        placeholder="Ex : Criminal Lawyer"
                        required
                      />
                      <label for="item-name">Are you Mediator</label>
                      {/* {Expertise == true ? (
                      <p style={{color: "red"}}>{validateErr.Expertise}</p>
                    ) : (
                      ""
                    )} */}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="mb-1 md:w-1/2 md:pr-8">
                <div class="mb-6">
                  <label
                    for="item-name"
                    class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                  >
                    Started Year
                  </label>
                  <input
                    type="number"
                    id="item-name"
                    name="StartedYear"
                    onChange={getValue}
                    maxLength={20}
                    class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                    placeholder="Enter Started Year"
                    required
                  />
                  {StartedYear == true ? (
                    <p style={{ color: "red" }}>{validateErr.StartedYear}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="form_betwine w-100_n space_brtenn md:pr-8 mb-6">

                {buttonLoader == true ? (
                  <button class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                    Loading...{" "}
                  </button>
                ) : (
                  <button
                    class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                    onClick={formSubmit}
                  >
                    Register{" "}
                  </button>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>





  )
}

export default AdvocateSignupForm