import React from "react";
// import Modal from "./Modal";
function Footer() {
  return (
    <>
      <footer class="page-footer bg-white dark:bg-jacarta-700">
        <div class="container">
          <div class="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12">
            <div class="col-span-full sm:col-span-3 md:col-span-4">
              {/* <!-- Logo --> */}
              <a href="/" class="mb-6 inline-block">
                <img
                  src={require("../img/white.png")}
                  class="max-h-7 dark:hidden"
                  alt="Xhibiter | NFT JustiChain"
                />
                <img
                  src={require("../img/white.png")}
                  class="hidden max-h-7 dark:block"
                  alt="Xhibiter | NFT JustiChain"
                />
              </a>
              <p class="mb-12 dark:text-jacarta-300 justify footer">
                Pursuant to the resolution by the Government of India to make
                India a hub of International and Domestic Arbitration and other
                ADR methodologies, the JustiChains has been established as a
                Non-Profit Arbitral, Mediation and Conciliation Institution so
                that it can function as an independent International
                Organization with its own progressive ADR Rules.
              </p>
              {/* <!-- Socials --> */}
              <div class="flex space-x-5">
                <a
                  target="_blank"
                  href="https://twitter.com/JustiChaintoken"
                  class="group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    class="h-5 w-5 fill-jacarta-300 group-hover:fill-accent dark:group-hover:fill-white"
                  >
                    <path d="M22.2125 5.65605C21.4491 5.99375 20.6395 6.21555 19.8106 6.31411C20.6839 5.79132 21.3374 4.9689 21.6493 4.00005C20.8287 4.48761 19.9305 4.83077 18.9938 5.01461C18.2031 4.17106 17.098 3.69303 15.9418 3.69434C13.6326 3.69434 11.7597 5.56661 11.7597 7.87683C11.7597 8.20458 11.7973 8.52242 11.8676 8.82909C8.39047 8.65404 5.31007 6.99005 3.24678 4.45941C2.87529 5.09767 2.68005 5.82318 2.68104 6.56167C2.68104 8.01259 3.4196 9.29324 4.54149 10.043C3.87737 10.022 3.22788 9.84264 2.64718 9.51973C2.64654 9.5373 2.64654 9.55487 2.64654 9.57148C2.64654 11.5984 4.08819 13.2892 6.00199 13.6731C5.6428 13.7703 5.27232 13.8194 4.90022 13.8191C4.62997 13.8191 4.36771 13.7942 4.11279 13.7453C4.64531 15.4065 6.18886 16.6159 8.0196 16.6491C6.53813 17.8118 4.70869 18.4426 2.82543 18.4399C2.49212 18.4402 2.15909 18.4205 1.82812 18.3811C3.74004 19.6102 5.96552 20.2625 8.23842 20.2601C15.9316 20.2601 20.138 13.8875 20.138 8.36111C20.138 8.1803 20.1336 7.99886 20.1256 7.81997C20.9443 7.22845 21.651 6.49567 22.2125 5.65605Z"></path>
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/JustiChain/"
                  class="group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    class="h-5 w-5 fill-jacarta-300 group-hover:fill-accent dark:group-hover:fill-white"
                  >
                    <path d="M18.3362 18.339H15.6707V14.1622C15.6707 13.1662 15.6505 11.8845 14.2817 11.8845C12.892 11.8845 12.6797 12.9683 12.6797 14.0887V18.339H10.0142V9.75H12.5747V10.9207H12.6092C12.967 10.2457 13.837 9.53325 15.1367 9.53325C17.8375 9.53325 18.337 11.3108 18.337 13.6245V18.339H18.3362ZM7.00373 8.57475C6.14573 8.57475 5.45648 7.88025 5.45648 7.026C5.45648 6.1725 6.14648 5.47875 7.00373 5.47875C7.85873 5.47875 8.55173 6.1725 8.55173 7.026C8.55173 7.88025 7.85798 8.57475 7.00373 8.57475ZM8.34023 18.339H5.66723V9.75H8.34023V18.339ZM19.6697 3H4.32923C3.59498 3 3.00098 3.5805 3.00098 4.29675V19.7033C3.00098 20.4202 3.59498 21 4.32923 21H19.6675C20.401 21 21.001 20.4202 21.001 19.7033V4.29675C21.001 3.5805 20.401 3 19.6675 3H19.6697Z"></path>
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://t.me/JustiChain_updates"
                  class="group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    class="h-5 w-5 fill-jacarta-300 group-hover:fill-accent dark:group-hover:fill-white"
                  >
                    <path d="M12.001 22C6.47813 22 2.00098 17.5228 2.00098 12C2.00098 6.47715 6.47813 2 12.001 2C17.5238 2 22.001 6.47715 22.001 12C22.001 17.5228 17.5238 22 12.001 22ZM8.89113 13.1708L8.90378 13.1628C9.48351 15.0767 9.77337 16.0337 9.77337 16.0337C9.88564 16.3442 10.04 16.3996 10.2273 16.3743C10.4145 16.3489 10.5139 16.2476 10.6361 16.1297C10.6361 16.1297 11.0324 15.7472 11.825 14.9823L14.3759 16.8698C14.8407 17.1266 15.1763 16.9941 15.2917 16.4377L16.9495 8.61641C17.1325 7.88842 16.8115 7.59644 16.247 7.82754L6.51397 11.5871C5.84996 11.854 5.85317 12.2255 6.39308 12.3911L8.89113 13.1708Z"></path>
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UCbg3ToXjksIHOpsxl8YsO_A"
                  class="group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    class="h-5 w-5 fill-jacarta-300 group-hover:fill-accent dark:group-hover:fill-white"
                  >
                    <path d="M12.2439 4C12.778 4.00294 14.1143 4.01586 15.5341 4.07273L16.0375 4.09468C17.467 4.16236 18.8953 4.27798 19.6037 4.4755C20.5486 4.74095 21.2913 5.5155 21.5423 6.49732C21.942 8.05641 21.992 11.0994 21.9982 11.8358L21.9991 11.9884L21.9991 11.9991C21.9991 11.9991 21.9991 12.0028 21.9991 12.0099L21.9982 12.1625C21.992 12.8989 21.942 15.9419 21.5423 17.501C21.2878 18.4864 20.5451 19.261 19.6037 19.5228C18.8953 19.7203 17.467 19.8359 16.0375 19.9036L15.5341 19.9255C14.1143 19.9824 12.778 19.9953 12.2439 19.9983L12.0095 19.9991L11.9991 19.9991C11.9991 19.9991 11.9956 19.9991 11.9887 19.9991L11.7545 19.9983C10.6241 19.9921 5.89772 19.941 4.39451 19.5228C3.4496 19.2573 2.70692 18.4828 2.45587 17.501C2.0562 15.9419 2.00624 12.8989 2 12.1625V11.8358C2.00624 11.0994 2.0562 8.05641 2.45587 6.49732C2.7104 5.51186 3.45308 4.73732 4.39451 4.4755C5.89772 4.05723 10.6241 4.00622 11.7545 4H12.2439ZM9.99911 8.49914V15.4991L15.9991 11.9991L9.99911 8.49914Z"></path>
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://medium.com/@JustiChain"
                  class="group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    class="h-5 w-5 fill-jacarta-300 group-hover:fill-accent dark:group-hover:fill-white"
                  >
                    <path d="M4.00098 3H20.001C20.5533 3 21.001 3.44772 21.001 4V20C21.001 20.5523 20.5533 21 20.001 21H4.00098C3.44869 21 3.00098 20.5523 3.00098 20V4C3.00098 3.44772 3.44869 3 4.00098 3ZM17.3019 15.9403C17.2014 15.8901 17.1512 15.7394 17.1512 15.639V8.00643C17.1512 7.906 17.2014 7.75536 17.3019 7.65493L18.256 6.55021V6.5H14.8413L12.2802 12.9776L9.36765 6.5H5.85249V6.55021L6.75639 7.80557C6.95726 8.00643 7.00747 8.30771 7.00747 8.55879V14.0824C7.05769 14.3836 7.00747 14.7351 6.85682 15.0364L5.50098 16.8944V16.9446H9.11657V16.8944L7.76072 15.0866C7.61007 14.7854 7.55985 14.4841 7.61007 14.1326V9.11114C7.66029 9.21157 7.7105 9.21157 7.76072 9.41243L11.1754 17.045H11.2257L14.54 8.75964C14.4897 9.06093 14.4897 9.41243 14.4897 9.6635V15.5888C14.4897 15.7394 14.4395 15.8399 14.3391 15.9403L13.3348 16.8944V16.9446H18.256V16.8944L17.3019 15.9403Z"></path>
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://github.com/JustiChain"
                  class="group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    class="h-5 w-5 fill-jacarta-300 group-hover:fill-accent dark:group-hover:fill-white"
                  >
                    <path d="M12.001 2C6.47598 2 2.00098 6.475 2.00098 12C2.00098 16.425 4.86348 20.1625 8.83848 21.4875C9.33848 21.575 9.52598 21.275 9.52598 21.0125C9.52598 20.775 9.51348 19.9875 9.51348 19.15C7.00098 19.6125 6.35098 18.5375 6.15098 17.975C6.03848 17.6875 5.55098 16.8 5.12598 16.5625C4.77598 16.375 4.27598 15.9125 5.11348 15.9C5.90098 15.8875 6.46348 16.625 6.65098 16.925C7.55098 18.4375 8.98848 18.0125 9.56348 17.75C9.65098 17.1 9.91348 16.6625 10.201 16.4125C7.97598 16.1625 5.65098 15.3 5.65098 11.475C5.65098 10.3875 6.03848 9.4875 6.67598 8.7875C6.57598 8.5375 6.22598 7.5125 6.77598 6.1375C6.77598 6.1375 7.61348 5.875 9.52598 7.1625C10.326 6.9375 11.176 6.825 12.026 6.825C12.876 6.825 13.726 6.9375 14.526 7.1625C16.4385 5.8625 17.276 6.1375 17.276 6.1375C17.826 7.5125 17.476 8.5375 17.376 8.7875C18.0135 9.4875 18.401 10.375 18.401 11.475C18.401 15.3125 16.0635 16.1625 13.8385 16.4125C14.201 16.725 14.5135 17.325 14.5135 18.2625C14.5135 19.6 14.501 20.675 14.501 21.0125C14.501 21.275 14.6885 21.5875 15.1885 21.4875C19.259 20.1133 21.9999 16.2963 22.001 12C22.001 6.475 17.526 2 12.001 2Z"></path>
                  </svg>
                </a>

                <a
                  target="_blank"
                  href="https://www.reddit.com/user/JustiChain"
                  class="group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    class="h-5 w-5 fill-jacarta-300 group-hover:fill-accent dark:group-hover:fill-white"
                  >
                    <path d="M12.001 22C6.47813 22 2.00098 17.5228 2.00098 12C2.00098 6.47715 6.47813 2 12.001 2C17.5238 2 22.001 6.47715 22.001 12C22.001 17.5228 17.5238 22 12.001 22ZM18.671 12C18.65 11.425 18.2932 10.916 17.7598 10.7C17.2265 10.4841 16.6161 10.6016 16.201 11C15.0634 10.2267 13.7262 9.7995 12.351 9.77L13.001 6.65L15.141 7.1C15.1935 7.58851 15.5932 7.96647 16.0839 7.99172C16.5745 8.01696 17.0109 7.68201 17.1133 7.20147C17.2157 6.72094 16.9538 6.23719 16.4955 6.06019C16.0372 5.88318 15.5181 6.06536 15.271 6.49L12.821 6C12.74 5.98224 12.6554 5.99763 12.5858 6.04272C12.5163 6.08781 12.4678 6.15886 12.451 6.24L11.711 9.71C10.3189 9.73099 8.96325 10.1585 7.81098 10.94C7.38972 10.5436 6.77418 10.4333 6.2415 10.6588C5.70882 10.8842 5.35944 11.4028 5.35067 11.9812C5.3419 12.5595 5.67538 13.0885 6.20098 13.33C6.18972 13.4765 6.18972 13.6235 6.20098 13.77C6.20098 16.01 8.81098 17.83 12.031 17.83C15.251 17.83 17.861 16.01 17.861 13.77C17.8722 13.6235 17.8722 13.4765 17.861 13.33C18.3646 13.0797 18.6797 12.5623 18.671 12ZM8.67098 13C8.67098 12.4477 9.11869 12 9.67098 12C10.2233 12 10.671 12.4477 10.671 13C10.671 13.5523 10.2233 14 9.67098 14C9.40576 14 9.15141 13.8946 8.96387 13.7071C8.77633 13.5196 8.67098 13.2652 8.67098 13ZM14.481 15.75C13.7715 16.2847 12.8986 16.5568 12.011 16.52C11.1234 16.5568 10.2505 16.2847 9.54098 15.75C9.45288 15.6427 9.46057 15.486 9.55877 15.3878C9.65696 15.2896 9.81363 15.2819 9.92098 15.37C10.5222 15.811 11.2561 16.0333 12.001 16C12.7468 16.0406 13.4841 15.8254 14.091 15.39C14.1624 15.3203 14.2656 15.2941 14.3617 15.3211C14.4577 15.3482 14.5321 15.4244 14.5567 15.5211C14.5813 15.6178 14.5524 15.7203 14.481 15.79V15.75ZM14.301 14.04C13.7487 14.04 13.301 13.5923 13.301 13.04C13.301 12.4877 13.7487 12.04 14.301 12.04C14.8533 12.04 15.301 12.4877 15.301 13.04C15.312 13.3138 15.2101 13.5802 15.0192 13.7767C14.8282 13.9733 14.565 14.083 14.291 14.08L14.301 14.04Z"></path>
                  </svg>
                </a>

                <a
                  target="_blank"
                  href="https://discord.gg/e9cKqusTDB"
                  class="group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    class="h-5 w-5 fill-jacarta-300 group-hover:fill-accent dark:group-hover:fill-white"
                  >
                    <path d="M19.3034 5.33716C17.9344 4.71103 16.4805 4.2547 14.9629 4C14.7719 4.32899 14.5596 4.77471 14.411 5.12492C12.7969 4.89144 11.1944 4.89144 9.60255 5.12492C9.45397 4.77471 9.2311 4.32899 9.05068 4C7.52251 4.2547 6.06861 4.71103 4.70915 5.33716C1.96053 9.39111 1.21766 13.3495 1.5891 17.2549C3.41443 18.5815 5.17612 19.388 6.90701 19.9187C7.33151 19.3456 7.71356 18.73 8.04255 18.0827C7.41641 17.8492 6.82211 17.5627 6.24904 17.2231C6.39762 17.117 6.5462 17.0003 6.68416 16.8835C10.1438 18.4648 13.8911 18.4648 17.3082 16.8835C17.4568 17.0003 17.5948 17.117 17.7434 17.2231C17.1703 17.5627 16.576 17.8492 15.9499 18.0827C16.2789 18.73 16.6609 19.3456 17.0854 19.9187C18.8152 19.388 20.5875 18.5815 22.4033 17.2549C22.8596 12.7341 21.6806 8.80747 19.3034 5.33716ZM8.5201 14.8459C7.48007 14.8459 6.63107 13.9014 6.63107 12.7447C6.63107 11.5879 7.45884 10.6434 8.5201 10.6434C9.57071 10.6434 10.4303 11.5879 10.4091 12.7447C10.4091 13.9014 9.57071 14.8459 8.5201 14.8459ZM15.4936 14.8459C14.4535 14.8459 13.6034 13.9014 13.6034 12.7447C13.6034 11.5879 14.4323 10.6434 15.4936 10.6434C16.5442 10.6434 17.4038 11.5879 17.3825 12.7447C17.3825 13.9014 16.5548 14.8459 15.4936 14.8459Z"></path>
                  </svg>
                </a>
              </div>
            </div>

            <div class="col-span-full sm:col-span-3 md:col-span-3 md:col-start-7">
              <h3 class="mb-6 font-display text-sm dark:text-white">
                STEP BY STEP GUIDES
              </h3>
              <ul class="flex flex-col space-y-1 dark:text-jacarta-300">
                <li>
                  <a href="" class="hover:text-accent dark:hover:text-white">
                    Login as Advocate - Law Firm
                  </a>
                </li>
                <li>
                  <a href="" class="hover:text-accent dark:hover:text-white">
                    Login as Client- User
                  </a>
                </li>
                <li>
                  <a  target="_blank" href="https://app.JustiChain.io/" class="hover:text-accent dark:hover:text-white">
                    Store Document on Blockchain
                  </a>
                </li>
                <li>
                  <a href="/" class="hover:text-accent dark:hover:text-white">
                    FAQ/Guide
                  </a>
                </li>
                <li>
                  <a href="">Wallet & Exchanges</a>
                </li>
                <li>
                  <a
                    href="https://JustiChain.io/static/media/JustiChain_whitepaper.14958e347e6ebef809f4.pdf"
                    target="_blank"
                    class="hover:text-accent dark:hover:text-white"
                  >
                    White paper
                  </a>
                </li>
                <li>
                  <a href="" class="hover:text-accent dark:hover:text-white">
                    Download Brand Assets
                  </a>
                </li>
                <li>
                  <a href="" class="hover:text-accent dark:hover:text-white">
                    Community Support.
                  </a>
                </li>
              </ul>
            </div>

            <div class="col-span-full sm:col-span-3 md:col-span-3">
              <h3 class="mb-6 font-display text-sm dark:text-white">
                VIRTUAL ARBITRATION HEARING
              </h3>
              <ul class="flex flex-col space-y-1 dark:text-jacarta-300 mb-6">
                <li>
                  <a href="/" class="hover:text-accent dark:hover:text-white">
                    VAH FAQ's
                  </a>
                </li>
              </ul>
              <h3 class="mb-6 font-display text-sm dark:text-white">
                CONTACT US :
              </h3>
              <ul class="flex flex-col space-y-1 dark:text-jacarta-300 mb-6">
                <li>
                  <p className="dark:text-jacarta-300">
                    Email : info@JustiChain.io 
                  </p>
                </li>
              </ul>
              <p className="mb-12 dark:text-jacarta-300"></p>
            </div>
          </div>
          <div class="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            <ul class="flex flex-wrap space-x-4 text-sm dark:text-jacarta-400">
              <li>
                <a href="#" class="hover:text-accent dark:hover:text-white">
                  Terms and conditions
                </a>
              </li>
              <li>
                <a href="#" class="hover:text-accent dark:hover:text-white">
                  Privacy policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      {/* <Modal/> */}
      {/* <div
        class="modal fade"
        id="walletModal"
        tabindex="-1"
        aria-labelledby="walletModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog max-w-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="walletModalLabel">
                Connect your wallet
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="h-6 w-6 fill-jacarta-700 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            <div class="modal-body p-6 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                viewBox="0 0 318.6 318.6"
                class="mb-4 inline-block h-8 w-8"
              >
                <path
                  fill="#e2761b"
                  stroke="#e2761b"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M274.1 35.5l-99.5 73.9L193 65.8z"
                />
                <path
                  class="st1"
                  d="M44.4 35.5l98.7 74.6-17.5-44.3zm193.9 171.3l-26.5 40.6 56.7 15.6 16.3-55.3zm-204.4.9L50.1 263l56.7-15.6-26.5-40.6z"
                />
                <path
                  class="st1"
                  d="M103.6 138.2l-15.8 23.9 56.3 2.5-2-60.5zm111.3 0l-39-34.8-1.3 61.2 56.2-2.5zM106.8 247.4l33.8-16.5-29.2-22.8zm71.1-16.5l33.9 16.5-4.7-39.3z"
                />
                <path
                  d="M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zm-105 0l31.5 14.9-.2-9.3 2.5-22.1z"
                  fill="#d7c1b3"
                  stroke="#d7c1b3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M138.8 193.5l-28.2-8.3 19.9-9.1zm40.9 0l8.3-17.4 20 9.1z"
                  fill="#233447"
                  stroke="#233447"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z"
                  fill="#cd6116"
                  stroke="#cd6116"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M87.8 162.1l23.6 46-.8-22.9zm120.3 23.1l-1 22.9 23.7-46zm-64-20.6l-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0l-2.7 18 1.2 45 6.7-34.1z"
                  fill="#e4751f"
                  stroke="#e4751f"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  class="st6"
                  d="M179.8 193.5l-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3l.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z"
                />
                <path
                  fill="#c0ad9e"
                  stroke="#c0ad9e"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z"
                />
                <path
                  fill="#161616"
                  stroke="#161616"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M177.9 230.9l-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z"
                />
                <path
                  d="M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z"
                  fill="#763d16"
                  stroke="#763d16"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  class="st6"
                  d="M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z"
                />
              </svg>
              <p class="text-center dark:text-white">
                You don't have MetaMask in your browser, please download it from
                <a
                  href="https://metamask.io/"
                  class="text-accent"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  MetaMask
                </a>
              </p>
            </div>

            <div class="modal-footer">
              <div class="flex items-center justify-center space-x-4">
                <a
                  href="https://metamask.io/"
                  target="_blank"
                  rel="noreferrer noopener"
                  class="rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                >
                  Get Metamask
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Footer;
