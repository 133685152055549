import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import moment from "moment";
import DragAndDrop from "../common/DragAndDrop";
import { Editor } from "@tinymce/tinymce-react";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Report } from "notiflix/build/notiflix-report-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import { eventsdata, getTxnStatus } from "../utils/contract";
import Axios from "axios";
import Web3 from "web3";
import { getWeb3, keys } from "../utils/getWeb3";
import { NFTStorage, File, Blob } from "nft.storage";
import LawContract from "../utils/lawblocks.json";
import LawTokenContract from "../utils/lawblocks.json";
import HtmlDiff from "htmldiff-js";
import useStateRef from "react-usestateref";
import { Table } from "reactstrap";
import { useNavigate } from "react-router-dom";

import {
  LAWBLOCKS_CONTRACT,
  LAWBLOCKS_TOKEN_CONTRACT,
} from "../utils/constant";
import { env } from "../core/service/envconfig";


const ethers = require("ethers");

function ContractUpload() {
  const [web3, setWeb3] = useState(getWeb3());
  const [User, setUser, Userref] = useStateRef("");
  const [revisionResult, setRevisionResult, revisionResultref] = useStateRef(null);
  const navigate = useNavigate();

  const [uploadDetails, setUploadDetails, uploadDetailsref] = useStateRef({
    shortContent: "",
    uploadStatus: false,
    viewStatus: false,
    imagePreview: false,
    fileName: "",
    fileext: "",
    text: "",
    currentPage: "draft",
    country: "",
    errors: {},
    countryValidation: false,
  });
  const StorxStorage = require("storx-storage-sdk");
  const storxStorageFunctions = StorxStorage();

  function toXdc(address) {
    return "xdc" + String(address).slice(2);
  }

  useEffect(() => {
    // console.log({ uploadDetails });
  }, [uploadDetails]);

  const NFT_STORAGE_TOKEN =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDVBMkM5YTUxQWE2NmUzMTk2YjJjQTdBODg2NjFkMTUwODVjMDczODAiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3ODUyNTQxMzIxOSwibmFtZSI6ImhvcHB5aGFyZXMifQ.CYTqIjhUqVUlViBTjN-mmQoDvBugYwghGIpTc7C6DoY";
  const client = new NFTStorage({ token: NFT_STORAGE_TOKEN });

  useEffect(() => {
    const URL = window.location.href;
    const Splitdata = URL.split("viewdraft/")[1];
    seturldata(Splitdata);
    getData1(Splitdata);
  }, []);

  async function convertToBlob(file64) {
    const base64Response = await fetch(file64);
    // console.log("reached conversion");
    const blobs = await base64Response.blob();
    var files = new Blob([blobs], { type: `application/pdf` });
    var blobURL = URL.createObjectURL(files);
    return blobURL;
  }

  async function getData1(url) {
    Loading.standard("Loading...", {
      messageColor: "#0d102d",
      svgColor: "#0d102d",
      svgSize: "100px",
      backgroundColor: "#eff7ff",
    });
    try {
      const Data = {
        Link: url,
      };
      const result = await Axios.post(
        `${env.apiHost}advocate/advocate/viewdraft`, Data
      );

      var temp = result.data.draft;
      setShareList(result.data.draft.shareList);
      // console.log(result, "result =====");
      setUser(result.data.user);
      setDraftList(result.data.draft);

      // console.log(temp.editedDraft.reverse(), "=-=-=-=-=temp.editedDraftres");
      var Type = temp.draftName.split(".")[1];
      var image = temp.originalDraft;
      // console.log("FileTYpe", Type);
      var remTag = image.split("</p>")[0];
      if (Type === "docx") {
        setUploadDetails({
          ...uploadDetails,
          doctype: true,
          ImageUrl: remTag,
          draftData: result.data.draft,
          fileName: temp.draftName,
          shortContent: temp.originalDraft,
          ipfsHash: temp.ipfsHash,
          originalDraft: temp.originalDraft,
          draftID: temp._id,
          emailList: temp.shareList,
          revisionList: temp.editedDraft,
        });
        // console.log(uploadDetailsref.current, "uploadDetailsref.current");
        // console.log()
        Loading.remove();
      } else if (Type === "pdf") {
        var blobURL;
        blobURL = await convertToBlob(temp.originalDraft);
        setUploadDetails({
          ...uploadDetails,
          bloburls: blobURL,
          doctype: false,
          viewPdf: true,
          draftData: result.data,
          fileName: temp.draftName,
          shortContent: remTag,
          originalDraft: temp.originalDraft,
          draftID: temp._id,
          emailList: temp.shareList,
          revisionList: temp.editedDraft,
        });
        // console.log(uploadDetailsref.current, "=-=-=-=-3type");
        Loading.remove();
      } else {
        setUploadDetails({
          ...uploadDetails,
          doctype: false,
          viewPdf: false,
          draftData: result.data,
          fileName: temp.draftName,
          shortContent: remTag,
          originalDraft: temp.originalDraft,
          draftID: temp._id,
          emailList: temp.shareList,
          revisionList: temp.editedDraft,
        });
        Loading.remove();
      }
      // console.log(Type, "=-=-=-=-=-=-=-=-=-");
      revisiondatas(temp.editedDraft);

    } catch (error) {
      console.error(error);
      setUploadDetails({
        ...uploadDetails,
        isLoading: true,
      });
    }
  }
  async function revisiondatas(data) {
    // console.log(data, "uploadDetailsref.current.revisionList")


    try {
      if (data && data?.length > 0) {
        const result = data && data?.map(
          (revision, index) => {
            const diffCode = HtmlDiff.execute(
              uploadDetailsref.current.originalDraft,
              revision.changes
            );
            // console.log(revision, "revisionrevisionrevisionrevisionrevision=====")

            return (
              <div className="card bg-light revisioncss shadow-sm" key={index}>
                <div className="card-header w_100 flex flex-row justify-between">
                  <h3 className="card-title TextPrimary mb-5 text-x">
                    Updated At:{" "}
                    {moment(revision.date).format("MMMM Do YYYY, h:mm:ss a")}
                  </h3>
                  <div className="card-toolbar">
                    <label
                      class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-l hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer TextPrimary"
                      onClick={() => {
                        window.scroll(0, 0);
                        setUploadDetails({
                          ...uploadDetails,
                          shortContent: revision.changes,
                        });
                      }}
                    >
                      <span class="dark:text-white group-hover:text-white">
                        Edit
                      </span>
                    </label>
                  </div>
                </div>
                <div className="card-body card-scroll h-200px">
                  <div className="row">
                    <div className="col-md-6 new-doc-line">
                      <div dangerouslySetInnerHTML={{ __html: diffCode }}></div>
                    </div>
                  </div>
                </div>
                <div className="card-footer text-center TextPrimary text-l mt-4">
                  edited By: {revision.email}
                </div>
              </div>
            );
          }
        );

        setRevisionResult(result);
      } else {
        setRevisionResult(
          <div className="card bg-light shadow-sm">
            <div className="card-header w_100 flex flex-row justify-center">
              <h3 className="card-title text-white mb-5 text-x">
                No Revision Found
              </h3>
            </div>
          </div>
        );
      }
    } catch (error) {
      // console.log(error,"error-unVisibleObject")    
    }



  }
  async function handleEditorShortChange(contentVal, editor) {
    setUploadDetails({
      ...uploadDetails,
      shortContent: contentVal,
    });
  }

  const [shareList, setShareList, shareListref] = useStateRef([]);
  const [draftList, setDraftList, draftListref] = useStateRef([]);
  const [urldata, seturldata, urldataref] = useStateRef([]);
  const [balances, setbalances, balancesref] = useStateRef([]);
  const [addressdata, setaddressdata, addressdataref] = useStateRef("");
  const [uploadloader, setuploadloader, uploadloaderref] = useStateRef(false);

  useEffect(() => {
    balancedata();
  }, []);

  async function balancedata() {

    try {
      var balance = 0;
      var web3 = getWeb3();
      // console.log(web3, "web3");
      // console.log(
      //   web3.eth.accounts.privateKeyToAccount(keys()),
      //   "web3.eth.accounts"
      // );
      const account = web3.eth.accounts.privateKeyToAccount(keys());
      web3.eth.accounts.wallet.add(account);
      var address = web3.eth.accounts.wallet[0].address;
      // console.log(address, "address ==========++++");
      setaddressdata(address);
      if (address) {
        const lawContractdata = new web3.eth.Contract(
          LawContract,
          LAWBLOCKS_CONTRACT
        );
        const lawTokenContractdata = new web3.eth.Contract(
          LawTokenContract,
          LAWBLOCKS_TOKEN_CONTRACT
        );
      }

      // console.log("address is here", Number(await web3.eth.getBalance(address)));
      balance = web3.utils.fromWei(
        Number(await web3.eth.getBalance(address)),
        "ether"
      );
      // console.log(balance, "=-=-=-balance");
      setbalances(balance);
    } catch (error) {
      // console.log(error,"error-unVisibleObject")    
    }


  }

  async function onpreview() {
    setUploadDetails({
      ...uploadDetails,
      imagePreview: !uploadDetails.imagePreview,
    });
  }

  async function onSubmitUpload() {
    try {
      if (!web3) {
        Notify.failure(`Connection Error`);
        return null;
      }

      // console.log(uploadDetails, "HTML");

      var fileBuffer;

      fileBuffer = new Blob([uploadDetails.shortContent], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      // console.log(fileBuffer, "HTML");

      setuploadloader(true);


      // Loading.standard("Starting Upload", {
      //   messageColor: "#fff",
      //   svgColor: "#fff",
      //   svgSize: "100px",
      //   backgroundColor: "#0d102d",
      // });

      var ipfsHash;
      if (env.REACT_APP_STORAGE_TYPE === "ipfs") {
        // console.log(fileBuffer, "inside");
        let added = await client.storeBlob(fileBuffer);
        // console.log("outside");

        ipfsHash = `${added}`;
        // console.log(ipfsHash, "ipfsHash");
      } else if (env.REACT_APP_STORAGE_TYPE === "storx") {
        let form = new FormData();
        form.append("xfile", fileBuffer, uploadDetails.fileName);
        let added = await storxStorageFunctions.uploadFileToFolder({
          data: form, // file object
          folderId: env.REACT_APP_STORX_FOLDER_ID, // string
          mnemonic: env.REACT_APP_STORX_MNEMONIC,
          apiAccessKey: env.REACT_APP_STORX_ACCESS_KEY,
        });
        var addedresult = added.result;
        Loading.remove();
        ipfsHash = `${addedresult.fileId}`;
        // console.log(ipfsHash, "ipfsHash2");
      } else {
        Loading.remove();
        // console.log();
      }

      let signature = web3.eth.accounts.sign(ipfsHash, keys());
      const ethersProvider = new ethers.JsonRpcProvider(
        "https://erpc.xinfin.network"
      );
      const ethersWallet = new ethers.Wallet(keys(), ethersProvider);
      const lawEthersInstance = new ethers.Contract(
        LAWBLOCKS_CONTRACT,
        LawContract,
        ethersWallet
      );

      var txConfig = {
        gasLimit: "2100000",
      };

      const uploadHash = await lawEthersInstance.uploadFile(
        ipfsHash.toString(),
        uploadDetails.fileName.toString(),
        signature.toString(),
        txConfig
      );
      await uploadHash.wait(1).then(async function () {
        // Loading.standard(
        //   "Please wait while Confirming Transaction in Blockchain",
        //   {
        //     messageColor: "#fff",
        //     svgColor: "#fff",
        //     svgSize: "100px",
        //     backgroundColor: "#0d102d",
        //   }
        // );
        const [txhash, status, blockNumber] = await getTxnStatus(
          uploadHash.hash
        );
        // console.log(txhash, status, blockNumber);
        const documentNo = await lawEthersInstance._contractIds();
        var start = setInterval(callTransaction, 3000);
        async function callTransaction() {
          if (!status) {
            Report.failure(
              "Error Occured on Blockchain Transaction",
              `Try Again!!`,
              "View",
              () => {
                window.open(
                  `https://explorer.xinfin.network/tx/${uploadHash.hash}`
                );
              }
            );
            Loading.remove();
            return null;
          } else {
            const walletadd = toXdc(addressdataref.current);
            // console.log(walletadd, "walletadd");

            const Data = {
              fileName: uploadDetails.fileName,
              txnHash: uploadHash.hash,
              ipfsHash: ipfsHash,
              draftID: urldataref.current,
              documentNo: Number(documentNo) - 1,
              walletAddress: walletadd,
              storageType: env.REACT_APP_STORAGE_TYPE,
            };
            // console.log(Data, "======");
            Axios.post(`${env.apiHost}advocate/advocate/document`, Data)
              .then(function (response) {
                setuploadloader(false);

                Loading.remove();
                Report.success(
                  "Document Uploaded Successfully",
                  "",
                  "View",
                  () => {
                    window.open(
                      `https://explorer.xinfin.network/tx/${uploadHash.hash}`
                    );
                    navigate(`/contracts`);
                  }
                );
                clearInterval(start);
              })
              .catch(function (error) {
                setuploadloader(false);

                Loading.remove();
                Notify.failure(`Error Occured Try Again`);
              });
          }
        }
      });
    } catch (e) {
      setuploadloader(false);

      // console.log(e, "=-=-=-=-=-");
      Loading.remove();
    }
  }
  function onChange(e) {
    setUploadDetails({
      ...uploadDetails,
      [e.target.name]: e.target.value,
    });
  }

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(,|$)/
      );
  }

  async function onShare(result) {
    // console.log("onshare");
    if (!web3) {
      Notify.failure(`Connection Error`);
      return null;
    }
    try {
      Confirm.prompt(
        "Are  you share ?",
        "Enter the email ID to share",
        "",
        "Send",
        "Cancel",
        async (clientAnswer) => {
          if (
            clientAnswer != null &&
            clientAnswer !== undefined &&
            clientAnswer.trim() !== ""
          ) {
            const emailArray = clientAnswer.split(',').map(email => email.trim());
            const invalidEmails = emailArray.filter(email => !validateEmail(email));

            if (invalidEmails.length > 0) {
              Notify.failure(`Invalid Email(s): ${invalidEmails.join(', ')}`);
              return;
            }

            Axios.post(`${env.apiHost}advocate/advocate/share`, {
              email: emailArray,
              DraftID: urldataref.current,
              sharedby: Userref.current.email,
            })
              .then(function (response) {
                Loading.remove();
                Report.success("Draft Shared Successfully", "", "View", () => {
                  getData1(urldataref.current);
                });
              })
              .catch(function (error) {
                Loading.remove();
                Notify.failure(`Error Occured Try Again`);
              });
          } else {
            Notify.failure(`Please enter the email ID`);
          }
        },
        (clientAnswer) => {
          Notify.failure(`User Cancelled `);
        },
        {}
      );
    } catch (e) {
      Notify.failure(`Error Occured Try Again`);
    }
  }

  async function onDraftUpdate() {
    try {
      Loading.standard("Uploading Document", {
        messageColor: "#0d102d",
        svgColor: "#0d102d",
        svgSize: "100px",
        backgroundColor: "#eff7ff",
      });
      const Data = {
        _id: urldataref.current,
        email: Userref.current.email,
        changes: uploadDetails.shortContent,
      };
      Axios.post(`${env.apiHost}advocate/advocate/draftupdate`, Data)
        .then(function (response) {
          Loading.remove();
          Report.success("Draft Edited Successfully", "", "View", () => {
            getData1(urldataref.current);
          });
        })
        .catch(function (error) {
          Loading.remove();
          Notify.failure(`Error Occured Try Again`);
        });
    } catch (e) {
      Notify.failure(`Error Occured Try Again`);
    }
  }

  return (
    <div>
      <Header />
      <main class="mt-24">
        <section class="relative pt-16 pb-24">
          <div>
            {uploadDetails && (
              <div className="imadsetfd">
                {uploadDetails.doctype == true ? (
                  <>
                    <div>
                      {uploadloaderref.current == false ? (
                        <React.Fragment>
                          <div
                            className="content d-flex flex-column flex-column-fluid"
                            id="kt_content"
                          >
                            <div className="container" id="kt_content_container">
                              <div class="mb-10 ">
                                <div>
                                  <Editor
                                    apiKey='1q31mvbn8z72qjyalrqpcjnrx3ge16jscq0lpfxtiue7pi0t'
                                    value={uploadDetails.shortContent}
                                    init={{
                                      height: 450,
                                      menubar: false,
                                      plugins: [
                                        "advlist autolink lists link image charmap print preview anchor",
                                        "searchreplace visualblocks code fullscreen casechange",
                                        "directionality advcode visualchars mediaembed codesample pagebreak",
                                        "nonbreaking tableofcontents checklist tinymcespellchecker editimage",
                                        "formatpainter permanentpen linkchecker emoticons advtable export autosave",
                                        "insertdatetime media table paste code help wordcount",
                                      ],
                                      branding: false,
                                      content_style: "body img{ width:100%; }",
                                      toolbar:
                                        "undo redo | styleselect | formatselect | bold italic backcolor | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist outdent indent | removeformat | table tabledelete | \
                                        tableprops tablerowprops tablecellprops | \
                                        tableinsertrowbefore tableinsertrowafter tabledeleterow | \
                                        tableinsertcolbefore tableinsertcolafter tabledeletecol | help",
                                    }}
                                    onEditorChange={handleEditorShortChange}
                                  />
                                  <div
                                    style={{
                                      // background: "aqua",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginTop: "20px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <b className="TextPrimary">
                                      File Name : {uploadDetails.fileName}
                                    </b>
                                  </div>
                                </div>
                                <div className="d-flex text-center">
                                  <label
                                    class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                    onClick={() => onDraftUpdate()}
                                  >
                                    <span class="dark:text-white group-hover:text-white">
                                      Update Draft
                                    </span>
                                  </label>

                                  {draftListref.current.userID === User._id && (
                                    <React.Fragment>
                                      <label
                                        class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                        onClick={() => onShare()}
                                      >
                                        <span class="dark:text-white group-hover:text-white">
                                          Share
                                        </span>
                                      </label>
                                      <label
                                        class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                        onClick={() => onSubmitUpload()}
                                      >
                                        <span class="dark:text-white group-hover:text-white">
                                          Upload Contract
                                        </span>
                                      </label>
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="card mb-5 mb-xl-10">
                                    <div className="card-header">
                                      <div className="card-title">
                                        <h3 className="text-center dark:text-white TextPrimary group-hover:text-white text-2xl bold">
                                          Revisions
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {revisionResultref.current}
                              </div>

                              {draftListref.current.userID === User._id && (
                                <div className="col-md-12">
                                  <b className="TextPrimary text-xl">Share List</b>
                                  <div className="card mb-5 pt-4 mb-xl-10">
                                    {/*begin::Card body*/}
                                    <div class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border">
                                      <div className="uploadtbl">
                                        {/*begin::Table*/}
                                        <Table bordered hover responsive>
                                          {/*begin::Thead*/}
                                          <thead className="border-gray-200 fs-5 fw-bold bg-lighten">
                                            <tr>
                                              <th className="TextPrimary">S.No</th>
                                              <th className="TextPrimary">User</th>
                                            </tr>
                                          </thead>
                                          {/*end::Thead*/}
                                          {/*begin::Tbody*/}
                                          <tbody className="fw-6 fw-bold text-gray-600">
                                            {shareListref.current &&
                                              shareList.length > 0 ? (
                                              shareList.map((item, i) => {
                                                // console.log(item, "itemssssssssss");

                                                return (
                                                  <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{item}</td>
                                                  </tr>
                                                );
                                              })
                                            ) : (
                                              <tr>
                                                <td colSpan="3">
                                                  <span className="w-100 text-center d-block">
                                                    No History found!
                                                  </span>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </Table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}



                            </div>
                          </div>
                        </React.Fragment>
                      ) : (
                        <>
                          <img
                            width="100"
                            height="100"
                            class="my-5 block mx-auto fill-jacarta-500 dark:fill-white"
                            src={require("../img/loadinggif.gif")}
                          ></img>
                          <p class="text-center text-l dark:text-jacarta-500">
                            Please Wait, Your transaction is uploading to ETH Blockchain
                          </p>
                        </>
                      )}
                    </div>

                  </>
                ) : (
                  <>
                    <div>
                      {uploadloaderref.current == false ? (
                        <React.Fragment>
                          <div
                            className="content d-flex flex-column flex-column-fluid"
                            id="kt_content"
                          >
                            <div className="container" id="kt_content_container">
                              <div class="mb-10 ">
                                {uploadDetails.viewPdf == false ? (
                                  <div>
                                    {uploadDetails.imagePreview == false ? (
                                      <div
                                        className="flex flex-col"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          className=""
                                          style={{ fontSize: "150px" }}
                                        >
                                          <i class="ri-image-fill image_icon"></i>
                                        </span>
                                        <b className="TextPrimary">
                                          File Name : {uploadDetails.fileName}
                                        </b>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          // background: "aqua",
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={uploadDetails.shortContent}
                                          width="300px"
                                        ></img>
                                        <b className="TextPrimary">
                                          File Name : {uploadDetails.fileName}
                                        </b>
                                      </div>
                                    )}
                                  </div>
                                ) : uploadDetails.doctype == true ? (
                                  uploadDetails.imagePreview == false ? (
                                    <div
                                      className="flex flex-col"
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        className=""
                                        style={{ fontSize: "150px" }}
                                      >
                                        <i class="ri-file-pdf-line image_icon"></i>
                                      </span>
                                      <b className="TextPrimary">
                                        File Name : {uploadDetails.fileName}
                                      </b>
                                    </div>
                                  ) : (
                                    <React.Fragment>
                                      <Editor
                                        apiKey='1q31mvbn8z72qjyalrqpcjnrx3ge16jscq0lpfxtiue7pi0t'

                                        value={uploadDetails.shortContent}
                                        init={{
                                          height: 400,
                                          plugins: [
                                            "advlist autolink lists link image charmap print preview anchor",
                                            "searchreplace visualblocks code fullscreen powerpaste casechange",
                                            "directionality advcode visualchars mediaembed codesample pagebreak",
                                            "nonbreaking tableofcontents checklist tinymcespellchecker editimage",
                                            "formatpainter permanentpen linkchecker emoticons advtable export autosave",
                                            "insertdatetime media table paste code help wordcount",
                                          ],
                                          menubar: false,
                                          content_style: "body img{ width:100%; }",
                                          toolbar:
                                            "undo redo | styleselect | formatselect | bold italic backcolor | \
                                                                alignleft aligncenter alignright alignjustify | \
                                                                bullist numlist outdent indent | removeformat | table tabledelete | \
                                                                tableprops tablerowprops tablecellprops | \
                                                                tableinsertrowbefore tableinsertrowafter tabledeleterow | \
                                                                tableinsertcolbefore tableinsertcolafter tabledeletecol | help",
                                        }}
                                      />
                                      <div
                                        style={{
                                          // background: "aqua",
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <b className="TextPrimary">
                                          File Name : {uploadDetails.fileName}
                                        </b>
                                      </div>
                                    </React.Fragment>
                                  )
                                ) : (
                                  <div>
                                    {uploadDetails.imagePreview == false ? (
                                      <div
                                        className="flex flex-col"
                                        style={{
                                          // background: "aqua",
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          className=""
                                          style={{ fontSize: "150px" }}
                                        >
                                          <i class="ri-file-pdf-line image_icon"></i>
                                        </span>
                                        <b className="TextPrimary">
                                          File Name : {uploadDetails.fileName}
                                        </b>
                                      </div>
                                    ) : (
                                      <div
                                        className="pdfFrameview"
                                        style={{
                                          // background: "aqua",
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <iframe
                                          className="pdfFrameview"
                                          src={uploadDetails.bloburls}
                                          width="100%"
                                          height="500"
                                        ></iframe>

                                        <b className="TextPrimary">
                                          File Name : {uploadDetails.fileName}
                                        </b>
                                      </div>
                                    )}
                                  </div>
                                )}

                                <div className="d-flex mt-5">
                                  {draftListref.current.userID === User._id && (
                                    <React.Fragment>
                                      <div className="text-center">
                                        <label
                                          class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                          onClick={() => onShare()}
                                        >
                                          <span class="dark:text-white group-hover:text-white">
                                            Share
                                          </span>
                                        </label>
                                        <label
                                          class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                          onClick={() => onSubmitUpload()}
                                        >
                                          <span class="dark:text-white group-hover:text-white">
                                            Upload Contract
                                          </span>
                                        </label>

                                        {uploadDetails.imagePreview == false ? (
                                          <label
                                            class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                            onClick={() => onpreview()}
                                          >
                                            <span class="dark:text-white group-hover:text-white">
                                              Show Preview
                                            </span>
                                          </label>
                                        ) : (
                                          <label
                                            class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"
                                            onClick={() => onpreview()}
                                          >
                                            <span class="dark:text-white group-hover:text-white">
                                              Hide Preview
                                            </span>
                                          </label>
                                        )}
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                              {draftListref.current.userID === User._id && (
                                <div className="col-md-12">
                                  <b className="TextPrimary text-xl">Share List</b>
                                  <div className="card mb-5 pt-4 mb-xl-10">
                                    {/*begin::Card body*/}
                                    <div class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
                                      <div className="uploadtbl">
                                        {/*begin::Table*/}
                                        <Table bordered hover responsive>
                                          {/*begin::Thead*/}
                                          <thead className="border-gray-200 fs-5 fw-bold bg-lighten">
                                            <tr>
                                              <th className="text-white">S.No</th>
                                              <th className="text-white">User</th>
                                            </tr>
                                          </thead>
                                          {/*end::Thead*/}
                                          {/*begin::Tbody*/}
                                          <tbody className="fw-6 fw-bold text-gray-600">
                                            {shareListref.current &&
                                              shareList.length > 0 ? (
                                              shareList.map((item, i) => {
                                                // console.log(item, "itemssssssssss");

                                                return (
                                                  <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{item}</td>
                                                  </tr>
                                                );
                                              })
                                            ) : (
                                              <tr>
                                                <td colSpan="3">
                                                  <span className="w-100 text-center d-block">
                                                    No History found!
                                                  </span>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </Table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      ) : (<>
                        <img
                          width="100"
                          height="100"
                          class="mb-4 block mx-auto  mt-5 fill-jacarta-500 dark:fill-white"
                          src={require("../img/loadinggif.gif")}
                        ></img>
                        <p class="text-center text-l dark:text-jacarta-300">
                          Please Wait, Your transaction is uploading to ETH Blockchain
                        </p>
                      </>)}
                    </div>

                  </>
                )}
              </div>
            )}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
export default ContractUpload;
