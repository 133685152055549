import React, { useEffect, useState } from "react";
import { PhoneInput } from "react-contact-number-input";
import ConnectWallet from "../components/WalletConnect";
import useStateRef from "react-usestateref";
import { getWeb3, keys } from "../utils/getWeb3";
import Axios from "axios";
import Header from "./Header";
import SelectUserType from "../components/SelectUserType";
import AdvocateSignupForm from "../components/AdvocateSignupForm";
import ClientSignupForm from "../components/ClientSignupForm";
import { env } from "../core/service/envconfig";
import { toast } from "react-toastify";

function Home() {
  const [pageStep, setPageStep] = useState(null);
  const [getGender, setgetGender, getGenderref] = useStateRef("Select Gender");
  const [addressdata, setaddressdata, addressdataref] = useStateRef("");

  const [socialLoginSdk, setSocialLoginSdk] = useState(null);
  const [nexusUser, setNexusUser] = useState("");

  const setNexusSdk = (sdk, userInfo) => {
    setSocialLoginSdk(sdk);
    setNexusUser(userInfo);
    setPageStep("userType");
  };

  const showAdvocateOrClientSignup = ({
    isUserAdvocateRegistered,
    isUserClientRegistered,
    token,
    decrypted,
    Message,
  }) => {
    // console.log({
    //   isUserAdvocateRegistered,
    //   isUserClientRegistered,
    //   token,
    //   decrypted,
    // });

    if (isUserAdvocateRegistered) {
      localStorage.setItem("user_token", token);
      localStorage.setItem("decodes_advocate", decrypted + "*advocates2024");
      localStorage.setItem("type", "advocate");
      if (
        localStorage.getItem("user_token") != "" &&
        localStorage.getItem("user_token") != null &&
        localStorage.getItem("user_token") != undefined
      ) {
        // navigate("/profile");
        window.location.href = env.baseURL + "/profile";
      }
      toast.success(Message);
      balancedata();
    }

    if (isUserClientRegistered) {
      localStorage.setItem("user_token", token);
      localStorage.setItem("type", "client");
      localStorage.setItem("decodes_client", decrypted + "*client2024");
      if (
        localStorage.getItem("user_token") != "" &&
        localStorage.getItem("user_token") != null &&
        localStorage.getItem("user_token") != undefined
      ) {
        // navigate("/clientProfilers");
        window.location.href = env.baseURL + "/clientProfilers";
      }

      toast.success(Message);
      balancedata();
    }

    if (isUserAdvocateRegistered === false) {
      // console.log("jnjnkknknknjnk");
      setPageStep("advocateSignup");
    }

    if (isUserClientRegistered === false) {
      // console.log("drxrdrdrrrxrxrx");
      setPageStep("clientSignup");
    }
  };

  useEffect(() => {
    // console.log({ pageStep }, "changed");
  }, [pageStep]);

  async function balancedata() {
    var web3 = getWeb3();
    // console.log(web3, "web3");
    // console.log(
    //   web3.eth.accounts.privateKeyToAccount(keys()),
    //   "web3.eth.accounts"
    // );
    const account = web3.eth.accounts.privateKeyToAccount(keys());
    web3.eth.accounts.wallet.add(account);
    var address = web3.eth.accounts.wallet[0].address;
    // console.log(address, "address ==========++++");
    setaddressdata(address);

    const feesData = {
      to: String(address),
    };
    const transapi = await Axios.post(
      `${env.apiHost}advocate/advocate/transfer`,
      feesData
    );
    // console.log(transapi, "transapi");
  }

  return (
    <>
      <section class="relative h-screen">
        <div class="lg:flex lg:h-full">
          <div class="relative text-center lg:w-1/2">
            <img
              src={require("../img/pexels.jpg")}
              alt="login"
              class="absolute h-full w-full object-cover"
            />
          </div>

          <div class="relative flex items-center justify-center p-[10%] lg:w-1/2 login_for_pading">
            <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              <img
                src={require("../img/gradient_light.jpg")}
                alt="gradient"
                class="h-full w-full"
              />
            </picture>

            <div class="w-full max-w-[25.625rem] text-center max_width_100">
              <section class="relative pt-16 pb-24">
                {socialLoginSdk === null && (
                  <ConnectWallet setNexusSdk={setNexusSdk} />
                )}

                <div
                  className="content d-flex flex-column flex-column-fluid"
                  id="kt_content"
                >
                  {pageStep === "userType" && (
                    <SelectUserType
                      userEmail={nexusUser.email}
                      userInfo={nexusUser}
                      showAdvocateOrClientSignup={showAdvocateOrClientSignup}
                    />
                  )}
                  {pageStep === "advocateSignup" && (
                    <AdvocateSignupForm userInfo={nexusUser} />
                  )}
                  {pageStep === "clientSignup" && (
                    <ClientSignupForm userInfo={nexusUser} />
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
