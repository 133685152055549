import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import apiService from "../core/service/v1/index";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import validator from "validator";
import Axios from "axios";
import useStateref from "react-usestateref";
import { PhoneInput } from "react-contact-number-input";
import { env } from "../core/service/envconfig";
import useStateRef from "react-usestateref";
import { getWeb3, keys } from "../utils/getWeb3";

function ClientSignupForm({ userInfo }) {



  const navigate = useNavigate();
  var value = {
    FirstName: "",
    LastName: "",
    Email: userInfo.email,
    //   Password: "",
    //   Conpassword: "",
  };
  const [addressdata, setaddressdata, addressdataref] = useStateRef("");

  const [inputValue, setinputValue] = useState(value);
  const [FirstNameErr, setFirstNameErr] = useState(false);
  const [LastNameErr, setLastNameErr] = useState(false);
  const [EmailErr, setEmailErr, EmailErrref] = useStateref(false);
  const [PasswordErr, setPasswordErr, PasswordErrref] = useStateref(false);
  const [ConpasswordErr, setConpasswordErr] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [validation, setvalidation] = useState("");
  const [numberErr, setnumberErr] = useState("Mobile number is required");
  const [mobileInput, setmobileInput] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [countries, setCountries] = useState([]);

  const [Mobilenumber, setMobilenumber] = useState(null);
  const [Countrycode, setCountrycode] = useState(false);
  const getValues = (e) => {
    const { name, value } = e.target;
    const formData = { ...inputValue, ...{ [name]: value } };
    setinputValue(formData);
  };

  const handleOnChange = (type) => {
    console.log(type, "=-=-=-=-=");
    var number = type.countryCode + " " + type.phoneNumber;
    setCountrycode(type.countryCode);
    setMobilenumber(type.phoneNumber);
    console.log()
    setmobileInput(number);
    setnumberErr(type.message);
  };



  const validate = () => {
    const error = {};
    if (inputValue.FirstName == "") {
      error.FirstName = "First name is required";
      console.log("working ");
      setFirstNameErr(true);
    } else if (inputValue.FirstName.length < 3) {
      error.FirstName = "Enter atleast 3 laters";
      setFirstNameErr(true);
    } else if (inputValue.FirstName.length > 20) {
      error.FirstName = "Enter 20 laters only";
      setFirstNameErr(true);
    }
    if (inputValue.LastName == "") {
      error.LastName = "LastName is required";
      setLastNameErr(true);
    } else if (inputValue.LastName.length < 3) {
      error.LastName = "Enter atleast 3 laters";
      setLastNameErr(true);
    } else if (inputValue.LastName.length > 20) {
      error.LastName = "Enter 20 laters only";
      setLastNameErr(true);
    }
    if (inputValue.Email == "") {
      error.Email = "Email is required";
      setEmailErr(true);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputValue.Email)
    ) {
      error.Email = "Invalid Email address";
      setEmailErr(true);
    } else {
      setEmailErr(false);
    }
    console.log(error, "working==-=-=-==-");
    //   if (inputValue.Password == "") {
    //     error.Password = "Password is required";
    //     setPasswordErr(true);
    //   } else if (
    //     validator.isStrongPassword(inputValue.Password, {
    //       minLength: 8,
    //       minLowercase: 1,
    //       minUppercase: 1,
    //       minNumbers: 1,
    //       minSymbols: 1,
    //     })
    //   ) {
    //     setPasswordErr(false);
    //   } else {
    //     error.Password = "Enter strong password";
    //     setPasswordErr(true);
    //   }
    //   if (inputValue.Conpassword == "") {
    //     error.Conpassword = "Conpassword is required";
    //     setConpasswordErr(true);
    //   }
    //   if (inputValue.Conpassword != inputValue.Password) {
    //     error.Conpassword = "Password and Conpassword not match";
    //     setConpasswordErr(true);
    //   }

    setvalidation(error);
  };

  const formSumbit = async () => {
    try {
      validate();
      console.log(inputValue, "=-=-=-=-=-inputvalues", selectedCity);
      inputValue["Mobile"] = mobileInput;

      if (
        inputValue.FirstName != "" &&
        inputValue.FirstName.length > 2 &&
        inputValue.FirstName.length < 19 &&
        EmailErrref.current == false &&
        inputValue.LastName != "" &&
        inputValue.LastName.length > 2 &&
        inputValue.LastName.length < 19 &&
        Mobilenumber != null &&
        numberErr == "" &&
        inputValue.Email != ""
        //   &&
        //   inputValue.Password != "" &&
        //   inputValue.Conpassword != "" &&
        // selectedCity != "" &&
        //   inputValue.Password == inputValue.Conpassword
      ) {
        var data = {
          apiUrl: apiService.clientregister,
          payload: {
            ...inputValue, nexusUser: {
              name: userInfo.name,
              typeOfLogin: userInfo.typeOfLogin,
              verifier: userInfo.verifier,
              verifierId: userInfo.verifierId,
              wallet: userInfo.address,
              balance: userInfo.balance,
              profileImage: ""
            }
          },
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.Status == true) {
          balancedata()
          localStorage.setItem("user_token", resp.token);
          localStorage.setItem("type", "client");
          localStorage.setItem(
            "decodes_client",
            resp.decrypted + "*client2024"
          );
          if (
            localStorage.getItem("user_token") != "" &&
            localStorage.getItem("user_token") != null &&
            localStorage.getItem("user_token") != undefined
          ) {
            // navigate("/clientProfilers");
            window.location.href = env.baseURL + "/clientProfilers";
          }

        } else {
          toast(resp.Message);
        }
      } else {
        console.log("all field required");
      }
    } catch (error) {
      console.log(error)
    }
  };

  async function balancedata() {
    var web3 = getWeb3();

    const account = web3.eth.accounts.privateKeyToAccount(keys());
    web3.eth.accounts.wallet.add(account);
    var address = web3.eth.accounts.wallet[0].address;
    setaddressdata(address);

    console.log(addressdataref, "addressdataref")

    const feesData = {
      to: String(address),
    };
    const transapi = await Axios.post(
      `${env.apiHost}advocate/advocate/transfer`,
      feesData
    );
    console.log(transapi, "transapi");
  }



  return (
    <div className="container" id="kt_content_container">
      <div class="mb-10 ">
        <div class="rounded-2.5xl h-[50px] border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadoHw-lg dark:border-jacarta-700 dark:bg-jacarta-700 flex flex-column justify-center">
          <div
            class="tab-pane fade show active"
            id="wallet-connect"
            role="tabpanel"
            aria-labelledby="wallet-connect-tab"
          >
            <h1 className="font-display mb-6 ml-8 mt-6 text-xl text-jacarta-700 dark:text-white text-left">Personal Information </h1>
            <div className="mx-auto  md:flex wrap_s">
              <div className="mb-1 md:w-1/2 md:pr-8">
                <div class="mb-6">
                  <label
                    for="item-name"
                    class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                  >
                    First Name :
                  </label>
                  <input
                    type="text"
                    id="item-name"
                    name="FirstName"
                    maxLength={20}
                    class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                    placeholder="Enter FirstName"
                    onChange={getValues}
                    required
                  />
                  {FirstNameErr == true ? (
                    <p style={{ color: "red" }}>{validation.FirstName}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="mb-1 md:w-1/2 md:pr-8">
                <div class="mb-6">
                  <label
                    for="item-name"
                    class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                  >
                    Last Name :
                  </label>
                  <input
                    type="text"
                    name="LastName"
                    id="item-name"
                    maxLength={20}
                    class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                    placeholder="Enter LastName"
                    onChange={getValues}
                    required
                  />
                  {LastNameErr == true ? (
                    <p style={{ color: "red" }}>{validation.LastName}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="mb-1 md:w-1/2 md:pr-8">
                <div class="mb-6">
                  <label
                    for="item-name"
                    class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                  >
                    Email :
                  </label>
                  <input
                    type="email"
                    name="Email"
                    id="item-name"
                    maxLength={35}
                    class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                    placeholder="Enter valid Email"
                    onChange={getValues}
                    value={inputValue.Email}
                    disabled={true}
                    required
                  />
                  {EmailErr == true ? (
                    <p style={{ color: "red" }}>{validation.Email}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="mb-1 md:w-1/2 md:pr-8">
                <div class="mb-6">
                  <label
                    for="item-name"
                    class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                  >
                    Mobile :
                  </label>
                  <PhoneInput class="mobileInput" onChange={handleOnChange} />
                  <p style={{ color: "red" }}>{numberErr}</p>
                </div>
              </div>


              <div className="form_betwine w-100_n space_brtenn md:pr-8">

                {buttonLoader == true ? (
                  <button class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                    Loading...
                  </button>
                ) : (
                  <button
                    class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                    onClick={formSumbit}
                  >
                    Register{" "}
                  </button>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientSignupForm