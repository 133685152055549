const apiService = {
  clientregister: "users/users/clientsignup",
  documentcount: "users/users/documentcount",
  advacateregister: "users/users/advacatesignup",
  clientsignin: "users/users/clientsignin",
  getAIresponse: "users/users/getAIresponse",
  checkUserExist: "users/users/checkUserExist",
  advacatesignin: "users/users/advacatesignin",
  emailVerify: "users/users/emailVerify",
  documentDownloader: "users/users/documentDownloader",
  createTemplateAdv: "advocate/advocate/createTemplateAdv",
  getAllAdvocates: "advocate/advocate/getAllAdvocates",
  getRssfeedcontent: "advocate/advocate/getRssfeedcontent",
  getAdvProfile: "advocate/advocate/getAdvProfile",
  updateProfile: "advocate/advocate/updateProfile",
  getAdvProfileID: "advocate/advocate/getAdvProfileID",
  request: "advocate/advocate/request",
  getRequest: "advocate/advocate/getRequest",
  getConnections: "advocate/advocate/getConnections",
  advocateAction: "advocate/advocate/advocateAction",
  templateDetails: "advocate/advocate/templateDetails",
  getTemplateID: "advocate/advocate/getTemplateIDItem",
  addToCartS: "advocate/advocate/addToCartS",
  getCartDetails: "advocate/advocate/getCartDetails",
  removCartDetails: "advocate/advocate/removCartDetails",
  checkoutCart: "advocate/advocate/checkoutCart",
  getClientInfo: "advocate/advocate/getClientInfo",
  getwalletInfo: "advocate/advocate/getwalletInfo",
  getMyDocuments: "advocate/advocate/getMyDocuments",
  getCLientProfile: "advocate/advocate/getCLientProfile",
  updateClientProfile: "advocate/advocate/updateClientProfile",
  ForgotPassword: "users/users/ForgotPassword",
  resetpassword: "users/users/resetpassword",
  updateTemplate: "advocate/advocate/updateTemplate",
  getMyCreateDocs: "advocate/advocate/getMyCreateDocs",
  getcountryDocuments: "advocate/advocate/getcountryDocuments",
  searching: "advocate/advocate/searching",
  getTemplateIDEdit: "advocate/advocate/getTemplateIDEdit",
  updatedocx: "advocate/advocate/updatedocx",
  docsigner: "users/users/docsigner",
  deletecontract: "users/users/deletecontract",
  searchcontract: "users/users/searchcontract",
  LinkVerify: "users/users/LinkVerify",
  signerverify: "users/users/signerverify",
  Updatesign: "users/users/Updatesign",
  Ethsigncheck: "users/users/Ethsigncheck",
  getAllSignDocs: "users/users/getAllSignDocs",
  completeDatas: "users/users/completeDatas"
};
export default apiService;
