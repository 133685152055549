export const env = {
  // apiHost: "http://localhost:3033/",
  // frontendUrl: "http://localhost:3000/",
  // baseURL: "http://localhost:3000",
  // frontendUrlLogin: "http://localhost:3000/login",


  apiHost: "https://marketplaceai.online:3033/",
  frontendUrl: "https://marketplaceai.online/",
  baseURL: "https://marketplaceai.online",
  frontendUrlLogin: "https://marketplaceai.online/login",

  cloud_name: "din0cewcc",
  preset: "ku41ornw",

  REACT_APP_IPFS_PROJECT_ID  : "2HX34iE4Rs8dL8dOopRqDGoHG2P",
REACT_APP_IPFS_PROJECT_SECRET : "80919304e5c4838611462475e6f5166b",
REACT_APP_STORX_MNEMONIC : "next easy grid margin grain convince high tip cup maid noble robot brass aisle design race broom caught utility rubber camp make oak glare",
REACT_APP_STORX_ACCESS_KEY : "sx_live_1e8ca84cd7062f2a0773f9039df04fe518d0bd3df73a14a800a7713fc0a48cc1",
REACT_APP_STORX_FOLDER_ID : "35",
REACT_APP_STORX_URL : "http://109.169.55.25:3000/",
REACT_APP_STORX_SHARE_URL : "http://109.169.55.25:8000/api/storage/share",
REACT_APP_STORAGE_TYPE : "ipfs",
REACT_APP_IPFS_BASE_URL : "https://ipfs.infura.io/ipfs"
};
