import React, { useState, useEffect } from "react";
// import Sliderimg from "./Sliderimg";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Header from "./Header";
import { getMethod } from "../core/service/common.api";
import apiService from "../core/service/v1/index";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function TemplateMore() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const styles = {
    backgroundImage:
      "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/2764-fe0f.png)",
  };

  const [allAdvocate, setallAdvocate] = useState([]);
  const [lawLoader, setlawLoader] = useState(false);
  const [templateDetails, settemplateDetails] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // getLawerDetails();
    getAllTemplateDetails();
  }, [0]);

  const getLawerDetails = async (e) => {
    try {
      var data = {
        apiUrl: apiService.getAllAdvocates,
      };
      setlawLoader(true);
      var resp = await getMethod(data);
      setlawLoader(false);
      // console.log(resp.Message);
      if (resp.status == true) {
        setallAdvocate(resp.Message);
      } else {
        setallAdvocate([]);
      }
    } catch (error) {
      // console.log(error, "::::error");
    }
  };

  const goToProfile = async (id) => {
    try {
      // console.log(id, "====id====");
      navigate("/profile?" + id);
    } catch (error) {
      // console.log(error, "::::error");
    }
  };

  const getAllTemplateDetails = async (e) => {
    try {
      var data = {
        apiUrl: apiService.templateDetails,
      };
      // setlawLoader(true);
      var resp = await getMethod(data);
      // setlawLoader(false);
      // console.log(resp.Message,"=-=-=-==-=-=-=-=-=-=");
      if (resp.status == true) {
        settemplateDetails(resp.Message);
      } else {
        settemplateDetails([]);
      }
    } catch (error) {
      // console.log(error, "::::error");
    }
  };

  // getAllAdvocates

  return (
    <>
      <Header />
      <main className="pt-[5.5rem] lg:pt-24">
        <div className="container py-5">
          <div class="mb-12 text-center font-display text-3xl text-jacarta-700 dark:text-white">
            <h2 class="inline my-5 bold">All Legal Template Details</h2>
          </div>

          {lawLoader == true ? (
            <div class="flex_loading">
              <img
                width="60"
                height="60"
                class="mb-4 inline-block fill-jacarta-500 dark:fill-white"
                src={require("../img/loadinggif.gif")}
              ></img>
            </div>
          ) : templateDetails.length > 0 ? (
            <div class="grid grid-cols-1 gap-[1.875rem] md:grid-cols-3 lg:grid-cols-3 mb-4 js-collections-grid">
              {templateDetails.map((item, index) => {
                return (
                  <article>
                    {/* <div class="block rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
                      <figure class="relative">
                        <a href={"/Item?" + item._id}>
                          <div className="pfd_view for_pdf_other">
                            <div className="image_vie"></div>
                            <div className="pdf_title">
                              <h4 className="titel_nme"> {item.name} </h4>
                            </div>
                            <div className="pdf_title">
                              <h4 className="text_desctpon">
                                {item.description}
                              </h4>
                            </div>
                            <div className="pdf_auther">
                              <h4> Advocate Name</h4>
                              <p>{item.user_id.fullName} </p>
                            </div>
                          </div>
                        </a>
                      </figure>

                      <div class="mt-7 flex items-center justify-between">
                        <a href={"/Item?" + item._id}>
                          <span class="font-display text-base text-jacarta-700 hover:text-accent dark:text-white">
                            {item.name}
                          </span>
                        </a>
                        <span class="flex items-center whitespace-nowrap rounded-md border border-jacarta-100 py-1 px-2 dark:border-jacarta-600">
                          {item && item.user_id.prfileIMG == "" ? (
                            <img
                              src={require("../img/tharasu.png")}
                              alt="gradient"
                              class="h-full w-full width_img_icon"
                            />
                          ) : (
                            <img
                              src={item.user_id.prfileIMG}
                              alt="gradient"
                              class="h-full w-full width_img_icon"
                            />
                          )}
                        </span>
                      </div>
                      <div class="mt-2 text-sm">
                        <span class="dark:text-jacarta-300">
                          Location : {item && item.state},{" "}
                          {item && item.country}{" "}
                        </span>
                        <br />
                        <span class="text-jacarta-700 dark:text-jacarta-100">
                          Price {item && item.amount} {item && item.currency}
                        </span>
                      </div>

                      <a href={"/Item?" + item._id}>
                        <div class="mt-8 flex items-center justify-between">
                          <button class="font-display text-sm font-semibold text-accent">
                            Buy Now
                          </button>

                          <a href={"/Item?" + item._id}>
                            <button
                              class="dropdown-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
                              aria-label="profile"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="bi bi-cart-check-fill h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white color_blsce"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                              >
                                <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
                              </svg>
                            </button>
                          </a>
                        </div>
                      </a>
                    </div> */}

                    <div class="block rounded-2.5xl bg-white tempcard p-[1.1875rem] py-3 transition-shadow hover:shadow-lg ">
                              <figure class="relative">
                                <a href={"/Item?" + item._id}>
                                  <div className="pfd_view for_pdf_other">
                                    <div className="image_vie"></div>
                                    <div className="pdf_title">
                                      <h4 className="titel_nme">
                                        {" "}
                                        {item && item.name}{" "}
                                      </h4>
                                    </div>
                                    <div className="pdf_title">
                                      <h4 className="text_desctpon">
                                        {item && item.description}
                                      </h4>
                                    </div>
                                    <div className="pdf_auther">
                                      <h4>Advocate Name </h4>

                                      <p>{item && item.user_id.fullName} </p>
                                    </div>
                                  </div>
                                </a>
                              </figure>

                              <div class="mt-7 flex items-center justify-between">
                                <a href={"/Item?" + item._id}>
                                  <span class="font-display text-base text-jacarta-700 hover:text-accent TextPrimary">
                                    {item && item.name}
                                  </span>
                                </a>
                                <span class="flex items-center whitespace-nowrap py-1 px-2">
                                  {item && item.user_id.prfileIMG == "" ? (
                                    <img
                                      src={require("../img/tharasu.png")}
                                      alt="gradient"
                                      class="tempimage"
                                    />
                                  ) : (
                                    <img
                                      src={item.user_id.prfileIMG}
                                      alt="gradient"
                                      class="tempimage"
                                    />
                                  )}
                                </span>
                              </div>
                              <div class="mt-2 text-sm">
                                <span class="dark:text-jacarta-300 TextThird">
                                  {item && item.state}, {item && item.country}{" "}
                                </span>
                              </div>

                              <div class="mt-2 text-sm">
                                <span class="text-jacarta-700 mt-2 dark:text-jacarta-100 TextThird">
                                  Price {item && item.amount}{" "}
                                  {item && item.currency}
                                </span>
                              </div>

                              <a href={"/Item?" + item._id}>
                                <div class="mt-8 flex items-center justify-between">
                                  <button class="font-display text-sm font-semibold text-white BtnPrimary">
                                    Buy Now{" "}
                                    <i class="ri-arrow-right-line text-white"></i>
                                  </button>

                                  <a href={"/Item?" + item._id}>
                                    <button
                                      class="TextPrimary d-flex "
                                      aria-label="profile"
                                    >
                                      Add to card
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="bi bi-cart-check-fill group-hover:fill-white group-focus:fill-white TextPrimary ml-2"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                      </svg>
                                    </button>
                                  </a>
                                </div>
                              </a>
                            </div>
                  </article>
                );
              })}
            </div>
          ) : (
            <div className="notata_found">
              <img
                src={require("../img/no-data/cuate.png")}
                class=""
                alt="Xhibiter | NFT Marketplace"
              />
              <p className="font-display text-base font-medium">
                No Leagal Documents Found!
              </p>
            </div>
          )}
        </div>
      </main>

      <Footer />
    </>
  );
}

export default TemplateMore;
