import Web3 from 'web3'
import { EvmRpc } from "@web5nexus/sociallogin";
var CryptoJS = require("crypto-js");


export const getWeb3 = () => {
  try {
    const web3 = new Web3(new Web3.providers.HttpProvider("https://rpc.xinfin.network"))
    const keyvalue = localStorage.getItem("Info");
    var bytes = CryptoJS.AES.decrypt(keyvalue, 'CRYPTO_EXCHANGE');
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
    const privateKey = "0x" + decryptedData;
      // console.log(privateKey,"privateKey");
      if (!privateKey) throw new Error('Private key not found in localStorage')
      const account = web3.eth.accounts.privateKeyToAccount(privateKey)
      web3.eth.accounts.wallet.add(account)
    return web3
  } catch (error) {
    console.error('Failed to get web3:', error)
    throw error
  }
}

export const keys =  () => {

  const keyvalue = localStorage.getItem("Info");
  var bytes = CryptoJS.AES.decrypt(keyvalue, 'CRYPTO_EXCHANGE');
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return "0x" + decryptedData
}

const exportedObject = {
  getWeb3,
  keys,
};


export default exportedObject
