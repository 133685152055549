// import { Document, Page, pdfjs } from "react-pdf";
import $ from "jquery";
import { fabric } from "fabric";
import { jsPDF } from "jspdf";
import { getDocument } from "pdfjs-dist/webpack";
import edgeImg from "../img/edgecontrol.svg";
import verticalImg from "../img/middlecontrol.svg";
import horizontalImg from "../img/middlecontrolhoz.svg";

export const PDFAnnotate = function (container_id, url, options = {}) {
  this.number_of_pages = 0;
  this.pages_rendered = 0;
  this.active_tool = 1; // 1 - Free hand, 2 - Text, 3 - Arrow, 4 - Rectangle
  this.fabricObjects = [];
  this.fabricObjectsData = [];
  this.color = "#212121";
  this.borderColor = "#000000";
  this.borderSize = 1;
  this.font_size = 16;
  this.active_canvas = 0;
  this.container_id = container_id;
  this.url = url;
  this.pageImageCompression = options.pageImageCompression
    ? options.pageImageCompression.toUpperCase()
    : "NONE";
  this.textBoxText = "Sample Text";
  this.format = "";
  this.orientation = "";
  var inst = this;
  this.text = "";

  this.pdfFileAsString = ""

  var loadingTask = getDocument(this.url);
  loadingTask.promise.then(
    function (pdf) {
      var scale = options.scale ? options.scale : 1.3;
      inst.number_of_pages = pdf.numPages;

      for (var i = 1; i <= pdf.numPages; i++) {
        pdf.getPage(i).then(function (page) {
          if (
            typeof inst.format === "undefined" ||
            typeof inst.orientation === "undefined"
          ) {
            var originalViewport = page.getViewport({ scale: 1 });
            inst.format = [originalViewport.width, originalViewport.height];
            inst.orientation =
              originalViewport.width > originalViewport.height
                ? "landscape"
                : "portrait";
          }

          var viewport = page.getViewport({ scale: scale });
          var canvas = document.createElement("canvas");
          document.getElementById(inst.container_id).appendChild(canvas);
          canvas.className = "pdf-canvas";
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          let context = canvas.getContext("2d");

          var renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          var renderTask = page.render(renderContext);
          renderTask.promise.then(function () {
            $(".pdf-canvas").each(function (index, el) {
              $(el).attr("id", "page-" + (index + 1) + "-canvas");
            });
            inst.pages_rendered++;
            if (inst.pages_rendered == inst.number_of_pages) inst.initFabric();
          });
        });
      }
    },
    function (reason) {
      console.error(reason);
    }
  );

  this.initFabric = function () {
    var inst = this;
    let canvases = $("#" + inst.container_id + " canvas");
    canvases.each(function (index, el) {
      var background = el.toDataURL("image/png");
      var fabricObj = new fabric.Canvas(el.id, {
        freeDrawingBrush: {
          width: 1,
          color: inst.color,
        },
      });
      inst.fabricObjects.push(fabricObj);
      if (typeof options.onPageUpdated == "function") {
        fabricObj.on("object:added", function () {
          var oldValue = Object.assign({}, inst.fabricObjectsData[index]);
          inst.fabricObjectsData[index] = fabricObj.toJSON();
          options.onPageUpdated(
            index + 1,
            oldValue,
            inst.fabricObjectsData[index]
          );
        });
      }
      fabricObj.setBackgroundImage(
        background,
        fabricObj.renderAll.bind(fabricObj)
      );
      $(fabricObj.upperCanvasEl).click(function (event) {
        inst.active_canvas = index;
        inst.fabricClickHandler(event, fabricObj);
      });

      fabricObj.on("after:render", function () {
        inst.fabricObjectsData[index] = fabricObj.toJSON();
        fabricObj.off("after:render");
      });

      if (
        index === canvases.length - 1 &&
        typeof options.ready === "function"
      ) {
        options.ready(inst.fabricObjects);
      }
    });
  };

  this.fabricClickHandler = function (event, fabricObj) {
    var inst = this;
    var toolObj;
    if (inst.active_tool == 2) {
      toolObj = new fabric.IText(inst.textBoxText, {
        left:
          event.clientX - fabricObj.upperCanvasEl.getBoundingClientRect().left,
        top:
          event.clientY - fabricObj.upperCanvasEl.getBoundingClientRect().top,
        fill: inst.color,
        fontSize: inst.font_size,
        selectable: true,
      });
    } else if (inst.active_tool == 4) {
      const text = new fabric.Text(inst.text, {
        fontSize: 20,
        left:
          event.clientX -
          fabricObj.upperCanvasEl.getBoundingClientRect().left +
          10,
        top:
          event.clientY -
          fabricObj.upperCanvasEl.getBoundingClientRect().top +
          10,
      });

      let rect = new fabric.Rect({
        left:
          event.clientX - fabricObj.upperCanvasEl.getBoundingClientRect().left,
        top:
          event.clientY - fabricObj.upperCanvasEl.getBoundingClientRect().top,
        width: text.width + 20,
        height: text.height + 20,
        fill: inst.color,
        stroke: inst.borderColor,
        strokeSize: inst.borderSize,
      });

      let group = new fabric.Group([rect, text], {});
      group.setControlsVisibility({ mtr: false });
      fabricObj.add(group);

      inst.active_tool = 5;
    }

    if (toolObj) {
      fabricObj.add(toolObj);
    }
  };
};

PDFAnnotate.prototype.enableSelector = function () {
  var inst = this;
  inst.active_tool = 0;
  if (inst.fabricObjects.length > 0) {
    $.each(inst.fabricObjects, function (index, fabricObj) {
      fabricObj.isDrawingMode = false;
    });
  }
};

PDFAnnotate.prototype.enablePencil = function () {
  var inst = this;
  inst.active_tool = 1;
  if (inst.fabricObjects.length > 0) {
    $.each(inst.fabricObjects, function (index, fabricObj) {
      fabricObj.isDrawingMode = true;
    });
  }
};

PDFAnnotate.prototype.enableAddText = function (text) {
  var inst = this;
  inst.active_tool = 2;
  if (typeof text === "string") {
    inst.textBoxText = text;
  }
  if (inst.fabricObjects.length > 0) {
    $.each(inst.fabricObjects, function (index, fabricObj) {
      fabricObj.isDrawingMode = false;
    });
  }
};

PDFAnnotate.prototype.enableRectangle = function (text) {
  var inst = this;
  inst.text = text;
  var fabricObj = inst.fabricObjects[inst.active_canvas];
  // console.log({ fabricObj });
  inst.active_tool = 4;
  if (inst.fabricObjects.length > 0) {
    $.each(inst.fabricObjects, function (index, fabricObj) {
      // console.log(fabricObj, "000 fabricObj");
      fabricObj.isDrawingMode = false;
      return;
    });
  }
};
PDFAnnotate.prototype.addImageToCanvas = function () {
  var inst = this;
  var fabricObj = inst.fabricObjects[inst.active_canvas];

  if (fabricObj) {
    var inputElement = document.createElement("input");
    inputElement.type = "file";
    inputElement.accept = ".jpg,.jpeg,.png,.PNG,.JPG,.JPEG";
    inputElement.onchange = function () {
      var reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          inputElement.remove();
          var image = new Image();
          image.onload = function () {
            fabricObj.add(new fabric.Image(image));
          };
          image.src = this.result;
        },
        false
      );
      reader.readAsDataURL(inputElement.files[0]);
    };
    document.getElementsByTagName("body")[0].appendChild(inputElement);
    inputElement.click();
  }
};


PDFAnnotate.prototype.savePdf = function (fileName) {
  var inst = this;
  var format = inst.format || "a4";
  var orientation = inst.orientation || "portrait";
  if (!inst.fabricObjects.length) return;

  var doc = new jsPDF({ format, orientation });
  if (typeof fileName === "undefined") {
    fileName = `${new Date().getTime()}.pdf`;
  }

  inst.fabricObjects.forEach(function (fabricObj, index) {
    if (index != 0) {
      doc.addPage(format, orientation);
      doc.setPage(index + 1);
    }
    doc.addImage(
      fabricObj.toDataURL({
        format: "png",
      }),
      inst.pageImageCompression == "NONE" ? "PNG" : "JPEG",
      0,
      0,
      doc.internal.pageSize.getWidth(),
      doc.internal.pageSize.getHeight(),
      `page-${index + 1}`,
      ["FAST", "MEDIUM", "SLOW"].indexOf(inst.pageImageCompression) >= 0
        ? inst.pageImageCompression
        : undefined
    );
    if (index === inst.fabricObjects.length - 1) {
      doc.save(fileName);
    }
  });
};

PDFAnnotate.prototype.getFileAsArrayBuffer = function (fileName) {
  var inst = this;
  var format = inst.format || "a4";
  var orientation = inst.orientation || "portrait";
  if (!inst.fabricObjects.length) return;

  var doc = new jsPDF({ format, orientation });
  if (typeof fileName === "undefined") {
    fileName = `${new Date().getTime()}.pdf`;
  }

  inst.fabricObjects.forEach(function (fabricObj, index) {
    if (index != 0) {
      doc.addPage(format, orientation);
      doc.setPage(index + 1);
    }
    doc.addImage(
      fabricObj.toDataURL({
        format: "png",
      }),
      inst.pageImageCompression == "NONE" ? "PNG" : "JPEG",
      0,
      0,
      doc.internal.pageSize.getWidth(),
      doc.internal.pageSize.getHeight(),
      `page-${index + 1}`,
      ["FAST", "MEDIUM", "SLOW"].indexOf(inst.pageImageCompression) >= 0
        ? inst.pageImageCompression
        : undefined
    );
    if (index === inst.fabricObjects.length - 1) {
      // doc.save(fileName);

      console.log(doc.output("datauristring"))

      inst.pdfFileAsString =doc.output('datauristring')

    //  return doc.output('datauristring')

    }
  });
};

PDFAnnotate.prototype.setBrushSize = function (size) {
  var inst = this;
  $.each(inst.fabricObjects, function (index, fabricObj) {
    fabricObj.freeDrawingBrush.width = parseInt(size, 10) || 1;
  });
};

PDFAnnotate.prototype.setColor = function (color) {
  var inst = this;
  inst.color = color;
  $.each(inst.fabricObjects, function (index, fabricObj) {
    fabricObj.freeDrawingBrush.color = color;
  });
};

PDFAnnotate.prototype.setBorderColor = function (color) {
  var inst = this;
  inst.borderColor = color;
};

PDFAnnotate.prototype.setFontSize = function (size) {
  this.font_size = size;
};

PDFAnnotate.prototype.setBorderSize = function (size) {
  this.borderSize = size;
};

//   PDFAnnotate.prototype.clearActivePage = function () {
//     var inst = this;
//     var fabricObj = inst.fabricObjects[inst.active_canvas];
//     var bg = fabricObj.backgroundImage;
//     if (confirm('Are you sure?')) {
//       fabricObj.clear();
//       fabricObj.setBackgroundImage(bg, fabricObj.renderAll.bind(fabricObj));
//     }
//   };

PDFAnnotate.prototype.serializePdf = function (callback) {
  var inst = this;
  var pageAnnotations = [];
  inst.fabricObjects.forEach(function (fabricObject) {
    fabricObject.clone(function (fabricObjectCopy) {
      fabricObjectCopy.setBackgroundImage(null);
      fabricObjectCopy.setBackgroundColor("");
      pageAnnotations.push(fabricObjectCopy);
      if (pageAnnotations.length === inst.fabricObjects.length) {
        var data = {
          page_setup: {
            format: inst.format,
            orientation: inst.orientation,
          },
          pages: pageAnnotations,
        };
        callback(JSON.stringify(data));
      }
    });
  });
};

PDFAnnotate.prototype.loadFromJSON = function (jsonData) {
  var inst = this;
  var { page_setup, pages } = jsonData;
  if (typeof pages === "undefined") {
    pages = jsonData;
  }
  if (
    typeof page_setup === "object" &&
    typeof page_setup.format === "string" &&
    typeof page_setup.orientation === "string"
  ) {
    inst.format = page_setup.format;
    inst.orientation = page_setup.orientation;
  }
  $.each(inst.fabricObjects, function (index, fabricObj) {
    // console.log(index, fabricObj, "----")
    if (pages.length > index) {
      fabricObj.loadFromJSON(
        pages[index],
        function () {
          inst.fabricObjectsData[index] = fabricObj.toJSON();
        },
        function (o, object) {
          object.set("selectable", false);
        }
      );
    }
  });
};

PDFAnnotate.prototype.setDefaultTextForTextBox = function (text) {
  var inst = this;
  if (typeof text === "string") {
    inst.textBoxText = text;
  }
};

PDFAnnotate.prototype.getCanvases = (cb) => {
  var inst = this;

  if (inst.fabricObjects.length > 0) {
    // $.each(inst.fabricObjects, function (index, fabricObj) {
    //   fabricObj.isDrawingMode = true;
    // });
    // console.log("Heyyyyyyy");
  }

  cb(this);
};

function drawImg(ctx, left, top, img, wSize, hSize, angle) {
  if (angle === undefined) return;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(angle));
  ctx.drawImage(img, -wSize / 2, -hSize / 2, wSize, hSize);
  ctx.restore();
}

function intervalControl() {
  const verticalImgIcon = document.createElement("img");
  verticalImgIcon.src = verticalImg;

  const horizontalImgIcon = document.createElement("img");
  horizontalImgIcon.src = horizontalImg;

  function renderIcon(ctx, left, top, styleOverride, fabricObject) {
    drawImg(ctx, left, top, verticalImgIcon, 20, 25, fabricObject.angle);
  }

  function renderIconHoz(ctx, left, top, styleOverride, fabricObject) {
    drawImg(ctx, left, top, horizontalImgIcon, 25, 20, fabricObject.angle);
  }
  fabric.Object.prototype.controls.ml = new fabric.Control({
    x: -0.5,
    y: 0,
    offsetX: -1,
    cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
    actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
    getActionName: fabric.controlsUtils.scaleOrSkewActionName,
    render: renderIcon,
  });

  fabric.Object.prototype.controls.mr = new fabric.Control({
    x: 0.5,
    y: 0,
    offsetX: 1,
    cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
    actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
    getActionName: fabric.controlsUtils.scaleOrSkewActionName,
    render: renderIcon,
  });

  fabric.Object.prototype.controls.mb = new fabric.Control({
    x: 0,
    y: 0.5,
    offsetY: 1,
    cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
    actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
    getActionName: fabric.controlsUtils.scaleOrSkewActionName,
    render: renderIconHoz,
  });

  fabric.Object.prototype.controls.mt = new fabric.Control({
    x: 0,
    y: -0.5,
    offsetY: -1,
    cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
    actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
    getActionName: fabric.controlsUtils.scaleOrSkewActionName,
    render: renderIconHoz,
  });
}

intervalControl();

function peakControl() {
  const img = document.createElement("img");
  img.src = edgeImg;

  function renderIconEdge(ctx, left, top, styleOverride, fabricObject) {
    drawImg(ctx, left, top, img, 25, 25, fabricObject.angle);
  }
  fabric.Object.prototype.controls.tl = new fabric.Control({
    x: -0.5,
    y: -0.5,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: fabric.controlsUtils.scalingEqually,
    render: renderIconEdge,
  });
  fabric.Object.prototype.controls.bl = new fabric.Control({
    x: -0.5,
    y: 0.5,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: fabric.controlsUtils.scalingEqually,
    render: renderIconEdge,
  });
  fabric.Object.prototype.controls.tr = new fabric.Control({
    x: 0.5,
    y: -0.5,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: fabric.controlsUtils.scalingEqually,
    render: renderIconEdge,
  });
  fabric.Object.prototype.controls.br = new fabric.Control({
    x: 0.5,
    y: 0.5,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: fabric.controlsUtils.scalingEqually,
    render: renderIconEdge,
  });
}

peakControl();

var deleteIcon =
  "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";

const delImg = document.createElement("img");
delImg.src = deleteIcon;

function renderDelIcon(ctx, left, top, styleOverride, fabricObject) {
  drawImg(ctx, left, top, delImg, 24, 24, fabricObject.angle);
}

function deleteObject(eventData, transform) {
  var target = transform.target;
  var canvas = target.canvas;
  canvas.remove(target);
  canvas.requestRenderAll();
}

fabric.Object.prototype.controls.deleteControl = new fabric.Control({
  x: 0.5,
  y: -0.5,
  offsetY: -16,
  offsetX: 16,
  cursorStyle: "pointer",
  mouseUpHandler: deleteObject,
  render: renderDelIcon,
  // cornerSize: 24,
});

fabric.Object.prototype.set({
  transparentCorners: false,
  borderColor: "#51B9F9",
  cornerColor: "#FFF",
  borderScaleFactor: 2.5,
  cornerStyle: "circle",
  cornerStrokeColor: "#0E98FC",
  borderOpacityWhenMoving: 1,
});
