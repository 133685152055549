import React, { useState, useEffect } from "react";
// import Sliderimg from "./Sliderimg";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Header from "./Header";
import { getMethod } from "../core/service/common.api";
import apiService from "../core/service/v1/index";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Home() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const styles = {
    backgroundImage:
      "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/2764-fe0f.png)",
  };

  const [allAdvocate, setallAdvocate] = useState([]);
  const [lawLoader, setlawLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getLawerDetails();
  }, [0]);

  const getLawerDetails = async (e) => {
    try {
      var data = {
        apiUrl: apiService.getAllAdvocates,
      };
      setlawLoader(true);
      var resp = await getMethod(data);
      setlawLoader(false);
      // console.log(resp.Message);
      if (resp.status == true) {
        setallAdvocate(resp.Message);


      } else {
        setallAdvocate([]);
      }
    } catch (error) {
      // console.log(error, "::::error");
    }
  };

  const goToProfile = async (id) => {
    try {
      // console.log(id, "====id====");
      navigate("/profile?" + id);
    } catch (error) {
      // console.log(error, "::::error");
    }
  };

  // getAllAdvocates

  return (
    <>
      <Header />
      <main className="pt-[5.5rem] lg:pt-24 my-5">
        <div className="container my-5">
          <div class="mb-5 text-center font-display text-3xl my-5 text-jacarta-700 dark:text-white">
            <h2 class="inline my-5 bold">Arbitrators Panel</h2>
          </div>
          <div class="mb-12 text-center font-display text-xl text-jacarta-700 dark:text-white">
            {/* <span class="animate-gradient ">All Lawyers, Counselors, Mediators and Arbitrators are KYC authenticated</span> */}

            <span class="TextPrimary">All Lawyers, Counselors, Mediators and Arbitrators are KYC authenticated</span>

          </div>


          {

            lawLoader == true ?
              <div class="grid grid-cols-1 gap-3 md:grid-cols-1 md:gap-[1.875rem] lg:grid-cols-1">
                <img
                  width="60"
                  height="60"
                  class="mb-4 d-block fill-jacarta-500 dark:fill-white mx-auto"
                  src={require("../img/loadinggif.gif")}
                ></img>
              </div>

              :
              <><div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-3">
                {
                  allAdvocate.length > 0 ? allAdvocate.filter((item) => item.Experience >= 15 && item.showstatus == "show").map((item, i) => {

                    return (
                      <div className="card_layer posiuion">
                        <div className="lawer_imga">

                          {item && item.prfileIMG == "" ||
                            item && item.prfileIMG == null ? (
                            <img
                              src={require("../img/advocateImage-removebg-preview.png")}
                              width="100px"
                              height="100px"
                              alt="avatar 1"
                              class="rounded-2lg"
                              loading="lazy"
                            />
                          ) : (
                            <img
                              src={item && item.prfileIMG}
                              width="100px"
                              height="100px"
                              alt="avatar 1"
                              class="rounded-2lg"
                              loading="lazy"
                            />
                          )}
                        </div>

                        <div className="lawer_info">
                          <h4>{item && item.fullName}</h4>
                          <p><span>Experience:</span> {item && item.Experience} years</p>
                          <p><span>expertise:</span> {item.exertise} </p>

                          <p><i class="ri-map-pin-fill"></i>  {item && item.city != "" ? item.city : ""}  {" "} {item && item.state != "" ? item.state : ""} {" "} {item && item.country != "" ? item.country : "India"}</p>
                        </div>
                        <div className="postion_hover"> <a onClick={() => goToProfile(item._id)}>View</a></div>

                      </div>


                    )
                  })

                    : "No Advocates Found!"

                }
              </div>
              </>
          }


        </div>
      </main>

      <Footer />
    </>
  );
}

export default Home;
